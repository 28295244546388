export const GOOGLE_LOG_OUT_STARTED = 'GOOGLE_LOG_OUT_STARTED'
export const GOOGLE_LOG_OUT_SUCCESS = 'GOOGLE_LOG_OUT_SUCCESS'
export const GOOGLE_LOG_OUT_FAILURE = 'GOOGLE_LOG_OUT_FAILURE'

export const GET_LOCATION_LIST_STARTED = 'GET_LOCATION_LIST_STARTED'
export const GET_LOCATION_LIST_SUCCESS = 'GET_LOCATION_LIST_SUCCESS'
export const GET_LOCATION_LIST_FAILURE = 'GET_LOCATION_LIST_FAILURE'

export const GET_MORE_GOOGLE_REVIEW_STARTED = 'GET_MORE_GOOGLE_REVIEW_STARTED'
export const GET_MORE_GOOGLE_REVIEW_SUCCESS = 'GET_MORE_GOOGLE_REVIEW_SUCCESS'
export const GET_MORE_GOOGLE_REVIEW_FAILURE = 'GET_MORE_GOOGLE_REVIEW_FAILURE'

export const REPLY_GOOGLE_REVIEW_STARTED = 'REPLY_GOOGLE_REVIEW_STARTED'
export const REPLY_GOOGLE_REVIEW_SUCCESS = 'REPLY_GOOGLE_REVIEW_SUCCESS'
export const REPLY_GOOGLE_REVIEW_FAILURE = 'REPLY_GOOGLE_REVIEW_FAILURE'

export const DELETE_REPLY_GOOGLE_REVIEW_STARTED = 'DELETE_REPLY_GOOGLE_REVIEW_STARTED'
export const DELETE_REPLY_GOOGLE_REVIEW_SUCCESS = 'DELETE_REPLY_GOOGLE_REVIEW_SUCCESS'
export const DELETE_REPLY_GOOGLE_REVIEW_FAILURE = 'DELETE_REPLY_GOOGLE_REVIEW_FAILURE'

export const CREATE_ADMIN_STARTED = 'CREATE_ADMIN_STARTED'
export const CREATE_ADMIN_SUCCESS = 'CREATE_ADMIN_SUCCESS'
export const CREATE_ADMIN_FAILURE = 'CREATE_ADMIN_FAILURE'

export const DELETE_ADMIN_STARTED = 'DELETE_ADMIN_STARTED'
export const DELETE_ADMIN_SUCCESS = 'DELETE_ADMIN_SUCCESS'
export const DELETE_ADMIN_FAILURE = 'DELETE_ADMIN_FAILURE'

export const UPDATE_HOURS_STARTED = 'UPDATE_HOURS_STARTED'
export const UPDATE_HOURS_SUCCESS = 'UPDATE_HOURS_SUCCESS'
export const UPDATE_HOURS_FAILURE = 'UPDATE_HOURS_FAILURE'

export const UPDATE_GENERAL_INFO_STARTED = 'UPDATE_GENERAL_INFO_STARTED'
export const UPDATE_GENERAL_INFO_SUCCESS = 'UPDATE_GENERAL_INFO_SUCCESS'
export const UPDATE_GENERAL_INFO_FAILURE = 'UPDATE_GENERAL_INFO_FAILURE'

export const GET_GENERAL_INFO_STARTED = 'GET_GENERAL_INFO_STARTED'
export const GET_GENERAL_INFO_SUCCESS = 'GET_GENERAL_INFO_SUCCESS'
export const GET_GENERAL_INFO_FAILURE = 'GET_GENERAL_INFO_FAILURE'

export const GET_HOURS_STARTED = 'GET_HOURS_STARTED'
export const GET_HOURS_SUCCESS = 'GET_HOURS_SUCCESS'
export const GET_HOURS_FAILURE = 'GET_HOURS_FAILURE'

export const GET_ADMINS_STARTED = 'GET_ADMINS_STARTED'
export const GET_ADMINS_SUCCESS = 'GET_ADMINS_SUCCESS'
export const GET_ADMINS_FAILURE = 'GET_ADMINS_FAILURE'

export const GET_GOOGLE_REVIEW_STARTED = 'GET_GOOGLE_REVIEW_STARTED'
export const GET_GOOGLE_REVIEW_SUCCESS = 'GET_GOOGLE_REVIEW_SUCCESS'
export const GET_GOOGLE_REVIEW_FAILURE = 'GET_GOOGLE_REVIEW_FAILURE'

export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE"