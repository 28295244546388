export const SEND_INVOICE_TO_EMAIL_STARTED = "SEND_INVOICE_TO_EMAIL_STARTED"
export const SEND_INVOICE_TO_EMAIL_SUCCESS = "SEND_INVOICE_TO_EMAIL_SUCCESS"
export const SEND_INVOICE_TO_EMAIL_FAILURE = "SEND_INVOICE_TO_EMAIL_FAILURE"


export const DISCARD_INVOICE_STARTED = "DISCARD_INVOICE_STARTED"
export const DISCARD_INVOICE_SUCCESS = "DISCARD_INVOICE_SUCCESS"
export const DISCARD_INVOICE_FAILURE = "DISCARD_INVOICE_FAILURE"


export const SEND_REVIEW_REQUEST_STARTED = "SEND_REVIEW_REQUEST_STARTED"
export const SEND_REVIEW_REQUEST_SUCCESS = "SEND_REVIEW_REQUEST_SUCCESS"
export const SEND_REVIEW_REQUEST_FAILURE = "SEND_REVIEW_REQUEST_FAILURE"



export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE"