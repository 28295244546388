import {
    COLLAPSE_MENU,
    COLLAPSE_MENU_DONE,
} from "../../constants/responsive/responsive";

const initialState = {
    isMenuCollapsed: window.innerWidth <= 1200 ? true : false,
    isTablet: window.innerWidth <= 1200 && window.innerWidth > 780? true: false,
    isMobile: window.innerWidth <= 780 ? true : false,
    reloadLayout: false,
};

export default function responsive(state = initialState, action) {
    switch (action.type) {
        case COLLAPSE_MENU:
            return {
                ...state,
                isMenuCollapsed: action.payload,
                reloadLayout: true,
            };
        case COLLAPSE_MENU_DONE:
            return {
                ...state,
                reloadLayout: false,
            };
        default:
            return {
                ...state,
            };
    }
}
