import axios from "axios";

let baseURL = "https://endpoint.partnersdash.com"
if (process.env.NODE_ENV && process.env.NODE_ENV === 'development') {
  baseURL = null
}

export default axios.create({
  withCredentials: true,
  baseURL
});
