export const CREATE_APPOINTMENT_STARTED = "CREATE_APPOINTMENT_STARTED"
export const CREATE_APPOINTMENT_SUCCESS = "CREATE_APPOINTMENT_SUCCESS"
export const CREATE_APPOINTMENT_FAILURE = "CREATE_APPOINTMENT_FAILURE"

export const GET_APPOINTMENTS_STARTED = "GET_APPOINTMENTS_STARTED"
export const GET_APPOINTMENTS_SUCCESS = "GET_APPOINTMENTS_SUCCESS"
export const GET_APPOINTMENTS_FAILURE = "GET_APPOINTMENTs_FAILURE"



export const UPDATE_APPOINTMENT_SERVICES_STARTED = "UPDATE_APPOINTMENT_SERVICES_STARTED"
export const UPDATE_APPOINTMENT_SERVICES_SUCCESS = "UPDATE_APPOINTMENT_SERVICES_SUCCESS"
export const UPDATE_APPOINTMENT_SERVICES_FAILURE = "UPDATE_APPOINTMENT_SERVICES_FAILURE"

export const CANCEL_APPOINTMENT_STARTED = "CANCEL_APPOINTMENT_STARTED"
export const CANCEL_APPOINTMENT_SUCCESS = "CANCEL_APPOINTMENT_SUCCESS"
export const CANCEL_APPOINTMENT_FAILURE = "CANCEL_APPOINTMENT_FAILURE"

export const RESCHEDULE_APPOINTMENT_STARTED = "RESCHEDULE_APPOINTMENT_STARTED"
export const RESCHEDULE_APPOINTMENT_SUCCESS = "RESCHEDULE_APPOINTMENT_SUCCESS"
export const RESCHEDULE_APPOINTMENT_FAILURE = "RESCHEDULE_APPOINTMENT_FAILURE"

export const UPDATE_STATUS_STARTED = "UPDATE_STATUS_STARTED"
export const UPDATE_STATUS_SUCCESS = "UPDATE_STATUS_SUCCESS"
export const UPDATE_STATUS_FAILURE = "UPDATE_STATUS_FAILURE"

export const CONFIRM_APPOINTMENT_STARTED = "CONFIRM_APPOINTMENT_STARTED"
export const CONFIRM_APPOINTMENT_SUCCESS = "CONFIRM_APPOINTMENT_SUCCESS"
export const CONFIRM_APPOINTMENT_FAILURE = "CONFIRM_APPOINTMENT_FAILURE"


export const CHECKED_IN_STARTED = "CHECKED_IN_STARTED"
export const CHECKED_IN_SUCCESS = "CHECKED_IN_SUCCESS"
export const CHECKED_IN_FAILURE = "CHECKED_IN_FAILURE"


export const GET_APPOINTMENT_DETAIL_STARTED = "GET_APPOINTMENT_DETAIL_STARTED"
export const GET_APPOINTMENT_DETAIL_SUCCESS = "GET_APPOINTMENT_DETAIL_SUCCESS"
export const GET_APPOINTMENT_DETAIL_FAILURE = "GET_APPOINTMENT_DETAIL_FAILURE"

export const UPDATE_APPOINTMENT_STAFF_FOR_SERVICE_STARTED = "UPDATE_APPOINTMENT_STAFF_FOR_SERVICE_STARTED"
export const UPDATE_APPOINTMENT_STAFF_FOR_SERVICE_SUCCESS = "UPDATE_APPOINTMENT_STAFF_FOR_SERVICE_SUCCESS"
export const UPDATE_APPOINTMENT_STAFF_FOR_SERVICE_FAILURE = "UPDATE_APPOINTMENT_STAFF_FOR_SERVICE_FAILURE"

export const SEND_CLIENT_TEXT_REMINDER_STARTED = "SEND_CLIENT_TEXT_REMINDER_STARTED"
export const SEND_CLIENT_TEXT_REMINDER_SUCCESS = "SEND_CLIENT_TEXT_REMINDER_SUCCESS"
export const SEND_CLIENT_TEXT_REMINDER_FAILURE = "SEND_CLIENT_TEXT_REMINDER_FAILURE"

export const CREATE_BLOCK_TIME_STARTED = "CREATE_BLOCK_TIME_STARTED"
export const CREATE_BLOCK_TIME_SUCCESS = "CREATE_BLOCK_TIME_SUCCESS"
export const CREATE_BLOCK_TIME_FAILURE = "CREATE_BLOCK_TIME_FAILURE"

export const UPDATE_BLOCK_TIME_STARTED = "UPDATE_BLOCK_TIME_STARTED"
export const UPDATE_BLOCK_TIME_SUCCESS = "UPDATE_BLOCK_TIME_SUCCESS"
export const UPDATE_BLOCK_TIME_FAILURE = "UPDATE_BLOCK_TIME_FAILURE"

export const REMOVE_BLOCK_TIME_STARTED = "REMOVE_BLOCK_TIME_STARTED"
export const REMOVE_BLOCK_TIME_SUCCESS = "REMOVE_BLOCK_TIME_SUCCESS"
export const REMOVE_BLOCK_TIME_FAILURE = "REMOVE_BLOCK_TIME_FAILURE"

export const GET_BLOCK_TIMES_STARTED = "GET_BLOCK_TIMES_STARTED"
export const GET_BLOCK_TIMES_SUCCESS = "GET_BLOCK_TIMES_SUCCESS"
export const GET_BLOCK_TIMES_FAILURE = "GET_BLOCK_TIMES_FAILURE"

export const REPLY_REVIEW_STARTED = "REPLY_REVIEW_STARTED"
export const REPLY_REVIEW_SUCCESS = "REPLY_REVIEW_SUCCESS"
export const REPLY_REVIEW_FAILURE = "REPLY_REVIEW_FAILURE"

export const SEND_REVIEW_SMS_STARTED = "SEND_REVIEW_SMS_STARTED"
export const SEND_REVIEW_SMS_SUCCESS = "SEND_REVIEW_SMS_SUCCESS"
export const SEND_REVIEW_SMS_FAILURE = "SEND_REVIEW_SMS_FAILURE"

export const GET_APPOINTMENTS_FOR_DUPLICATE_STARTED = "GET_APPOINTMENTS_FOR_DUPLICATE_STARTED"
export const GET_APPOINTMENTS_FOR_DUPLICATE_SUCCESS = "GET_APPOINTMENTS_FOR_DUPLICATE_SUCCESS"
export const GET_APPOINTMENTS_FOR_DUPLICATE_FAILURE = "GET_APPOINTMENTS_FOR_DUPLICATE_FAILURE"

export const DUPLICATE_SERVICE_STARTED = "DUPLICATE_SERVICE_STARTED"
export const DUPLICATE_SERVICE_SUCCESS = "DUPLICATE_SERVICE_SUCCESS"
export const DUPLICATE_SERVICE_FAILURE = "DUPLICATE_SERVICE_FAILURE"

export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE"


