import {
    UPDATE_BUSINESS_CLIENT_SETTINGS_STARTED,
    UPDATE_BUSINESS_CLIENT_SETTINGS_SUCCESS,
    UPDATE_BUSINESS_CLIENT_SETTINGS_FAILURE,
    GET_BUSINESS_CLIENT_SETTINGS_STARTED,
    GET_BUSINESS_CLIENT_SETTINGS_SUCCESS,
    GET_BUSINESS_CLIENT_SETTINGS_FAILURE,
    REMOVE_ERROR_MESSAGE
} from "../../constants/business-client-settings/businessClientSettings"

const initialState = {
    settings: null,
    loading: false,
    error: null,
    message: null,
};


export default function businessClientSettings(state = initialState, action) {
    switch (action.type) {
        case GET_BUSINESS_CLIENT_SETTINGS_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            }
        case GET_BUSINESS_CLIENT_SETTINGS_SUCCESS:
            return {
                ...state,
                settings: action.payload.businessClientSettings,
                loading: false,
                error: null,
                message: null,
            }
        case GET_BUSINESS_CLIENT_SETTINGS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
            }

        case UPDATE_BUSINESS_CLIENT_SETTINGS_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            }
        case UPDATE_BUSINESS_CLIENT_SETTINGS_SUCCESS:
            return {
                ...state,
                settings: action.payload.businessClientSettings,
                loading: false,
                error: null,
                message: "Business client settings has been saved successfully!",
            }
        case UPDATE_BUSINESS_CLIENT_SETTINGS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
            }

        case REMOVE_ERROR_MESSAGE: {
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
            };
        }
        default:
            return {
                ...state,
            };
    }
}