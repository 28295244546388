import React, { Component } from "react";
import { connect } from "react-redux";
import { Badge } from "antd";
import { BellTwoTone } from "@ant-design/icons";
import NotificationDrawer from "../../../component/common/Notification/NotificationDrawer";
import { getNotificationsCount, getNotifications, loadMoreNotifications } from "../../../redux/actions/notification/notification";
import notificationSound from "./sms_notification.mp3";
import "../../../component/common/Notification/Notification.css";

const NOTIFICATION_TIMER_LENGTH = 60000;

class Notifications extends Component {
    state = {
        showDrawer: false,
        notificationCurrentPage: 1,
    };

    componentDidMount() {
        this.props.getNotificationsCount();
        this.props.getNotifications();

        this.countNotificationId = setInterval(() => this.props.getNotificationsCount(), NOTIFICATION_TIMER_LENGTH);
    };

    // When a new notification is added then reset the list
    componentDidUpdate(prevProps) {
        if (prevProps.unreadNotifications < this.props.unreadNotifications) {
            new Audio(notificationSound).play();
            this.props.getNotifications();
            this.setState({ notificationCurrentPage: 1 })
        }
        if (prevProps.unreadNotifications !== this.props.unreadNotifications) {
            if (this.props.unreadNotifications === 0) {
                document.title = "Dashbooking Partner App"
            } else {
                document.title = `(${this.props.unreadNotifications}) Dashbooking Partner App`
            }
        }
    };

    componentWillUnmount() {
        clearInterval(this.countNotificationId);
    };

    handleDrawer = (action) => {
        this.setState({ showDrawer: action });
    };

    onLoadMore = () => {
        let { notificationCurrentPage } = this.state;
        notificationCurrentPage++;
        this.setState({ notificationCurrentPage });
        this.props.loadMoreNotifications(notificationCurrentPage);
    };

    render () {
        const { showDrawer } = this.state;
        const {  history, unreadNotifications } = this.props;
     
        return (
            <div>
                <Badge className="notification-button" count={unreadNotifications} onClick={(_) => this.handleDrawer(true)}>
                    <BellTwoTone
                        style={{
                            fontSize: "1.5em",
                        }}
                    />
                </Badge>
                {showDrawer && <NotificationDrawer
                    showDrawer={showDrawer}
                    handleDrawer={this.handleDrawer}
                    onLoadMore={this.onLoadMore}
                    history={history}
                />}
            </div>
        );
    }
};

const mapStateToProps = (state) => ({
    error: state.notification.errror,
    message: state.notification.message,
    countNotifications: state.notification.count,
    unreadNotifications: state.notification.count.unreadNotifications
});

const mapDispatchToProps = (dispatch) => {
    return {
        getNotificationsCount: () => dispatch(getNotificationsCount()),
        getNotifications: () => dispatch(getNotifications()),
        loadMoreNotifications: (page) => dispatch(loadMoreNotifications(page)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
