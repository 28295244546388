export const GET_TIME_CLOCK_STARTED = "GET_TIME_CLOCK_STARTED"
export const GET_TIME_CLOCK_SUCCESS = "GET_TIME_CLOCK_SUCCESS"
export const GET_TIME_CLOCK_FAILURE = "GET_TIME_CLOCK_FAILURE"

export const STAFF_CLOCK_IN_AND_OUT_STARTED = "STAFF_CLOCK_IN_AND_OUT_STARTED"
export const STAFF_CLOCK_IN_AND_OUT_SUCCESS = "STAFF_CLOCK_IN_AND_OUT_SUCCESS"
export const STAFF_CLOCK_IN_AND_OUT_FAILURE = "STAFF_CLOCK_IN_AND_OUT_FAILURE"

export const GET_TIME_CLOCK_HISTORY_STARTED = "GET_TIME_CLOCK_HISTORY_STARTED"
export const GET_TIME_CLOCK_HISTORY_SUCCESS = "GET_TIME_CLOCK_HISTORY_SUCCESS"
export const GET_TIME_CLOCK_HISTORY_FAILURE = "GET_TIME_CLOCK_HISTORY_FAILURE"

export const UPDATE_STAFF_SHIFT_STARTED = "UPDATE_STAFF_SHIFT_STARTED"
export const UPDATE_STAFF_SHIFT_SUCCESS = "UPDATE_STAFF_SHIFT_SUCCESS"
export const UPDATE_STAFF_SHIFT_FAILURE = "UPDATE_STAFF_SHIFT_FAILURE"

export const DELETE_STAFF_SHIFT_STARTED = "DELETE_STAFF_SHIFT_STARTED"
export const DELETE_STAFF_SHIFT_SUCCESS = "DELETE_STAFF_SHIFT_SUCCESS"
export const DELETE_STAFF_SHIFT_FAILURE = "DELETE_STAFF_SHIFT_FAILURE"

export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE"
