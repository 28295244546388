import {
    GET_ALL_PROMOTIONS_STARTED,
    GET_ALL_PROMOTIONS_SUCCESS,
    GET_ALL_PROMOTIONS_FAILURE,
    GET_ALL_ACTIVE_PROMOTIONS_STARTED,
    GET_ALL_ACTIVE_PROMOTIONS_SUCCESS,
    GET_ALL_ACTIVE_PROMOTIONS_FAILURE,
    GET_ACTIVE_PROMOTIONS_STARTED,
    GET_ACTIVE_PROMOTIONS_SUCCESS,
    GET_ACTIVE_PROMOTIONS_FAILURE,
    CREATE_PROMOTION_STARTED,
    CREATE_PROMOTION_SUCCESS,
    CREATE_PROMOTION_FAILURE,
    UPDATE_PROMOTION_STARTED,
    UPDATE_PROMOTION_SUCCESS,
    UPDATE_PROMOTION_FAILURE,
    DELETE_PROMOTION_STARTED,
    DELETE_PROMOTION_SUCCESS,
    DELETE_PROMOTION_FAILURE,
    SEND_PROMOTION_STARTED,
    SEND_PROMOTION_SUCCESS,
    SEND_PROMOTION_FAILURE,
    SEND_PROMOTION_EMAIL_STARTED,
    SEND_PROMOTION_EMAIL_SUCCESS,
    SEND_PROMOTION_EMAIL_FAILURE,
    REMOVE_ERROR_MESSAGE,
} from "../../constants/promotion/promotion";

const initialState = {
    promotions: [],
    activePromotions: [],
    activePromotion: null,
    loading: false,
    updateLoading: false,
    error: null,
    message: null,
};

export default function promotion(state = initialState, action) {
    switch (action.type) {
        case SEND_PROMOTION_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            }
        case SEND_PROMOTION_SUCCESS:
            return {
                ...state,
                loading: false,
                message: "Your promotion has been sent successfully!",
            }
        case SEND_PROMOTION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        case SEND_PROMOTION_EMAIL_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            }
        case SEND_PROMOTION_EMAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                message: "Your promotion has been sent successfully!",
            }
        case SEND_PROMOTION_EMAIL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        case GET_ALL_PROMOTIONS_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case GET_ALL_PROMOTIONS_SUCCESS:
            return {
                ...state,
                promotions: action.payload.promotions,
                loading: false,
            };

        case GET_ALL_PROMOTIONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };

        case GET_ALL_ACTIVE_PROMOTIONS_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case GET_ALL_ACTIVE_PROMOTIONS_SUCCESS:
            return {
                ...state,
                activePromotions: action.payload.promotions,
                loading: false,
            };

        case GET_ALL_ACTIVE_PROMOTIONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };

        case GET_ACTIVE_PROMOTIONS_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };

        case GET_ACTIVE_PROMOTIONS_SUCCESS:
            return {
                ...state,
                activePromotion: action.payload.promotion,
                loading: false,
            };

        case GET_ACTIVE_PROMOTIONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };

        case CREATE_PROMOTION_STARTED:
            return {
                ...state,
                updateLoading: true,
                error: null,
                message: null,
            };
        case CREATE_PROMOTION_SUCCESS:
            let oldPromotions = state.promotions
            return {
                ...state,
                promotions: [...oldPromotions, action.payload.promotion],
                updateLoading: false,
                message: "New promotion has been created successfully!"
            };

        case CREATE_PROMOTION_FAILURE:
            return {
                ...state,
                updateLoading: false,
                error: action.payload.error
            };

        case UPDATE_PROMOTION_STARTED:
            return {
                ...state,
                updateLoading: true,
                error: null,
                message: null,
            };
        case UPDATE_PROMOTION_SUCCESS:
            let updatedPromotions = state.promotions
            for (let i = 0; i < updatedPromotions.length; i++) {
                if (updatedPromotions[i]._id === action.payload.promotion._id) {
                    updatedPromotions[i] = action.payload.promotion
                }
            }
            return {
                ...state,
                promotions: updatedPromotions,
                updateLoading: false,
                message: "The promotion has been updated successfully!"
            };

        case UPDATE_PROMOTION_FAILURE:
            return {
                ...state,
                updateLoading: false,
                error: action.payload.error
            };

        case DELETE_PROMOTION_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case DELETE_PROMOTION_SUCCESS:
            let removedPromotions = state.promotions
            removedPromotions = removedPromotions.filter(promotion => promotion._id !== action.payload.promotion._id)
            return {
                ...state,
                loading: false,
                promotions: removedPromotions,
                message: 'The promotion has been removed successfully!'

            };
        case DELETE_PROMOTION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };

        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                error: null,
                message: null
            };
        default:
            return {
                ...state,
            };
    }
}