export const GET_SALE_FOR_TODAY_STARTED = "GET_SALE_FOR_TODAY_STARTED"
export const GET_SALE_FOR_TODAY_SUCCESS = "GET_SALE_FOR_TODAY_SUCCESS"
export const GET_SALE_FOR_TODAY_FAILURE = "GET_SALE_FOR_TODAY_FAILURE"


export const GET_SALE_FOR_APPOINTMENT_STARTED = "GET_SALE_FOR_APPOINTMENT_STARTED"
export const GET_SALE_FOR_APPOINTMENT_SUCCESS = "GET_SALE_FOR_APPOINTMENT_SUCCESS"
export const GET_SALE_FOR_APPOINTMENT_FAILURE = "GET_SALE_FOR_APPOINTMENT_FAILURE"


export const SAVE_SALES_FOR_APPOINTMENT_STARTED = "SAVE_SALES_FOR_APPOINTMENT_STARTED"
export const SAVE_SALES_FOR_APPOINTMENT_SUCCESS = "SAVE_SALES_FOR_APPOINTMENT_SUCCESS"
export const SAVE_SALES_FOR_APPOINTMENT_FAILURE = "SAVE_SALES_FOR_APPOINTMENT_FAILURE"

export const SAVE_SALES_FOR_APPOINTMENT_FAST_CHECKOUT_STARTED = "SAVE_SALES_FOR_APPOINTMENT_FAST_CHECKOUT_STARTED"
export const SAVE_SALES_FOR_APPOINTMENT_FAST_CHECKOUT_SUCCESS = "SAVE_SALES_FOR_APPOINTMENT_FAST_CHECKOUT_SUCCESS"
export const SAVE_SALES_FOR_APPOINTMENT_FAST_CHECKOUT_FAILURE = "SAVE_SALES_FOR_APPOINTMENT_FAST_CHECKOUT_FAILURE"

export const ADD_PAYMENT_TO_INVOICE_STARTED = "ADD_PAYMENT_TO_INVOICE_STARTED"
export const ADD_PAYMENT_TO_INVOICE_SUCCESS = "ADD_PAYMENT_TO_INVOICE_SUCCESS"
export const ADD_PAYMENT_TO_INVOICE_FAILURE = "ADD_PAYMENT_TO_INVOICE_FAILURE"


export const UPDATE_REFUND_INVOICE_STARTED = "UPDATE_REFUND_INVOICE_STARTED"
export const UPDATE_REFUND_INVOICE_SUCCESS = "UPDATE_REFUND_INVOICE_SUCCESS"
export const UPDATE_REFUND_INVOICE_FAILURE = "UPDATE_REFUND_INVOICE_FAILURE"



export const GET_INVOICE_DETAIL_STARTED = "GET_INVOICE_DETAIL_STARTED"
export const GET_INVOICE_DETAIL_SUCCESS = "GET_INVOICE_DETAIL_SUCCESS"
export const GET_INVOICE_DETAIL_FAILURE = "GET_INVOICE_DETAIL_FAILURE"



// export const VOID_INVOICE_STARTED = "VOID_INVOICE_STARTED"
// export const VOID_INVOICE_SUCCESS = "VOID_INVOICE_SUCCESS"
// export const VOID_INVOICE_FAILURE = "VOID_INVOICE_FAILURE"

export const UPDATE_STAFF_FOR_SALE_STARTED = "UPDATE_STAFF_FOR_SALE_STARTED"
export const UPDATE_STAFF_FOR_SALE_SUCCESS = "UPDATE_STAFF_FOR_SALE_SUCCESS"
export const UPDATE_STAFF_FOR_SALE_FAILURE = "UPDATE_STAFF_FOR_SALE_FAILURE"

// export const CLEAR_INVOICE = "CLEAR_INVOICE"

export const EDIT_INVOICE_STARTED = "EDIT_INVOICE_STARTED"
export const EDIT_INVOICE_SUCCESS = "EDIT_INVOICE_SUCCESS"
export const EDIT_INVOICE_FAILURE = "EDIT_INVOICE_FAILURE"

export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE"