import {
    CREATE_STAFF_STARTED,
    CREATE_STAFF_SUCCESS,
    CREATE_STAFF_FAILURE,
    GET_STAFF_STARTED,
    GET_STAFF_SUCCESS,
    GET_STAFF_FAILURE,
    UPDATE_STAFF_DETAILS_STARTED,
    UPDATE_STAFF_DETAILS_SUCCESS,
    UPDATE_STAFF_DETAILS_FAILURE,
    DELETE_STAFF_STARTED,
    DELETE_STAFF_SUCCESS,
    DELETE_STAFF_FAILURE,
    UPDATE_STAFF_SERVICES_STARTED,
    UPDATE_STAFF_SERVICES_SUCCESS,
    UPDATE_STAFF_SERVICES_FAILURE,
    UPDATE_STAFF_ORDER_STARTED,
    UPDATE_STAFF_ORDER_SUCCESS,
    UPDATE_STAFF_ORDER_FAILURE,
    GET_STAFF_ASSIGN_SETTINGS_STARTED,
    GET_STAFF_ASSIGN_SETTINGS_SUCCESS,
    GET_STAFF_ASSIGN_SETTINGS_FAILURE,
    UPDATE_STAFF_ASSIGN_SETTINGS_STARTED,
    UPDATE_STAFF_ASSIGN_SETTINGS_SUCCESS,
    UPDATE_STAFF_ASSIGN_SETTINGS_FAILURE,
    GET_STAFF_PRIORITY_STARTED,
    GET_STAFF_PRIORITY_SUCCESS,
    GET_STAFF_PRIORITY_FAILURE,

    REMOVE_ERROR_MESSAGE,
} from "../../constants/staff/staff";
import { sortArray } from "../../../utils/SortArray/SortArray";

const getStaffsFromLocalStorage = () => {
    try {
        const localDataStaffs = localStorage.getItem("staffs");
        if (localDataStaffs) {
            var staffs = JSON.parse(localDataStaffs);
            return staffs;
        }
        return [];
    } catch (error) {
        console.log(error)
        return [];
    }

};

const getStaffHoursFromLocalStorage = () => {
    try {
        const localDataStaffHours = localStorage.getItem("staffHours");
        if (localDataStaffHours) {
            var staffHours = JSON.parse(localDataStaffHours);
            return staffHours;
        }
        return [];
    } catch (error) {
        console.log(error)
        return [];
    }

};

const getHolidaysAndSHourFromLocalStorage = () => {
    try {
        const localDataHolidays = localStorage.getItem("holidaysAndSHours");
        if (localDataHolidays) {
            var holidaysAndSHours = JSON.parse(localDataHolidays);
            return holidaysAndSHours;
        }
        return [];
    } catch (error) {
        console.log(error)
        return [];
    }

}

const getStaffAssignSettingsFromLocalStorage = () => {
    try {
        const localDataStaffAssignSettings = localStorage.getItem("staffAssignSettings");
        if (localDataStaffAssignSettings) {
            var staffAssignSettings = JSON.parse(localDataStaffAssignSettings);
            return staffAssignSettings;
        }
        return [];
    } catch (error) {
        console.log(error)
        return [];
    }

}

const initialState = {
    staffs: getStaffsFromLocalStorage(),
    staffHours: getStaffHoursFromLocalStorage(),
    holidays: getHolidaysAndSHourFromLocalStorage(),
    loading: false,
    error: null,
    message: null,
    assignStaffSettings: getStaffAssignSettingsFromLocalStorage(),
    loadingAssignStaffSettings: false,
    staffPriority: [],
    loadingStaffPriority: false,
    component: "",
};

export default function staff(state = initialState, action) {
    let updatedStaff = {};
    let updatedStaffHour = {};
    let newStaffArray = [];
    let newStaffHourArray = [];
    switch (action.type) {
        case CREATE_STAFF_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "Staffs",
            };
        case CREATE_STAFF_SUCCESS:
            updatedStaff = action.payload.data.staff;
            updatedStaffHour = action.payload.data.staffHour;
            newStaffArray = [...state.staffs, updatedStaff];
            newStaffHourArray = [...state.staffHours, updatedStaffHour];
            localStorage.setItem("staffs", JSON.stringify(newStaffArray));
            localStorage.setItem(
                "staffHours",
                JSON.stringify(newStaffHourArray)
            );
            return {
                ...state,
                loading: false,
                staffs: newStaffArray,
                staffHours: newStaffHourArray,
                message: "Staff was saved successfully!",
            };

        case CREATE_STAFF_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
            };
        case UPDATE_STAFF_DETAILS_STARTED:
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
                component: "Staffs",
            };
        case UPDATE_STAFF_DETAILS_SUCCESS:
            updatedStaff = action.payload.data.staff;
            updatedStaffHour = action.payload.data.staffHour;

            newStaffHourArray = state.staffHours.map((staffHour) => {
                if (staffHour._id === updatedStaffHour._id) {
                    return updatedStaffHour;
                }
                return staffHour;
            });

            newStaffArray = state.staffs.map((staff) => {
                if (staff._id === updatedStaff._id) {
                    return updatedStaff;
                }
                return staff;
            });
            localStorage.setItem("staffs", JSON.stringify(newStaffArray));
            localStorage.setItem(
                "staffHours",
                JSON.stringify(newStaffHourArray)
            );
            return {
                ...state,
                loading: false,
                staffs: newStaffArray,
                staffHours: newStaffHourArray,
                message: "Staff was saved successfully!",
            };
        case UPDATE_STAFF_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
            };
        case GET_STAFF_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "StaffContainer",
            };
        case GET_STAFF_SUCCESS:
            return {
                ...state,
                staffs: action.payload.data.staffs,
                staffHours: action.payload.data.staffHours,
                holidays: action.payload.data.holidays,
                error: null,
                message: null,
                loading: false,
            };
        case GET_STAFF_FAILURE:
            localStorage.removeItem("staffs");
            localStorage.removeItem("staffHours");
            return {
                ...state,
                error: action.payload.error,
                message: null,
                loading: false,
            };
        case DELETE_STAFF_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "StaffContainer",
            };
        case DELETE_STAFF_SUCCESS:
            updatedStaff = action.payload.staff;

            newStaffArray = state.staffs.filter(
                (staff) => staff._id !== updatedStaff._id
            );
            newStaffHourArray = state.staffHours.filter(
                (staffHour) => staffHour.staff !== updatedStaff._id
            );
            localStorage.setItem("staffs", JSON.stringify(newStaffArray));
            localStorage.setItem(
                "staffHours",
                JSON.stringify(newStaffHourArray)
            );
            return {
                ...state,
                loading: false,
                staffs: newStaffArray,
                staffHours: newStaffHourArray,
                message: "Staff was deleted successfully!",
                error: null,
            };
        case DELETE_STAFF_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
            };

        case UPDATE_STAFF_ORDER_STARTED:
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
            };
        case UPDATE_STAFF_ORDER_SUCCESS:
            newStaffArray = sortArray(
                state.staffs,
                action.payload.staffOrder,
                "_id"
            );
            localStorage.setItem("staffs", JSON.stringify(newStaffArray));
            // console.log(newStaffArray)
            return {
                ...state,
                loading: false,
                staffs: newStaffArray,
            };
        case UPDATE_STAFF_ORDER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
            };

        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
            };

        case UPDATE_STAFF_SERVICES_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case UPDATE_STAFF_SERVICES_SUCCESS:
            return {
                ...state,
                loading: false,
                message: "Staff has been updated successfully!",
            };
        case UPDATE_STAFF_SERVICES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };

        case GET_STAFF_ASSIGN_SETTINGS_STARTED:
            return {
                ...state,
                loadingAssignStaffSettings: true,
                error: null,
                message: null,
            };

        case GET_STAFF_ASSIGN_SETTINGS_SUCCESS:
            localStorage.setItem("staffAssignSettings", JSON.stringify(action.payload.data));
            return {
                ...state,
                assignStaffSettings: action.payload.data,
                loadingAssignStaffSettings: false,
            };

        case GET_STAFF_ASSIGN_SETTINGS_FAILURE:
            return {
                ...state,
                loadingAssignStaffSettings: false,
                error: action.payload.error,
            };

        case UPDATE_STAFF_ASSIGN_SETTINGS_STARTED:
            return {
                ...state,
                loadingAssignStaffSettings: true,
                error: null,
                message: null,
            };

        case UPDATE_STAFF_ASSIGN_SETTINGS_SUCCESS:
            localStorage.setItem("staffAssignSettings", JSON.stringify(action.payload.data));
            return {
                ...state,
                assignStaffSettings: action.payload.data,
                loadingAssignStaffSettings: false,
                message: "Assign staff settings has been updated successfully!",
            };

        case UPDATE_STAFF_ASSIGN_SETTINGS_FAILURE:
            return {
                ...state,
                loadingAssignStaffSettings: false,
                error: action.payload.error,
            };

        case GET_STAFF_PRIORITY_STARTED:
            return {
                ...state,
                loadingStaffPriority: true,
                error: null,
                message: null,
            };

        case GET_STAFF_PRIORITY_SUCCESS:
            return {
                ...state,
                staffPriority: action.payload.data.sortedStaffList,
                loadingStaffPriority: false,
            };

        case GET_STAFF_PRIORITY_FAILURE:
            return {
                ...state,
                loadingStaffPriority: false,
                error: action.payload.error,
            };

        default:
            return {
                ...state,
            };
    }
}
