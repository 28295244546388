import {
    CREATE_STAFF_ACCOUNT_STARTED,
    CREATE_STAFF_ACCOUNT_SUCCESS,
    CREATE_STAFF_ACCOUNT_FAILURE,
    GET_STAFF_ACCOUNT_LIST_FAILURE,
    GET_STAFF_ACCOUNT_LIST_STARTED,
    GET_STAFF_ACCOUNT_LIST_SUCCESS,
    UPDATE_STAFF_ACCOUNT_STARTED,
    UPDATE_STAFF_ACCOUNT_SUCCESS,
    UPDATE_STAFF_ACCOUNT_FAILURE,
    DELETE_STAFF_ACCOUNT_STARTED,
    DELETE_STAFF_ACCOUNT_SUCCESS,
    DELETE_STAFF_ACCOUNT_FAILURE,
    LOGOUT_STAFF_ACCOUNT_STARTED,
    LOGOUT_STAFF_ACCOUNT_SUCCESS,
    LOGOUT_STAFF_ACCOUNT_FAILURE,
    RESET_PASSWORD_STAFF_ACCOUNT_STARTED,
    RESET_PASSWORD_STAFF_ACCOUNT_SUCCESS,
    RESET_PASSWORD_STAFF_ACCOUNT_FAILURE,
    REMOVE_ERROR_MESSAGE
} from '../../constants/staff/staffAccount';

const initialState = {
    staffList: [],
    staffAccountList: [],
    loadingStaffAccount: false,
    error: null,
    message: null,
};


export default function staffAccount(state = initialState, action) {
    let updatedStaffAccount = {};
    let newStaffAccountList = []
    switch (action.type) {
        case CREATE_STAFF_ACCOUNT_STARTED:
            return {
                ...state,
                loadingStaffAccount: true,
                error: null,
                message: null
            };
        case CREATE_STAFF_ACCOUNT_SUCCESS:
            updatedStaffAccount = action.payload.data.staffAccount
            newStaffAccountList = [...state.staffAccountList, updatedStaffAccount];
            return {
                ...state,
                loadingStaffAccount: false,
                staffAccountList: newStaffAccountList,
                message: "Staff account was created successfully!",
            };
        case CREATE_STAFF_ACCOUNT_FAILURE:
            return {
                ...state,
                loadingStaffAccount: false,
                error: action.payload.error,
                message: null,
            };
        case GET_STAFF_ACCOUNT_LIST_STARTED:
            return {
                ...state,
                loadingStaffAccount: true,
                error: null,
                message: null
            };
        case GET_STAFF_ACCOUNT_LIST_SUCCESS:
            return {
                ...state,
                loadingStaffAccount: false,
                staffList: action.payload.data.staffList,
                staffAccountList: action.payload.data.staffAccountList,
                message: null,
            };
        case GET_STAFF_ACCOUNT_LIST_FAILURE:
            return {
                ...state,
                loadingStaffAccount: false,
                error: action.payload.error,
                message: null,
            };
        case RESET_PASSWORD_STAFF_ACCOUNT_STARTED:
            return { ...state, loadingStaffAccount: true, error: null, message: null };
        case RESET_PASSWORD_STAFF_ACCOUNT_SUCCESS:
            return { ...state, loadingStaffAccount: false, message: "The staff account password was reset successfully!" };
        case RESET_PASSWORD_STAFF_ACCOUNT_FAILURE:
            return { ...state, loadingStaffAccount: false, error: action.payload.error, message: null };
        case LOGOUT_STAFF_ACCOUNT_STARTED:
            return {
                ...state,
                loadingStaffAccount: true,
                error: null,
                message: null
            };
        case LOGOUT_STAFF_ACCOUNT_SUCCESS:
            return {
                ...state,
                loadingStaffAccount: false,
                message: "The staff account was logged out successfully!",
            };
        case LOGOUT_STAFF_ACCOUNT_FAILURE:
            return {
                ...state,
                loadingStaffAccount: false,
                error: action.payload.error,
                message: null,
            };
        case UPDATE_STAFF_ACCOUNT_STARTED:
            return {
                ...state,
                loadingStaffAccount: true,
                error: null,
                message: null
            };
        case UPDATE_STAFF_ACCOUNT_SUCCESS:
            updatedStaffAccount = action.payload.data.staffAccount
            newStaffAccountList = state.staffAccountList.map((staffAccount) => {
                if (staffAccount._id === updatedStaffAccount._id) {
                    return updatedStaffAccount;
                }
                return staffAccount;
            });

            return {
                ...state,
                loadingStaffAccount: false,
                staffAccountList: newStaffAccountList,
                message: "Staff account was updated successfully!",
            };
        case UPDATE_STAFF_ACCOUNT_FAILURE:
            return {
                ...state,
                loadingStaffAccount: false,
                error: action.payload.error,
                message: null,
            };
        case DELETE_STAFF_ACCOUNT_STARTED:
            return {
                ...state,
                loadingStaffAccount: true,
                error: null,
                message: null
            };
        case DELETE_STAFF_ACCOUNT_SUCCESS:
            updatedStaffAccount = action.payload.data.staffAccount
            newStaffAccountList = state.staffAccountList.filter((staffAccount) =>
                staffAccount._id !== updatedStaffAccount._id
            );
            return {
                ...state,
                loadingStaffAccount: false,
                staffAccountList: newStaffAccountList,
                message: "Staff account was deleted successfully!",
            };
        case DELETE_STAFF_ACCOUNT_FAILURE:
            return {
                ...state,
                loadingStaffAccount: false,
                error: action.payload.error,
                message: null,
            };

        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loadingStaffAccount: false,
                error: null,
                message: null,
            }
        default:
            return {
                ...state,
            };
    }
}