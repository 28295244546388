import {
    GET_HOLIDAYS_STARTED,
    GET_HOLIDAYS_SUCCESS,
    GET_HOLIDAYS_FAILURE,
    GET_HOLIDAY_DETAILS_STARTED,
    GET_HOLIDAY_DETAILS_SUCCESS,
    GET_HOLIDAY_DETAILS_FAILURE,
    CREATE_HOLIDAY_STARTED,
    CREATE_HOLIDAY_SUCCESS,
    CREATE_HOLIDAY_FAILURE,
    UPDATE_HOLIDAY_STARTED,
    UPDATE_HOLIDAY_SUCCESS,
    UPDATE_HOLIDAY_FAILURE,
    DELETE_HOLIDAY_STARTED,
    DELETE_HOLIDAY_SUCCESS,
    DELETE_HOLIDAY_FAILURE,
    COUNT_APPOINTMENT_HOLIDAY_STARTED,
    COUNT_APPOINTMENT_HOLIDAY_SUCCESS,
    COUNT_APPOINTMENT_HOLIDAY_FAILURE,
    REMOVE_ERROR_MESSAGE,
} from "../../constants/holiday/holiday";

const initialState = {
    holidays: [],
    holiday: {},
    countAppointmentsHoliday: [],
    loading: false,
    loadingCountAppointment: false,
    error: null,
    message: null,
    component: "",
};

const getHolidaysAndSHourFromLocalStorage = () => {
    const localDataHolidays = localStorage.getItem("holidaysAndSHours");
    if (localDataHolidays) {
        var holidaysAndSHours = JSON.parse(localDataHolidays);
        return holidaysAndSHours;
    }
    return [];
}

export default function holiday(state = initialState, action) {
    let updatedHoliday = {};
    let newHolidayArray = [];
    let activeHoliday = getHolidaysAndSHourFromLocalStorage()
    switch (action.type) {
        case COUNT_APPOINTMENT_HOLIDAY_STARTED:
            return {
                ...state,
                loadingCountAppointment: true,
                error: null,
                message: null,
            };
        case COUNT_APPOINTMENT_HOLIDAY_SUCCESS:
            return {
                ...state,
                loadingCountAppointment: false,
                countAppointmentsHoliday: action.payload.data,
            };
        case COUNT_APPOINTMENT_HOLIDAY_FAILURE:
            return {
                ...state,
                loadingCountAppointment: false,
                error: action.payload.error,
            };

        case GET_HOLIDAYS_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "HolidayListContainer",
            };
        case GET_HOLIDAYS_SUCCESS:
            return {
                ...state,
                loading: false,
                holidays: action.payload.data,
            };
        case GET_HOLIDAYS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case GET_HOLIDAY_DETAILS_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "HolidayFormContainer",
            };
        case GET_HOLIDAY_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                holiday: action.payload.data,
            };
        case GET_HOLIDAY_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case CREATE_HOLIDAY_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "HolidayFormContainer",
            };
        case CREATE_HOLIDAY_SUCCESS:
            updatedHoliday = action.payload.data;
            newHolidayArray = [...state.holidays, updatedHoliday];
            activeHoliday = [...activeHoliday, updatedHoliday]
            localStorage.setItem("holidaysAndSHours", JSON.stringify(activeHoliday))
            return {
                ...state,
                loading: false,
                holiday: updatedHoliday,
                holidays: newHolidayArray,
                message: "Holiday created successfully",
            };
        case CREATE_HOLIDAY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case UPDATE_HOLIDAY_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "HolidayFormContainer",
            };
        case UPDATE_HOLIDAY_SUCCESS:
            updatedHoliday = action.payload.data;

            newHolidayArray = state.holidays.map(holiday => {
                if (holiday._id === updatedHoliday._id) {
                    return updatedHoliday
                }
                return holiday
            });
            activeHoliday = activeHoliday.map(holiday => {
                if (holiday._id === updatedHoliday._id) {
                    return updatedHoliday
                }
                return holiday
            });
            localStorage.setItem("holidaysAndSHours", JSON.stringify(activeHoliday))
            return {
                ...state,
                loading: false,
                holiday: updatedHoliday,
                holidays: newHolidayArray,
                message: "Holiday updated successfully",
            };
        case UPDATE_HOLIDAY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case DELETE_HOLIDAY_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "HolidayFormContainer",
            };
        case DELETE_HOLIDAY_SUCCESS:
            updatedHoliday = action.payload.data;
            newHolidayArray = state.holidays.filter(holiday =>
                holiday._id !== updatedHoliday._id
            );
            activeHoliday = activeHoliday.filter(holiday =>
                holiday._id !== updatedHoliday._id
            );
            localStorage.setItem("holidaysAndSHours", JSON.stringify(activeHoliday))
            return {
                ...state,
                loading: false,
                holiday: {},
                holidays: newHolidayArray,
                message: "Holiday deleted successfully",
            };
        case DELETE_HOLIDAY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };

        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
            };

        default:
            return {
                ...state,
            };
    }
}
