import {
  SEND_INVOICE_TO_EMAIL_STARTED,
  SEND_INVOICE_TO_EMAIL_SUCCESS,
  SEND_INVOICE_TO_EMAIL_FAILURE,
  REMOVE_ERROR_MESSAGE,
  DISCARD_INVOICE_STARTED,
  DISCARD_INVOICE_SUCCESS,
  DISCARD_INVOICE_FAILURE,
  SEND_REVIEW_REQUEST_STARTED,
  SEND_REVIEW_REQUEST_SUCCESS,
  SEND_REVIEW_REQUEST_FAILURE,
} from "../../constants/invoice/invoice";

const initialState = {
  loading: false,
  error: null,
  message: null,
  redirectToAppointmentPage: false,
  component: ""
};

export default function invoice(state = initialState, action) {
    switch (action.type) {
        case SEND_REVIEW_REQUEST_STARTED:
            return {
                ...state,
                loading: false,
            }
        case SEND_REVIEW_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                message:"Review request has been sent!"
            }
        case SEND_REVIEW_REQUEST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        case DISCARD_INVOICE_STARTED:
            return {
                ...state,
                loading: true,
            }
        case DISCARD_INVOICE_SUCCESS:
            return {
                ...state,
                loading: false,
                message:"Invoice has been discarded!",
                redirectToAppointmentPage: true,
            }
        case DISCARD_INVOICE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                redirectToAppointmentPage: false
            }
        case SEND_INVOICE_TO_EMAIL_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case SEND_INVOICE_TO_EMAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                message: "Email has been sent successfully!"
            };
        case SEND_INVOICE_TO_EMAIL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.data,
            };
        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                redirectToAppointmentPage: false,
                error: null,
                message: null
            };

        default:
            return {
                ...state,
            };
    }
}