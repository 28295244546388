import {
    GET_TAXES_STARTED,
    GET_TAXES_SUCCESS,
    GET_TAXES_FAILURE,
    CREATE_TAX_STARTED,
    CREATE_TAX_SUCCESS,
    CREATE_TAX_FAILURE,
    UPDATE_TAX_STARTED,
    UPDATE_TAX_SUCCESS,
    UPDATE_TAX_FAILURE,
    DELETE_TAX_STARTED,
    DELETE_TAX_SUCCESS,
    DELETE_TAX_FAILURE,
    REMOVE_ERROR_MESSAGE
} from "../../constants/tax/tax";

const getTaxesFromLocalStorage = () => {
    const localTaxData = localStorage.getItem("taxes")
    if(localTaxData) {
        const taxes = JSON.parse(localTaxData)
        return taxes
    }
    return []
}

const initialState = {
    taxes: getTaxesFromLocalStorage(),
    loading: false,
    error: null,
    message: null,
    component: "",
};

export default function tax(state = initialState, action) {
    let updatedTax = {};
    let newTaxArray = [];
    switch (action.type) {
        case GET_TAXES_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "TaxesContainer",
            };
        case GET_TAXES_SUCCESS:
            return {
                ...state,
                loading: false,
                taxes: action.payload.data,
                error: null,
                message: null,
            };
        case GET_TAXES_FAILURE:
            localStorage.removeItem("taxes");
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
            };
        case CREATE_TAX_STARTED:
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
            };
        case CREATE_TAX_SUCCESS:
            updatedTax = action.payload.data;
            newTaxArray = [...state.taxes, updatedTax];
            localStorage.setItem("taxes", JSON.stringify(newTaxArray));
            return {
                ...state,
                loading: false,
                taxes: newTaxArray,
                message: "New tax was added successfully!"
            };
        case CREATE_TAX_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null
            };
        case UPDATE_TAX_STARTED:
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
            };
        case UPDATE_TAX_SUCCESS:
            updatedTax = action.payload.data;
            newTaxArray = state.taxes.map(tax => {
                if (tax._id === updatedTax._id) {
                    return updatedTax
                }
                return tax
            });
            localStorage.setItem("taxes", JSON.stringify(newTaxArray));
            return {
                ...state,
                loading: false,
                taxes: newTaxArray,
                message: "Tax was updated successfully!"
            };
        case UPDATE_TAX_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null
            };
        case DELETE_TAX_STARTED:
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
            };
        case DELETE_TAX_SUCCESS:
            updatedTax = action.payload.tax;
            newTaxArray = state.taxes.filter(
                tax => tax._id !== updatedTax._id
            );
            localStorage.setItem("taxes", JSON.stringify(newTaxArray));
            return {
                ...state,
                loading: false,
                taxes: newTaxArray,
                message: "Tax has been deleted successfully!"
            }
        case DELETE_TAX_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null
            };
        case REMOVE_ERROR_MESSAGE: {
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
            };
        }
        default:
            return {
                ...state,
            };
    }
}
