/**
 * Sort array of objects based on another array
 */

export function sortArray (array, order, key) {
    array.sort( function (a, b) {
      var A = a[key], B = b[key];
      
      if (order.indexOf(A) > order.indexOf(B)) {
        return 1;
      } else {
        return -1;
      }
      
    });
    return array;
  };
  

  /**
 * Example:
 */

// var item_array, item_order, ordered_array;

// item_array = [ 
//   { id: 2, label: 'Two' }
// , { id: 3, label: 'Three' }
// , { id: 5, label: 'Five' }
// , { id: 4, label: 'Four' }
// , { id: 1, label: 'One'}
// ];

// item_order = [1,2,3,4,5];

// ordered_array = sorArray(item_array, item_order, 'id');

// console.log('Ordered:', JSON.stringify(ordered_array));
  