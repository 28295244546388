export const GET_BUSINESS_INFO_STARTED = "GET_BUSINESS_INFO_STARTED"
export const GET_BUSINESS_INFO_SUCCESS = "GET_BUSINESS_INFO_SUCCESS"
export const GET_BUSINESS_INFO_FAILURE = "GET_BUSINESS_INFO_FAILURE"

export const GET_BUSINESS_APPOINTMENT_SETTINGS_STARTED = "GET_BUSINESS_APPOINTMENT_SETTINGS_STARTED"
export const GET_BUSINESS_APPOINTMENT_SETTINGS_SUCCESS = "GET_BUSINESS_APPOINTMENT_SETTINGS_SUCCESS"
export const GET_BUSINESS_APPOINTMENT_SETTINGS_FAILURE = "GET_BUSINESS_APPOINTMENT_SETTINGS_FAILURE"

export const UPDATE_BUSINESS_APPOINTMENT_SETTINGS_STARTED = "UPDATE_BUSINESS_APPOINTMENT_SETTINGS_STARTED"
export const UPDATE_BUSINESS_APPOINTMENT_SETTINGS_SUCCESS = "UPDATE_BUSINESS_APPOINTMENT_SETTINGS_SUCCESS"
export const UPDATE_BUSINESS_APPOINTMENT_SETTINGS_FAILURE = "UPDATE_BUSINESS_APPOINTMENT_SETTINGS_FAILURE"

export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE"
