export const CREATE_GIFT_CARD_STARTED = "CREATE_GIFT_CARD_STARTED"
export const CREATE_GIFT_CARD_SUCCESS = "CREATE_GIFT_CARD_SUCCESS"
export const CREATE_GIFT_CARD_FAILURE = "CREATE_GIFT_CARD_FAILURE"

export const CHECK_DUPLICATED_GIFT_CARD_NUMBER_STARTED = "CHECK_DUPLICATED_GIFT_CARD_NUMBER_STARTED"
export const CHECK_DUPLICATED_GIFT_CARD_NUMBER_SUCCESS = "CHECK_DUPLICATED_GIFT_CARD_NUMBER_SUCCESS"
export const CHECK_DUPLICATED_GIFT_CARD_NUMBER_FAILURE = "CHECK_DUPLICATED_GIFT_CARD_NUMBER_FAILURE"

export const GET_GIFT_CARDS_STARTED = "GET_GIFT_CARDS_STARTED"
export const GET_GIFT_CARDS_SUCCESS = "GET_GIFT_CARDS_SUCCESS"
export const GET_GIFT_CARDS_FAILURE = "GET_GIFT_CARDS_FAILURE"

export const LOAD_MORE_GIFT_CARDS_STARTED = "LOAD_MORE_GIFT_CARDS_STARTED"
export const LOAD_MORE_GIFT_CARDS_SUCCESS = "LOAD_MORE_GIFT_CARDS_SUCCESS"
export const LOAD_MORE_GIFT_CARDS_FAILURE = "LOAD_MORE_GIFT_CARDS_FAILURE"

export const UPDATE_GIFT_CARD_STARTED = "UPDATE_GIFT_CARD_STARTED"
export const UPDATE_GIFT_CARD_SUCCESS = "UPDATE_GIFT_CARD_SUCCESS"
export const UPDATE_GIFT_CARD_FAILURE = "UPDATE_GIFT_CARD_FAILURE"

export const REMOVE_GIFT_CARD_STARTED = "REMOVE_GIFT_CARD_STARTED"
export const REMOVE_GIFT_CARD_SUCCESS = "REMOVE_GIFT_CARD_SUCCESS"
export const REMOVE_GIFT_CARD_FAILURE = "REMOVE_GIFT_CARD_FAILURE"

export const SET_CARDS_FILTER = "SET_CARDS_FILTER"
export const SET_CURRENT_CARD = "SET_CURRENT_CARD"
export const SAVE_GIFT_CARD_FOR_CHECKOUT = "SAVE_GIFT_CARD_FOR_CHECKOUT"
export const UPDATE_GIFT_CARD_FOR_CHECKOUT = "UPDATE_GIFT_CARD_FOR_CHECKOUT"
export const REMOVE_GIFT_CARD_FOR_CHECKOUT = "REMOVE_GIFT_CARD_FOR_CHECKOUT"

export const GET_GIFT_CARDS_STARTED_FOR_PAYMENT = "GET_GIFT_CARDS_STARTED_FOR_PAYMENT"
export const GET_GIFT_CARDS_SUCCESS_FOR_PAYMENT = "GET_GIFT_CARDS_SUCCESS_FOR_PAYMENT"
export const GET_GIFT_CARDS_FAILURE_FOR_PAYMENT = "GET_GIFT_CARDS_FAILURE_FOR_PAYMENT"

export const UPDATE_GIFT_CARD_FOR_PAYMENT_FRONTEND = "UPDATE_GIFT_CARD_FOR_PAYMENT_FRONTEND"

export const SET_CURRENT_CARD_FOR_PAYMENT = "SET_CURRENT_CARD_FOR_PAYMENT"

export const SEND_EMAIL_STARTED = "SEND_EMAIL_STARTED"
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS"
export const SEND_EMAIL_FAILURE = "SEND_EMAIL_FAILURE"

//Clear gift card data before go to check out
export const CLEAR_GIFT_CARD = "CLEAR_GIFT_CARD"

export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE"