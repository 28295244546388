import {
    GET_SALE_FOR_TODAY_STARTED,
    GET_SALE_FOR_TODAY_SUCCESS,
    GET_SALE_FOR_TODAY_FAILURE,
    GET_SALE_FOR_APPOINTMENT_STARTED,
    GET_SALE_FOR_APPOINTMENT_SUCCESS,
    GET_SALE_FOR_APPOINTMENT_FAILURE,
    SAVE_SALES_FOR_APPOINTMENT_STARTED,
    SAVE_SALES_FOR_APPOINTMENT_SUCCESS,
    SAVE_SALES_FOR_APPOINTMENT_FAILURE,
    SAVE_SALES_FOR_APPOINTMENT_FAST_CHECKOUT_STARTED,
    SAVE_SALES_FOR_APPOINTMENT_FAST_CHECKOUT_SUCCESS,
    SAVE_SALES_FOR_APPOINTMENT_FAST_CHECKOUT_FAILURE,
    ADD_PAYMENT_TO_INVOICE_STARTED,
    ADD_PAYMENT_TO_INVOICE_SUCCESS,
    ADD_PAYMENT_TO_INVOICE_FAILURE,
    GET_INVOICE_DETAIL_STARTED,
    GET_INVOICE_DETAIL_SUCCESS,
    GET_INVOICE_DETAIL_FAILURE,
    UPDATE_REFUND_INVOICE_STARTED,
    UPDATE_REFUND_INVOICE_SUCCESS,
    UPDATE_REFUND_INVOICE_FAILURE,
    // VOID_INVOICE_STARTED,
    // VOID_INVOICE_SUCCESS,
    // VOID_INVOICE_FAILURE,
    // CLEAR_INVOICE,
    UPDATE_STAFF_FOR_SALE_STARTED,
    UPDATE_STAFF_FOR_SALE_SUCCESS,
    UPDATE_STAFF_FOR_SALE_FAILURE,
    EDIT_INVOICE_STARTED,
    EDIT_INVOICE_SUCCESS,
    EDIT_INVOICE_FAILURE,
    REMOVE_ERROR_MESSAGE,
} from "../../constants/sale/sale";

const initialState = {
    sales: [],
    sale: null,
    payments: [],
    invoice: null,
    taxIdentities: [],
    loading: false,
    error: null,
    message: null,
    component: "",
};

export default function sale(state = initialState, action) {
    let updatedSales = [];
    let updatedSale = {};
    let updatedInvoice = null;
    let updatedPayments = [];
    switch (action.type) {
        // case CLEAR_INVOICE:
        //     return {
        //         ...state,
        //         invoice: null,
        //         payments: [],
        //         sales: []
        //     }

        case ADD_PAYMENT_TO_INVOICE_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case ADD_PAYMENT_TO_INVOICE_SUCCESS:
            updatedInvoice = action.payload.data.invoice;
            updatedPayments = action.payload.data.existingPayments;

            return {
                ...state,
                loading: false,
                invoice: updatedInvoice,
                payments: updatedPayments,
                error: null,
                message: "Invoice has been saved successfully!",
            };
        case ADD_PAYMENT_TO_INVOICE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case GET_INVOICE_DETAIL_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case GET_INVOICE_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                invoice: action.payload.data.invoice,
                sales: action.payload.data.sales,
                payments: action.payload.data.payments,
                taxIdentities: action.payload.data.taxIdentities

            };
        case GET_INVOICE_DETAIL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
            };
        case SAVE_SALES_FOR_APPOINTMENT_FAST_CHECKOUT_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case SAVE_SALES_FOR_APPOINTMENT_FAST_CHECKOUT_SUCCESS:
            updatedSales = action.payload.data.sales;
            updatedInvoice = action.payload.data.invoice;
            return {
                ...state,
                sales: updatedSales,
                invoice: updatedInvoice,
                loading: false,
                error: null,
                message: null,
            };
        case SAVE_SALES_FOR_APPOINTMENT_FAST_CHECKOUT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
            };
        case SAVE_SALES_FOR_APPOINTMENT_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case SAVE_SALES_FOR_APPOINTMENT_SUCCESS:
            updatedSales = action.payload.data.sales;
            updatedInvoice = action.payload.data.invoice;
            return {
                ...state,
                sales: updatedSales,
                invoice: updatedInvoice,
                loading: false,
                error: null,
                message: null,
            };
        case SAVE_SALES_FOR_APPOINTMENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
            };
        case GET_SALE_FOR_APPOINTMENT_STARTED:
            return {
                ...state,
                loading: true,
                message: null,
                error: null,
            };
        case GET_SALE_FOR_APPOINTMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                sales: action.payload.data,
            };
        case GET_SALE_FOR_APPOINTMENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case GET_SALE_FOR_TODAY_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case GET_SALE_FOR_TODAY_SUCCESS:
            return {
                ...state,
                loading: false,
                sales: action.payload.data,
            };
        case GET_SALE_FOR_TODAY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
            };
        case UPDATE_STAFF_FOR_SALE_STARTED:
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
            };
        case UPDATE_STAFF_FOR_SALE_SUCCESS:
            let { sales } = state;
            updatedSale = action.payload.data;
            updatedSales = sales.map((sale) => {
                if (sale._id === updatedSale._id) {
                    sale.staff = updatedSale.staff;
                    sale.staffName = updatedSale.staffName
                        ? updatedSale.staffName
                        : "";
                }
                return sale;
            });
            return {
                ...state,
                loading: false,
                sales: updatedSales,
            };
        case UPDATE_STAFF_FOR_SALE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
            };
        case UPDATE_REFUND_INVOICE_STARTED: {
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        }
        case UPDATE_REFUND_INVOICE_SUCCESS:
            return {
                ...state,
                loading: false,
                invoice: { ...state.invoice, paymentStatus: "Refund" },
                message: "Invoice has been updated successfully!"
            }
        case UPDATE_REFUND_INVOICE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            }


        case EDIT_INVOICE_STARTED: {
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        }
        case EDIT_INVOICE_SUCCESS:
            return {
                ...state,
                loading: false,
                message: "Invoice has been updated successfully!"
            }
        case EDIT_INVOICE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            }

        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
            };

        default:
            return {
                ...state,
            };
    }
}
