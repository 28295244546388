import {
    GET_LOYALTY_SETTING_STARTED,
    GET_LOYALTY_SETTING_SUCCESS,
    GET_LOYALTY_SETTING_FAILURE,
    CREATE_LOYALTY_SETTING_STARTED,
    CREATE_LOYALTY_SETTING_SUCCESS,
    CREATE_LOYALTY_SETTING_FAILURE,
    UPDATE_LOYALTY_SETTING_STARTED,
    UPDATE_LOYALTY_SETTING_SUCCESS,
    UPDATE_LOYALTY_SETTING_FAILURE,
    UPDATE_LOYALTY_SETTING_NEW_CLIENT_STARTED,
    UPDATE_LOYALTY_SETTING_NEW_CLIENT_SUCCESS,
    UPDATE_LOYALTY_SETTING_NEW_CLIENTG_FAILURE,

    GET_LOYALTY_CONFIG_STARTED,
    GET_LOYALTY_CONFIG_SUCCESS,
    GET_LOYALTY_CONFIG_FAILURE,
    UPDATE_LOYALTY_CONFIG_STARTED,
    UPDATE_LOYALTY_CONFIG_SUCCESS,
    UPDATE_LOYALTY_CONFIG_FAILURE,

    REMOVE_ERROR_MESSAGE,
} from "../../constants/loyaltySetting/loyaltySetting";

const initialState = {
    loading: false,
    error: null,
    message: null,
    loyalty: localStorage.getItem('myObject') ? JSON.parse(localStorage.getItem('myObject')) : null,
    loyaltyConfig: null,
};

export default function loyaltySetting(state = initialState, action) {
    switch (action.type) {
        case GET_LOYALTY_SETTING_STARTED:
            return {
                ...state,
                loading: true,
            }
        case GET_LOYALTY_SETTING_SUCCESS:
            return {
                ...state,
                loyalty: action.payload.data !== "" ? action.payload.data : null,
                loading: false,
            }
        case GET_LOYALTY_SETTING_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case CREATE_LOYALTY_SETTING_STARTED:
            return {
                ...state,
                loading: true,
            }
        case CREATE_LOYALTY_SETTING_SUCCESS:
            return {
                ...state,
                loyalty: action.payload.data,
                message: "Loyalty setting has been created!",
                loading: false,
            }
        case CREATE_LOYALTY_SETTING_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case UPDATE_LOYALTY_SETTING_STARTED:
            return {
                ...state,
                loading: true,
            }
        case UPDATE_LOYALTY_SETTING_SUCCESS:
            return {
                ...state,
                loyalty: action.payload.data,
                message: "Loyalty setting has been updated!",
                loading: false,
            }
        case UPDATE_LOYALTY_SETTING_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case UPDATE_LOYALTY_SETTING_NEW_CLIENT_STARTED:
            return {
                ...state,
                loading: true,
            }
        case UPDATE_LOYALTY_SETTING_NEW_CLIENT_SUCCESS:
            return {
                ...state,
                loyalty: action.payload.data,
                message: "Loyalty setting has been updated!",
                loading: false,
            }
        case UPDATE_LOYALTY_SETTING_NEW_CLIENTG_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case GET_LOYALTY_CONFIG_STARTED:
            return {
                ...state,
                loading: true,
            }
        case GET_LOYALTY_CONFIG_SUCCESS:
            return {
                ...state,
                loyaltyConfig: action.payload.data,
                loading: false,
            }
        case GET_LOYALTY_CONFIG_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case UPDATE_LOYALTY_CONFIG_STARTED:
            return {
                ...state,
                loading: true,
            }
        case UPDATE_LOYALTY_CONFIG_SUCCESS:
            return {
                ...state,
                loyaltyConfig: action.payload.data,
                message: "Loyalty configuration has been updated!",
                loading: false,
            }
        case UPDATE_LOYALTY_CONFIG_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
                message: null
            };

        default:
            return {
                ...state,
            };
    }
}