import {
    GET_HISTORY_APPOINTMENTS_STARTED,
    GET_HISTORY_APPOINTMENTS_SUCCESS,
    GET_HISTORY_APPOINTMENTS_FAILURE,
    GET_HISTORY_REVENUES_STARTED,
    GET_HISTORY_REVENUES_SUCCESS,
    GET_HISTORY_REVENUES_FAILURE,
    GET_HISTORY_SALES_STARTED,
    GET_HISTORY_SALES_SUCCESS,
    GET_HISTORY_SALES_FAILURE,
    GET_HISTORY_INVOICES_STARTED,
    GET_HISTORY_INVOICES_SUCCESS,
    GET_HISTORY_INVOICES_FAILURE,
    GET_HISTORY_PAYMENTS_STARTED,
    GET_HISTORY_PAYMENTS_SUCCESS,
    GET_HISTORY_PAYMENTS_FAILURE,
    GET_HISTORY_REVIEWS_STARTED,
    GET_HISTORY_REVIEWS_SUCCESS,
    GET_HISTORY_REVIEWS_FAILURE,

    REPLY_REVIEW_HISTORY_STARTED,
    REPLY_REVIEW_HISTORY_SUCCESS,
    REPLY_REVIEW_HISTORY_FAILURE,

    GET_HISTORY_GIFTCARDS_STARTED,
    GET_HISTORY_GIFTCARDS_SUCCESS,
    GET_HISTORY_GIFTCARDS_FAILURE,

    VOID_INVOICE_STARTED,
    VOID_INVOICE_SUCCESS,
    VOID_INVOICE_FAILURE,

    GET_HISTORY_SALES_BY_STAFF_STARTED,
    GET_HISTORY_SALES_BY_STAFF_SUCCESS,
    GET_HISTORY_SALES_BY_STAFF_FAILURE,
    GET_HISTORY_SALES_BY_MENU_STARTED,
    GET_HISTORY_SALES_BY_MENU_SUCCESS,
    GET_HISTORY_SALES_BY_MENU_FAILURE,
    GET_HISTORY_SALES_BY_DATE_STARTED,
    GET_HISTORY_SALES_BY_DATE_SUCCESS,
    GET_HISTORY_SALES_BY_DATE_FAILURE,
    GET_HISTORY_SALES_BY_PAYMENT_STARTED,
    GET_HISTORY_SALES_BY_PAYMENT_SUCCESS,
    GET_HISTORY_SALES_BY_PAYMENT_FAILURE,
    GET_HISTORY_DISCOUNTS_STARTED,
    GET_HISTORY_DISCOUNTS_SUCCESS,
    GET_HISTORY_DISCOUNTS_FAILURE,
    GET_HISTORY_TIPS_STARTED,
    GET_HISTORY_TIPS_SUCCESS,
    GET_HISTORY_TIPS_FAILURE,
    GET_HISTORY_TAXES_STARTED,
    GET_HISTORY_TAXES_SUCCESS,
    GET_HISTORY_TAXES_FAILURE,

    GET_HISTORY_APPOINTMENTS_BY_TYPE_STARTED,
    GET_HISTORY_APPOINTMENTS_BY_TYPE_SUCCESS,
    GET_HISTORY_APPOINTMENTS_BY_TYPE_FAILURE,
    GET_HISTORY_APPOINTMENTS_BY_STATUS_STARTED,
    GET_HISTORY_APPOINTMENTS_BY_STATUS_SUCCESS,
    GET_HISTORY_APPOINTMENTS_BY_STATUS_FAILURE,
    GET_HISTORY_APPOINTMENTS_BY_DATE_STARTED,
    GET_HISTORY_APPOINTMENTS_BY_DATE_SUCCESS,
    GET_HISTORY_APPOINTMENTS_BY_DATE_FAILURE,
    GET_HISTORY_APPOINTMENTS_LIST_STARTED,
    GET_HISTORY_APPOINTMENTS_LIST_SUCCESS,
    GET_HISTORY_APPOINTMENTS_LIST_FAILURE,

    DISCARD_INVOICE_STARTED,
    DISCARD_INVOICE_SUCCESS,
    DISCARD_INVOICE_FAILURE,

    REMOVE_ERROR_MESSAGE,

} from "../../constants/history/history";

const initialState = {

    appointmentCounts: null,
    revenues: [],
    sales: [],
    reviews: [],
    business: null,
    payments: [],
    loading: false,
    error: null,
    message: null,
    component: "",

    salesByStaff: [],
    salesByDate: null,
    salesByMenu: null,
    salesByPayment: [],
    discounts: null,
    tips: [],
    taxes: [],
    invoices: null,

    appointmentsByType: [],
    appointmentsByStatus: [],
    appointmentsByDate: null,
    appointments: null,

    giftcards: [],
    redeemGiftCards: []
};

export default function history(state = initialState, action) {
    switch (action.type) {

        case REPLY_REVIEW_HISTORY_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            }
        case REPLY_REVIEW_HISTORY_SUCCESS:
            return {
                ...state,
                reviews: state.reviews.map(review => {
                    if (review._id === action.payload.data?._id) {
                        return action.payload.data
                    }
                    return review
                }),
                loading: false,
                message: "The reply has been sent!"
            }
        case REPLY_REVIEW_HISTORY_FAILURE:
            return {
                ...state,
                loading: false,
                error: "Cannot send the reply"
            }

        case VOID_INVOICE_STARTED:
            return {
                ...state,
                loading: true,
            }
        case VOID_INVOICE_SUCCESS:
            return {
                ...state,
                revenues: state.revenues.filter(rev => rev._id !== action.payload.data._id),
                loading: false,
                message: "The invoice has been archived!"
            }
        case VOID_INVOICE_FAILURE:
            return {
                ...state,
                loading: false,
                error: "Cannot archive the invoice"
            }
        case GET_HISTORY_APPOINTMENTS_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "HistoryContainer",
            };
        case GET_HISTORY_APPOINTMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                appointmentCounts: action.payload.data.appointmentCounts,
                appointments: action.payload.data.appointments,
            };
        case GET_HISTORY_APPOINTMENTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case GET_HISTORY_REVENUES_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "HistoryContainer",
            };
        case GET_HISTORY_REVENUES_SUCCESS:
            return {
                ...state,
                loading: false,
                business: action.payload.data.business,
                revenues: action.payload.data.revenues,
            };
        case GET_HISTORY_REVENUES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case GET_HISTORY_GIFTCARDS_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case GET_HISTORY_GIFTCARDS_SUCCESS:
            return {
                ...state,
                loading: false,
                giftcards: action.payload.data.giftcards,
                redeemGiftCards: action.payload.data.redeemGiftCards
            };
        case GET_HISTORY_GIFTCARDS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case GET_HISTORY_SALES_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case GET_HISTORY_SALES_SUCCESS:
            return {
                ...state,
                loading: false,
                sales: action.payload.data.sales,
            };
        case GET_HISTORY_SALES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case GET_HISTORY_INVOICES_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case GET_HISTORY_INVOICES_SUCCESS:
            return {
                ...state,
                loading: false,
                invoices: action.payload.data,
            };
        case GET_HISTORY_INVOICES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case GET_HISTORY_PAYMENTS_STARTED:
            return {
                ...state,
                loading: true,
            };
        case GET_HISTORY_PAYMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                payments: action.payload.data.payments,
            };
        case GET_HISTORY_PAYMENTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };

        case GET_HISTORY_REVIEWS_STARTED:
            return {
                ...state,
                loading: true,
            };
        case GET_HISTORY_REVIEWS_SUCCESS:
            return {
                ...state,
                loading: false,
                reviews: action.payload.data.reviews,
            };
        case GET_HISTORY_REVIEWS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };

        case GET_HISTORY_SALES_BY_STAFF_STARTED:
            return {
                ...state,
                loading: true,
            };
        case GET_HISTORY_SALES_BY_STAFF_SUCCESS:
            return {
                ...state,
                loading: false,
                salesByStaff: action.payload.data,
            };
        case GET_HISTORY_SALES_BY_STAFF_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };

        case GET_HISTORY_SALES_BY_DATE_STARTED:
            return {
                ...state,
                loading: true,
            };
        case GET_HISTORY_SALES_BY_DATE_SUCCESS:
            return {
                ...state,
                loading: false,
                salesByDate: action.payload.data,
            };
        case GET_HISTORY_SALES_BY_DATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };

        case GET_HISTORY_SALES_BY_MENU_STARTED:
            return {
                ...state,
                loading: true,
            };
        case GET_HISTORY_SALES_BY_MENU_SUCCESS:
            return {
                ...state,
                loading: false,
                salesByMenu: action.payload.data,
            };
        case GET_HISTORY_SALES_BY_MENU_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };

        case GET_HISTORY_SALES_BY_PAYMENT_STARTED:
            return {
                ...state,
                loading: true,
            };
        case GET_HISTORY_SALES_BY_PAYMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                salesByPayment: action.payload.data,
            };
        case GET_HISTORY_SALES_BY_PAYMENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };

        case GET_HISTORY_DISCOUNTS_STARTED:
            return {
                ...state,
                loading: true,
            };
        case GET_HISTORY_DISCOUNTS_SUCCESS:
            return {
                ...state,
                loading: false,
                discounts: action.payload.data,
            };
        case GET_HISTORY_DISCOUNTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };

        case GET_HISTORY_TIPS_STARTED:
            return {
                ...state,
                loading: true,
            };
        case GET_HISTORY_TIPS_SUCCESS:
            return {
                ...state,
                loading: false,
                tips: action.payload.data,
            };
        case GET_HISTORY_TIPS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };

        case GET_HISTORY_TAXES_STARTED:
            return {
                ...state,
                loading: true,
            };
        case GET_HISTORY_TAXES_SUCCESS:
            return {
                ...state,
                loading: false,
                taxes: action.payload.data,
            };
        case GET_HISTORY_TAXES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };

        case GET_HISTORY_APPOINTMENTS_BY_TYPE_STARTED:
            return {
                ...state,
                loading: true,
            };
        case GET_HISTORY_APPOINTMENTS_BY_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                appointmentsByType: action.payload.data,
            };
        case GET_HISTORY_APPOINTMENTS_BY_TYPE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };

        case GET_HISTORY_APPOINTMENTS_BY_STATUS_STARTED:
            return {
                ...state,
                loading: true,
            };
        case GET_HISTORY_APPOINTMENTS_BY_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                appointmentsByStatus: action.payload.data,
            };

        case GET_HISTORY_APPOINTMENTS_BY_STATUS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };

        case GET_HISTORY_APPOINTMENTS_BY_DATE_STARTED:
            return {
                ...state,
                loading: true,
            };
        case GET_HISTORY_APPOINTMENTS_BY_DATE_SUCCESS:
            return {
                ...state,
                loading: false,
                appointmentsByDate: action.payload.data,
            };
        case GET_HISTORY_APPOINTMENTS_BY_DATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };

        case GET_HISTORY_APPOINTMENTS_LIST_STARTED:
            return {
                ...state,
                loading: true,
            }
        case GET_HISTORY_APPOINTMENTS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                appointments: action.payload.data,
            }
        case GET_HISTORY_APPOINTMENTS_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case DISCARD_INVOICE_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };

        case DISCARD_INVOICE_SUCCESS:
            return {
                ...state,
                loading: false,
                invoices: action.payload.data,
                message: "The invoice has been removed!"
            };
        case DISCARD_INVOICE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };

        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
            };

        default:
            return {
                ...state,
            };
    }
}
