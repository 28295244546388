import {
  GET_BUSINESS_BILLING_HISTORY_STARTED,
  GET_BUSINESS_BILLING_HISTORY_SUCCESS,
  GET_BUSINESS_BILLING_HISTORY_FAILURE,
  GET_BUSINESS_BILLING_DETAILS_STARTED,
  GET_BUSINESS_BILLING_DETAILS_SUCCESS,
  GET_BUSINESS_BILLING_DETAILS_FAILURE,
  UPDATE_PAYMENT_METHOD_STARTED,
  UPDATE_PAYMENT_METHOD_SUCCESS,
  UPDATE_PAYMENT_METHOD_FAILURE,
  CREATE_PAYMENT_METHOD_STARTED,
  CREATE_PAYMENT_METHOD_SUCCESS,
  CREATE_PAYMENT_METHOD_FAILURE,
  UPDATE_VOID_CHEQUE_STARTED,
  UPDATE_VOID_CHEQUE_SUCCESS,
  UPDATE_VOID_CHEQUE_FAILURE,
  REMOVE_ERROR_MESSAGE,
} from "../../constants/billing/billing";

import { LOGOUT } from "../../constants/salon-account/authenticate";
import API from "../../../utils/API/API";


export const createPaymentMethod = (card) => {
  return (dispatch) => {
    dispatch(createPaymentMethodStarted());
    API.post(`/api/business-payment-profile/create`, card)
      .then((res) => {
        dispatch(createPaymentMethodSuccess(res.data));
      })
      .catch((err) => {
        catchError(err, dispatch, createPaymentMethodFailure);
      }).finally(() => {
        setTimeout(function () {
          dispatch(removeErrorMessage());
        }, 1000)
      });
  };
}

const createPaymentMethodStarted = () => ({
  type: CREATE_PAYMENT_METHOD_STARTED,
});


const createPaymentMethodSuccess = () => ({
  type: CREATE_PAYMENT_METHOD_SUCCESS,
});

const createPaymentMethodFailure = (error) => ({
  type: CREATE_PAYMENT_METHOD_FAILURE,
  payload: { error },
});


export const updateVoidCheque = (voidCheque) => {
  return (dispatch) => {
    dispatch(updateVoidChequeStarted());
    API.post(`/api/business-void-cheque/update`, voidCheque)
      .then(() => {
        dispatch(updateVoidChequeSuccess());
      })
      .catch((err) => {
        catchError(err, dispatch, updateVoidChequeFailure);
      }).finally(() => {
        setTimeout(function () {
          dispatch(removeErrorMessage());
        }, 1000)
      });
  }
}

const updateVoidChequeStarted = () => ({
  type: UPDATE_VOID_CHEQUE_STARTED,
});


const updateVoidChequeSuccess = () => ({
  type: UPDATE_VOID_CHEQUE_SUCCESS,
});

const updateVoidChequeFailure = (error) => ({
  type: UPDATE_VOID_CHEQUE_FAILURE,
  payload: { error },
});


export const updatePaymentMethod = (card) => {
  return (dispatch) => {
    dispatch(updatePaymentMethodStarted());
    API.post(`/api/business-payment-profile/update`, card)
      .then((res) => {
        dispatch(updatePaymentMethodSuccess(res.data));
      })
      .catch((err) => {
        catchError(err, dispatch, updatePaymentMethodFailure);
      }).finally(() => {
        setTimeout(function () {
          dispatch(removeErrorMessage());
        }, 1000)
      });
  };
}

const updatePaymentMethodStarted = () => ({
  type: UPDATE_PAYMENT_METHOD_STARTED,
});


const updatePaymentMethodSuccess = () => ({
  type: UPDATE_PAYMENT_METHOD_SUCCESS,
});

const updatePaymentMethodFailure = (error) => ({
  type: UPDATE_PAYMENT_METHOD_FAILURE,
  payload: { error },
});


export const getBusinessBillingHistory = () => {
  return (dispatch) => {
    dispatch(getBusinessBillingHistoryStarted());
    API.get(`/api/billing/history`)
      .then((res) => {
        dispatch(getBusinessBillingHistorySuccess(res.data));
      })
      .catch((err) => {
        catchError(err, dispatch, getBusinessBillingHistoryFailure);
      });
  };
};

const getBusinessBillingHistoryStarted = () => ({
  type: GET_BUSINESS_BILLING_HISTORY_STARTED,
});

const getBusinessBillingHistorySuccess = (data) => ({
  type: GET_BUSINESS_BILLING_HISTORY_SUCCESS,
  payload: { data },
});

const getBusinessBillingHistoryFailure = (error) => ({
  type: GET_BUSINESS_BILLING_HISTORY_FAILURE,
  payload: { error },
});


export const getBusinessBillingDetails = (id) => {
  return (dispatch) => {
    dispatch(getBusinessBillingDetailsStarted());
    API.get(`/api/billing/details?id=${id}`)
      .then((res) => {

        dispatch(getBusinessBillingDetailsSuccess(res.data));

      })
      .catch((err) => {
        catchError(err, dispatch, getBusinessBillingDetailsFailure);
      });
  };
};

const getBusinessBillingDetailsStarted = () => ({
  type: GET_BUSINESS_BILLING_DETAILS_STARTED,
});

const getBusinessBillingDetailsSuccess = (data) => ({
  type: GET_BUSINESS_BILLING_DETAILS_SUCCESS,
  payload: { data },
});

const getBusinessBillingDetailsFailure = (error) => ({
  type: GET_BUSINESS_BILLING_DETAILS_FAILURE,
  payload: { error },
});


const removeErrorMessage = () => ({
  type: REMOVE_ERROR_MESSAGE,
});

const logoutUser = () => ({
  type: LOGOUT,
});

//Handle catch error
//logout use when unauthorized
//display error message and remove message after 3 seconds
const catchError = (error, dispatch, nextFunction) => {
  var message = error.message;
  if (error.response) {
    message = error.response.data.message;
    if (error.response.status === 401) {
      dispatch(logoutUser());
    }
  }

  dispatch(nextFunction(message));
};