import {
    GET_BUSINESS_BILLING_HISTORY_STARTED,
    GET_BUSINESS_BILLING_HISTORY_SUCCESS,
    GET_BUSINESS_BILLING_HISTORY_FAILURE,
    GET_BUSINESS_BILLING_DETAILS_STARTED,
    GET_BUSINESS_BILLING_DETAILS_SUCCESS,
    GET_BUSINESS_BILLING_DETAILS_FAILURE,
    UPDATE_PAYMENT_METHOD_STARTED,
    UPDATE_PAYMENT_METHOD_SUCCESS,
    UPDATE_PAYMENT_METHOD_FAILURE,
    CREATE_PAYMENT_METHOD_STARTED,
    CREATE_PAYMENT_METHOD_SUCCESS,
    CREATE_PAYMENT_METHOD_FAILURE,
    UPDATE_VOID_CHEQUE_STARTED,
    UPDATE_VOID_CHEQUE_SUCCESS,
    UPDATE_VOID_CHEQUE_FAILURE,
    REMOVE_ERROR_MESSAGE,
} from "../../constants/billing/billing";

const initialState = {
    history: [],
    details: {},
    billingInfo: null,
    loading: false,
    loadingPaymentMethod: false,
    error: null,
    message: null,
    component: ""
};

export default function billing(state = initialState, action) {
    switch (action.type) {
        case CREATE_PAYMENT_METHOD_STARTED:
            return {
                ...state,
                loading: true,

            }
        case CREATE_PAYMENT_METHOD_SUCCESS:
            return {
                ...state,
                message: "Thank you for your payment. Your account has been activated successfully!",
                loading: false,
            }
        case CREATE_PAYMENT_METHOD_FAILURE:
            return {
                ...state,
                error: action.payload.error,
                loading: false,
            }
        case UPDATE_VOID_CHEQUE_STARTED:
            return {
                ...state,
                loadingPaymentMethod: true
            }
        case UPDATE_VOID_CHEQUE_SUCCESS:
            return {
                ...state,
                loadingPaymentMethod: false,
                message: "Your payment method has been updated successfully!"
            }
        case UPDATE_VOID_CHEQUE_FAILURE:
            return {
                ...state,
                loadingPaymentMethod: false,
                error: action.payload.error
            }
        case UPDATE_PAYMENT_METHOD_STARTED:
            return {
                ...state,
                loadingPaymentMethod: true
            }
        case UPDATE_PAYMENT_METHOD_SUCCESS:
            return {
                ...state,
                loadingPaymentMethod: false,
                message: "Your payment method has been updated successfully!"
            }
        case UPDATE_PAYMENT_METHOD_FAILURE:
            return {
                ...state,
                loadingPaymentMethod: false,
                error: action.payload.error
            }
        case GET_BUSINESS_BILLING_HISTORY_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "BillingHistory"
            };
        case GET_BUSINESS_BILLING_HISTORY_SUCCESS:
            return {
                ...state,
                loading: false,
                history: action.payload.data,
            };
        case GET_BUSINESS_BILLING_HISTORY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
            };
        case GET_BUSINESS_BILLING_DETAILS_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "BillingDetails"
            };
        case GET_BUSINESS_BILLING_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                details: action.payload.data.billingDetails,
                billingInfo: action.payload.data.billingInfo,
            };
        case GET_BUSINESS_BILLING_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
            };
        case REMOVE_ERROR_MESSAGE: {
            return {
                ...state,
                loading: false,
                error: null,
                message: null
            };
        }

        default:
            return {
                ...state
            };
    }
}