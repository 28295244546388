export const CREATE_STAFF_ACCOUNT_STARTED = "CREATE_STAFF_ACCOUNT_STARTED"
export const CREATE_STAFF_ACCOUNT_SUCCESS = "CREATE_STAFF_ACCOUNT_SUCCESS"
export const CREATE_STAFF_ACCOUNT_FAILURE = "CREATE_STAFF_ACCOUNT_FAILURE"


export const GET_STAFF_ACCOUNT_LIST_STARTED = "GET_STAFF_ACCOUNT_LIST_STARTED"
export const GET_STAFF_ACCOUNT_LIST_SUCCESS = "GET_STAFF_ACCOUNT_LIST_SUCCESS"
export const GET_STAFF_ACCOUNT_LIST_FAILURE = "GET_STAFF_ACCOUNT_LIST_FAILURE"

export const UPDATE_STAFF_ACCOUNT_STARTED = "UPDATE_STAFF_ACCOUNT_STARTED"
export const UPDATE_STAFF_ACCOUNT_SUCCESS = "UPDATE_STAFF_ACCOUNT_SUCCESS"
export const UPDATE_STAFF_ACCOUNT_FAILURE = "UPDATE_STAFF_ACCOUNT_FAILURE"


export const DELETE_STAFF_ACCOUNT_STARTED = "DELETE_STAFF_ACCOUNT_STARTED"
export const DELETE_STAFF_ACCOUNT_SUCCESS = "DELETE_STAFF_ACCOUNT_SUCCESS"
export const DELETE_STAFF_ACCOUNT_FAILURE = "DELETE_STAFF_ACCOUNT_FAILURE"


export const LOGOUT_STAFF_ACCOUNT_STARTED = "LOGOUT_STAFF_ACCOUNT_STARTED"
export const LOGOUT_STAFF_ACCOUNT_SUCCESS = "LOGOUT_STAFF_ACCOUNT_SUCCESS"
export const LOGOUT_STAFF_ACCOUNT_FAILURE = "LOGOUT_STAFF_ACCOUNT_FAILURE"



export const RESET_PASSWORD_STAFF_ACCOUNT_STARTED = "RESET_PASSWORD_STAFF_ACCOUNT_STARTED"
export const RESET_PASSWORD_STAFF_ACCOUNT_SUCCESS = "RESET_PASSWORD_STAFF_ACCOUNT_SUCCESS"
export const RESET_PASSWORD_STAFF_ACCOUNT_FAILURE = "RESET_PASSWORD_STAFF_ACCOUNT_FAILURE"

export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE"