import {
    GET_TIME_CLOCK_STARTED,
    GET_TIME_CLOCK_SUCCESS,
    GET_TIME_CLOCK_FAILURE,
    STAFF_CLOCK_IN_AND_OUT_STARTED,
    STAFF_CLOCK_IN_AND_OUT_SUCCESS,
    STAFF_CLOCK_IN_AND_OUT_FAILURE,
    GET_TIME_CLOCK_HISTORY_STARTED,
    GET_TIME_CLOCK_HISTORY_SUCCESS,
    GET_TIME_CLOCK_HISTORY_FAILURE,
    UPDATE_STAFF_SHIFT_STARTED,
    UPDATE_STAFF_SHIFT_SUCCESS,
    UPDATE_STAFF_SHIFT_FAILURE,
    DELETE_STAFF_SHIFT_STARTED,
    DELETE_STAFF_SHIFT_SUCCESS,
    DELETE_STAFF_SHIFT_FAILURE,
    REMOVE_ERROR_MESSAGE,
} from "../../constants/time-clock/time-clock";

const initialState = {
    _id: null,
    shifts: [],
    historyTimeClocks: [],
    loading: false,
    error: null,
    message: null,
    component: ""
};

export default function timeClock(state = initialState, action) {
    var shiftsToReturn = [];
    var idToReturn = null;
    var timeClocksToReturn = [];

    switch (action.type) {
        case GET_TIME_CLOCK_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "TimeClockStaff"
            };
        case GET_TIME_CLOCK_SUCCESS:
            let timeClockToday = action.payload.data[0]
           
            if (timeClockToday && timeClockToday.shifts.length > 0) {
                shiftsToReturn = timeClockToday.shifts
                idToReturn = timeClockToday._id
            } else {
                shiftsToReturn = []
                idToReturn = null
            }

            return {
                ...state,
                loading: false,
                shifts: shiftsToReturn,
                _id: idToReturn,
                // message: "Time clock retrieved successfully."
            };
        case GET_TIME_CLOCK_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            }
        case STAFF_CLOCK_IN_AND_OUT_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "TimeClockStaff"
            };
        case STAFF_CLOCK_IN_AND_OUT_SUCCESS:
            if (action.payload.data && action.payload.data.shifts.length > 0) {
                shiftsToReturn = action.payload.data.shifts
            } else {
                shiftsToReturn = []
            }
            return {
                ...state,
                loading: false,
                shifts: shiftsToReturn,
                message: "Staff clocked successfully!"
            };
        case STAFF_CLOCK_IN_AND_OUT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            }
        case GET_TIME_CLOCK_HISTORY_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "TimeClockHistory"
            };
        case GET_TIME_CLOCK_HISTORY_SUCCESS:
            if (action.payload.data.length > 0) {
                timeClocksToReturn = action.payload.data
            } else {
                timeClocksToReturn = []
            }

            return {
                ...state,
                loading: false,
                historyTimeClocks: timeClocksToReturn,
            };
        case GET_TIME_CLOCK_HISTORY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            }
        case UPDATE_STAFF_SHIFT_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "TimeClockHistory"
            };
        case UPDATE_STAFF_SHIFT_SUCCESS:
            let newTimeClock = action.payload.data;
            if (newTimeClock._id === state._id) {
                shiftsToReturn = newTimeClock.shifts
            } else {
                shiftsToReturn = state.shifts
            }

            timeClocksToReturn = state.historyTimeClocks.map(timeClock => {
                if (timeClock._id === newTimeClock._id) {
                    return newTimeClock
                }
                return timeClock
            });

            return {
                ...state,
                loading: false,
                shifts: shiftsToReturn,
                historyTimeClocks: timeClocksToReturn,
                message: "Updated shift successfully."
            };
        case UPDATE_STAFF_SHIFT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            }
        case DELETE_STAFF_SHIFT_STARTED:
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
                component: "TimeClockHistory"
            };
        case DELETE_STAFF_SHIFT_SUCCESS:
            let updatedTimeClockId = action.payload.data.timeClockId;
            let deletedShiftId = action.payload.data.shiftId;

            if (updatedTimeClockId === state._id) {
                shiftsToReturn = state.shifts.filter(shift => shift._id !== deletedShiftId)
            } else {
                shiftsToReturn = state.shifts
            }

            timeClocksToReturn = state.historyTimeClocks.map(timeClock => {
                if (timeClock._id === updatedTimeClockId) {
                    timeClock.shifts = timeClock.shifts.filter(shift => shift._id !== deletedShiftId)
                    return timeClock
                }
                return timeClock
            });

            return {
                ...state,
                loading: false,
                shifts: shiftsToReturn,
                historyTimeClocks: timeClocksToReturn,
                message: "Deleted shift successfully."
            };
        case DELETE_STAFF_SHIFT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            }
        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
                message: null
            };
        default:
            return {
                ...state,
            };
    }
}