import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Dropdown, Menu } from "antd";
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";
import { logout, logoutAdmin } from "../../../redux/actions/salon-account/authenticate";
// import Cookies from "js-cookie";

class AccountSetting extends Component {

    state = {
        isAdmin: null
    }

    componentDidMount() {
        this.checkIsAdmin()
    }

    checkIsAdmin = () => {
        const adminData = localStorage.getItem("isAdmin");
        const isAdmin = adminData ? JSON.parse(adminData) : null;
        if (isAdmin && isAdmin.expireTime > new Date().getTime()) {
            this.setState({ isAdmin });
        } else {
            this.removeAdmin();
        }
        return isAdmin;
    }

    removeAdmin = () => {
        localStorage.removeItem("isAdmin");
        this.setState({ isAdmin: null });
    }


    checkAdmin = (visible) => {
        const isAdmin = this.checkIsAdmin();
        if (visible && isAdmin && isAdmin.expireTime < new Date().getTime()) {
            this.removeAdmin();
            this.logoutAdmin()
        }
    }


    items = () => {
        const { isAdmin } = this.state;
        return [
            {
                label: "Profile",
                key: "1",
                icon: <UserOutlined />,
                style: { lineHeight: "30px" },
                onClick: () => {
                    this.props.history.push("/account");
                }
            },
            {
                label: "Logout",
                key: "2",
                icon: <LogoutOutlined />,
                style: { lineHeight: "30px" },
                onClick: () => {
                    this.props.logout();
                    this.props.history.push("/");
                }
            },
            isAdmin && {
                label: "Logout as admin",
                key: "3",
                icon: <LogoutOutlined />,
                style: { lineHeight: "30px" },
                onClick: this.logoutAdmin
            }
        ].filter(Boolean); // This will remove any falsy values, such as the false from isAdmin && {}
    };

    navigateToAccount = () => {
        this.props.history.push("/account");
    }

    logout = () => {
        this.props.logout();
        this.props.history.push("/");
    }

    logoutAdmin = () => {
        this.removeAdmin();
        this.props.logoutAdmin();
        this.props.history.push("/");
    }

    userDropdown = () => (
        <Menu items={this.items} />
    );
    render() {
        const { isMobile } = this.props
        const { isAdmin } = this.state
        return (
            <Dropdown
                key="AccountSettings"
                menu={{ items: this.items(isAdmin) }}
                trigger={["click"]}
                onOpenChange={this.checkAdmin}
            >
                <Button
                    style={{
                        border: "none",
                        padding: 0,
                        marginRight: "10px",
                        marginLeft: "30px",
                    }}
                >
                    <UserOutlined
                        style={{
                            fontSize: 20,
                            verticalAlign: "top",
                        }}
                    />{" "}
                    {!isMobile && "Account"}
                </Button>
            </Dropdown>
        );
    }
}

const mapStateToProps = (state) => ({
    isMobile: state.responsive.isMobile
});

function mapDispatchToProps(dispatch) {
    return {
        logout: () => dispatch(logout()),
        logoutAdmin: () => dispatch(logoutAdmin()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountSetting);
