export const GET_HOLIDAYS_STARTED = "GET_HOLIDAYS_STARTED"
export const GET_HOLIDAYS_SUCCESS = "GET_HOLIDAYS_SUCCESS"
export const GET_HOLIDAYS_FAILURE = "GET_HOLIDAYS_FAILURE"

export const GET_HOLIDAY_DETAILS_STARTED = "GET_HOLIDAY_DETAILS_STARTED"
export const GET_HOLIDAY_DETAILS_SUCCESS = "GET_HOLIDAY_DETAILS_SUCCESS"
export const GET_HOLIDAY_DETAILS_FAILURE = "GET_HOLIDAY_DETAILS_FAILURE"

export const CREATE_HOLIDAY_STARTED = "CREATE_HOLIDAY_STARTED"
export const CREATE_HOLIDAY_SUCCESS = "CREATE_HOLIDAY_SUCCESS"
export const CREATE_HOLIDAY_FAILURE = "CREATE_HOLIDAY_FAILURE"

export const UPDATE_HOLIDAY_STARTED = "UPDATE_HOLIDAY_STARTED"
export const UPDATE_HOLIDAY_SUCCESS = "UPDATE_HOLIDAY_SUCCESS"
export const UPDATE_HOLIDAY_FAILURE = "UPDATE_HOLIDAY_FAILURE"

export const DELETE_HOLIDAY_STARTED = "DELETE_HOLIDAY_STARTED"
export const DELETE_HOLIDAY_SUCCESS = "DELETE_HOLIDAY_SUCCESS"
export const DELETE_HOLIDAY_FAILURE = "DELETE_HOLIDAY_FAILURE"

export const COUNT_APPOINTMENT_HOLIDAY_STARTED = "COUNT_APPOINTMENT_HOLIDAY_STARTED"
export const COUNT_APPOINTMENT_HOLIDAY_SUCCESS = "COUNT_APPOINTMENT_HOLIDAY_SUCCESS"
export const COUNT_APPOINTMENT_HOLIDAY_FAILURE = "COUNT_APPOINTMENT_HOLIDAY_FAILURE"

export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE"