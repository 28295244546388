import {
    GET_GIFT_CARD_SETTINGS_STARTED,
    GET_GIFT_CARD_SETTINGS_SUCCESS,
    GET_GIFT_CARD_SETTINGS_FAILURE,
    UPDATE_GIFT_CARD_SETTINGS_STARTED,
    UPDATE_GIFT_CARD_SETTINGS_SUCCESS,
    UPDATE_GIFT_CARD_SETTINGS_FAILURE,
    REMOVE_ERROR_MESSAGE

} from '../../constants/gift-card-settings/giftCardSettings'

const initialState = {
    settings: null,
    loading: false,
    error: null,
    message: null,
};

export default function giftCardSettings(state = initialState, action) {
    switch (action.type) {

        case GET_GIFT_CARD_SETTINGS_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case GET_GIFT_CARD_SETTINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                settings: action.payload.settings,
            };
        case GET_GIFT_CARD_SETTINGS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };

        case UPDATE_GIFT_CARD_SETTINGS_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case UPDATE_GIFT_CARD_SETTINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                settings: action.payload.settings,
                message: 'Gift card settings has been updated successfully!'
            };
        case UPDATE_GIFT_CARD_SETTINGS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };


        //remove error
        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
                message: null
            };
        default:
            return {
                ...state,
            };
    }
}

