import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "../reducers/index";
import thunk from "redux-thunk";
// import Cookies from "js-cookie"

//load the saved user data from localstorage for Redux state
// function loadFromLocalStorage() {
//     try {
//         // const serializedUser = localStorage.getItem("user") 
//         var serializedUser = Cookies.get("user") 
//         serializedUser.isRequiredActivation = !!Cookies.getJSON("_ar") || false;
      
//         // if (serializedUser === null) {
//         //     const authenticate = { user };
//         //     return Object.assign({}, { authenticate });
//         // }
//         console.log(serializedUser)
//         const user = JSON.parse(serializedUser);
     
//         const authenticate = { user };
//         return Object.assign({}, { authenticate });
//     } catch (e) {
//         const user = {
//             authenticated: false,
//             isRequiredActivation: false
//         };

//         const authenticate = { user };

//         return Object.assign({}, { authenticate });
//     }
// }
//User Data load from localStorage
// const persistedState = loadFromLocalStorage();

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    rootReducer,
    // persistedState,
    storeEnhancers(applyMiddleware(thunk))
);

export default store;
