import {
    GET_BUSINESS_STATISTIC_LAST_TWELVE_MONTHS_START,
    GET_BUSINESS_STATISTIC_LAST_TWELVE_MONTHS_SUCCESS,
    GET_BUSINESS_STATISTIC_LAST_TWELVE_MONTHS_FAILURE,

    GET_BUSINESS_STATISTIC_MONTHS_LIST_START,
    GET_BUSINESS_STATISTIC_MONTHS_LIST_SUCCESS,
    GET_BUSINESS_STATISTIC_MONTHS_LIST_FAILURE,

    GET_BUSINESS_STATISTIC_DETAILS_START,
    GET_BUSINESS_STATISTIC_DETAILS_SUCCESS,
    GET_BUSINESS_STATISTIC_DETAILS_FAILURE,

    REMOVE_ERROR_MESSAGE
} from "../../constants/report/saleReport";

const initialState = {
    businessStatisticTwelveMonths: null,
    businessStatisticMonthsList: null,
    businessStatisticSelectedMonth: null,
    businessStatisticPreviousMonth: null,
    loadingGraph: false,
    loadingStatistic: false,
    error: null,
    message: null,
}

export default function saleReport(state = initialState, action) {
    switch (action.type) {
        //get 12 months
        case GET_BUSINESS_STATISTIC_LAST_TWELVE_MONTHS_START:
            return {
                ...state,
                loadingGraph: true,
                error: null,
                message: null
            }
        case GET_BUSINESS_STATISTIC_LAST_TWELVE_MONTHS_SUCCESS:
            return {
                ...state,
                loadingGraph: false,
                businessStatisticTwelveMonths: action.payload.data,
            }
        case GET_BUSINESS_STATISTIC_LAST_TWELVE_MONTHS_FAILURE:
            return {
                ...state,
                loadingGraph: false,
                error: action.payload.error,
                message: null,
            }

        //get months list
        case GET_BUSINESS_STATISTIC_MONTHS_LIST_START:
            return {
                ...state,
                loadingStatistic: true,
                error: null,
                message: null
            }
        case GET_BUSINESS_STATISTIC_MONTHS_LIST_SUCCESS:
            return {
                ...state,
                loadingStatistic: false,
                businessStatisticMonthsList: action.payload.data,
            }
        case GET_BUSINESS_STATISTIC_MONTHS_LIST_FAILURE:
            return {
                ...state,
                loadingStatistic: false,
                error: action.payload.error,
                message: null,
            }

        //get details
        case GET_BUSINESS_STATISTIC_DETAILS_START:
            return {
                ...state,
                loadingStatistic: true,
                error: null,
                message: null
            }
        case GET_BUSINESS_STATISTIC_DETAILS_SUCCESS:
            return {
                ...state,
                loadingStatistic: false,
                businessStatisticSelectedMonth: action.payload.selectedMonth,
                businessStatisticPreviousMonth: action.payload.previousMonth,
            }
        case GET_BUSINESS_STATISTIC_DETAILS_FAILURE:
            return {
                ...state,
                loadingStatistic: false,
                error: action.payload.error,
                message: null,
            }

        //remove error message
        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
                message: null
            };

        default:
            return {
                ...state,
            };
    }
}