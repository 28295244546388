export const GET_ALL_PROMOTIONS_STARTED = "GET_ALL_PROMOTIONS_STARTED"
export const GET_ALL_PROMOTIONS_SUCCESS = "GET_ALL_PROMOTIONS_SUCCESS"
export const GET_ALL_PROMOTIONS_FAILURE = "GET_ALL_PROMOTIONS_FAILURE"

export const GET_ALL_ACTIVE_PROMOTIONS_STARTED = "GET_ALL_ACTIVE_PROMOTIONS_STARTED"
export const GET_ALL_ACTIVE_PROMOTIONS_SUCCESS = "GET_ALL_ACTIVE_PROMOTIONS_SUCCESS"
export const GET_ALL_ACTIVE_PROMOTIONS_FAILURE = "GET_ALL_ACTIVE_PROMOTIONS_FAILURE"

export const SEND_PROMOTION_STARTED = "SEND_PROMOTION_STARTED"
export const SEND_PROMOTION_SUCCESS = "SEND_PROMOTION_SUCCESS"
export const SEND_PROMOTION_FAILURE = "SEND_PROMOTION_FAILURE"


export const SEND_PROMOTION_EMAIL_STARTED = "SEND_PROMOTION_EMAIL_STARTED"
export const SEND_PROMOTION_EMAIL_SUCCESS = "SEND_PROMOTION_EMAIL_SUCCESS"
export const SEND_PROMOTION_EMAIL_FAILURE = "SEND_PROMOTION_EMAIL_FAILURE"

export const GET_ACTIVE_PROMOTIONS_STARTED = "GET_ACTIVE_PROMOTIONS_STARTED"
export const GET_ACTIVE_PROMOTIONS_SUCCESS = "GET_ACTIVE_PROMOTIONS_SUCCESS"
export const GET_ACTIVE_PROMOTIONS_FAILURE = "GET_ACTIVE_PROMOTIONS_FAILURE"

export const CREATE_PROMOTION_STARTED = "CREATE_PROMOTION_STARTED"
export const CREATE_PROMOTION_SUCCESS = "CREATE_PROMOTION_SUCCESS"
export const CREATE_PROMOTION_FAILURE = "CREATE_PROMOTION_FAILURE"

export const UPDATE_PROMOTION_STARTED = "UPDATE_PROMOTION_STARTED"
export const UPDATE_PROMOTION_SUCCESS = "UPDATE_PROMOTION_SUCCESS"
export const UPDATE_PROMOTION_FAILURE = "UPDATE_PROMOTION_FAILURE"

export const DELETE_PROMOTION_STARTED = "DELETE_PROMOTION_STARTED"
export const DELETE_PROMOTION_SUCCESS = "DELETE_PROMOTION_SUCCESS"
export const DELETE_PROMOTION_FAILURE = "DELETE_PROMOTION_FAILURE"

export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE"