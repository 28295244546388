import React, { Component } from "react";
import { connect } from "react-redux";
import "./Notification.css";
import { Drawer, List, Button, Space, Modal, Dropdown } from "antd";
import {
    // CalendarOutlined,
    PushpinTwoTone,
    BellTwoTone,
    CloseCircleTwoTone,
    InfoCircleTwoTone,
    MessageTwoTone,
    SettingTwoTone,
    // MoreOutlined,
    ExclamationCircleTwoTone,
    DeleteOutlined,
    EllipsisOutlined,
    CheckOutlined,
    GiftTwoTone,
} from "@ant-design/icons";
import { clearNotifications, markNotificationsAsRead, updateNotificationStatus } from "../../../redux/actions/notification/notification";
import moment from "moment";
import API from "../../../utils/API/API";
class NotificationDrawer extends Component {
    handleNotificationClick(url, notification) {
        this.props.handleDrawer(false);
        if (!notification.read) {
            API.patch(`/api/notifications/mark-as-read`, {
                _id: notification._id,
            })
                .then(() => {
                    this.props.updateNotificationStatus(notification._id)
                    this.handleRedirect(url, notification.type === "System" ? true : false, notification.type === "Gift Card" ? true : false);
                })
                .catch((e) => {
                    this.handleRedirect(url, notification.type === "System" ? true : false, notification.type === "Gift Card" ? true : false);
                });
        } else {
            this.handleRedirect(url, notification.type === "System" ? true : false, notification.type === "Gift Card" ? true : false);
        }
    }

    handleNotificationAsRead(notification) {
        if (!notification.read) {
            API.patch(`/api/notifications/mark-as-read`, {
                _id: notification._id,
            }).then(() => {
                this.props.updateNotificationStatus(notification._id)
            }).catch((e) => {

            });
        }
    }

    handleRedirect = (url, newTab, forceRefresh) => {
        const pathname = url.substring(0, url.indexOf("?"));
        const search = url.substring(url.indexOf("?"));
        if (newTab) {
            const win = window.open(search, "_blank");
            win.focus();
        } else {
            this.props.history.push({
                pathname,
                search,
                state: { isFromApplication: true },
            });
            if (forceRefresh) {
                this.props.history.go(0)
            }
        }

        // if (url.indexOf("?") > 0) {
        //     this.props.history.push({
        //         pathname,
        //         search,
        //         state: { isFromApplication: true },
        //     });
        //     // this.props.history.go(0);
        // } else {
        //     if (url === this.props.history.location.pathname) {
        //         // this.props.history.go(0);
        //     } else {
        //         this.props.history.push({
        //             pathname: url,
        //             state: { isFromApplication: true },
        //         });
        //     }
        // }
    };
    filterNotifications = (notifications) => {
        let filterNotifications = notifications;

        if (filterNotifications) {
            filterNotifications = filterNotifications.map((notification) => {
                // notification.linkTags = notification.links.map((link, index) => {
                //     return <Button type="link" className="notification-link"
                //         // to={link.url}
                //         key={notification._id + link.name}
                //         onClick={() => this.handleRedirect(link.url, notification)}
                //     >
                //         {index === 0 ? <MoreOutlined /> : <CalendarOutlined />} {link.name}
                //     </Button>
                // });
                if (notification.type === "New Appointment") {
                    notification.avatar = (
                        <PushpinTwoTone
                            twoToneColor="#87d068"
                            style={{ fontSize: "25px" }}
                        />
                    );
                } else if (notification.type === "Reschedule") {
                    notification.avatar = (
                        <BellTwoTone
                            twoToneColor="#faad14"
                            style={{ fontSize: "25px" }}
                        />
                    );
                } else if (notification.type === "Cancel") {
                    notification.avatar = (
                        <CloseCircleTwoTone
                            twoToneColor="#ff4d4f"
                            style={{ fontSize: "25px" }}
                        />
                    );
                } else if (notification.type === "New Walked-in") {
                    notification.avatar = (
                        <InfoCircleTwoTone
                            twoToneColor="#b37feb"
                            style={{ fontSize: "25px" }}
                        />
                    );
                } else if (notification.type === "System") {
                    notification.avatar = (
                        <SettingTwoTone
                            twoToneColor="#fa541c"
                            style={{ fontSize: "25px" }}
                        />
                    );
                } else if (notification.type === "Review") {
                    notification.avatar = (
                        <MessageTwoTone style={{ fontSize: "25px" }} />
                    );
                } else if (notification.type === "Gift Card") {
                    notification.avatar = (
                        <GiftTwoTone
                            twoToneColor="#fcd461"
                            style={{ fontSize: "25px" }}
                        />
                    );
                }
                return notification;
            });

            return filterNotifications;
        }

        return notifications;
    };

    // getDateDifference = (date) => {
    //     let returnText = "";
    //     const today = moment();
    //     const theDate = moment(date);
    //     let differenceDate = today.diff(theDate, "days");
    //     returnText = differenceDate + " days ago";
    //     if (differenceDate === 0) {
    //         differenceDate = today.diff(theDate, "hours");
    //         returnText = differenceDate + " hours ago";
    //     }
    //     return returnText;
    // };

    warningClearNotificationModal = () => {
        Modal.confirm({
            title: 'Clear Notifications',
            content: 'Are you sure you want to clear all notifications? This action cannot be undone!',
            closable: true,
            maskClosable: true,
            onOk: () => this.props.clearNotifications(),
            okText: "Yes",
            cancelText: "Cancel",
        });
    };

    render() {
        const {
            showDrawer,
            notifications,
            countNotifications,
            loadingNotifications,
            isMobile
        } = this.props;

        const filterNotifications = this.filterNotifications(notifications);

        const loadMore = !loadingNotifications ? (
            <div
                style={{
                    textAlign: "center",
                    margin: 12,
                    height: 32,
                    lineHeight: "32px",
                }}
            >
                <Button onClick={this.props.onLoadMore}>Load more</Button>
            </div>
        ) : null;

        return (
            (<Drawer
                title="Notifications"
                extra={
                    (countNotifications.unreadNotifications > 0 || countNotifications.totalNotifications > 0) && <Dropdown
                        trigger={['click']}
                        menu={{
                            items: [
                                countNotifications.unreadNotifications > 0 && {
                                    key: "mark all as read",
                                    label: <Button type="link" icon={<CheckOutlined />} onClick={() => this.props.markNotificationsAsRead()}>Mark all as read</Button>
                                },
                                countNotifications.totalNotifications > 0 && {
                                    key: "clear notifications",
                                    label: <Button type="link" icon={<DeleteOutlined />} danger onClick={this.warningClearNotificationModal}>Clear notifications</Button>
                                },
                            ],
                        }}
                    >
                        <Button shape="circle" icon={<EllipsisOutlined />} />
                    </Dropdown>
                }
                // headerStyle={{ textAlign: "center" }}
                placement="right"
                closable={false}
                onClose={(_) => this.props.handleDrawer(false)}
                open={showDrawer}
                width={!isMobile ? "25%" : 300}
            >
                <List
                    loading={loadingNotifications}
                    loadMore={
                        notifications.length <
                        countNotifications.totalNotifications && loadMore
                    }
                    dataSource={filterNotifications}
                    renderItem={(item) => (
                        <List.Item
                            className="notification-item-container"
                            key={item._id}
                            style={
                                item.read
                                    ? {
                                        padding: "10px",
                                        backgroundColor: "#ededed",
                                    }
                                    : { padding: "10px" }
                            }
                            onClick={() =>
                                this.handleNotificationClick(item.links[0].url, item)
                            }
                        >
                            <List.Item.Meta
                                // avatar={item.avatar}
                                title={
                                    <Space
                                        style={
                                            item.read
                                                ? { fontWeight: "normal" }
                                                : { fontWeight: "bold" }
                                        }
                                    >
                                        {item.avatar} {item.title}

                                    </Space>
                                }
                                description={
                                    <div>
                                        <p style={{ margin: "0px" }}>
                                            {item.message} <br />{" "}
                                            <span
                                                style={
                                                    item.read
                                                        ? { color: "inherit" }
                                                        : { color: "#1890ff" }
                                                }
                                            >
                                                {moment(item.createdAt).fromNow()} {" "}
                                                at {moment(item.createdAt).format("hh:mm a")}

                                            </span>
                                            <span className="notification-item-read-button">  {!item.read &&
                                                <ExclamationCircleTwoTone
                                                    twoToneColor="#1890ff"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        this.handleNotificationAsRead(item);
                                                    }}
                                                    className="notification-item-read-button"
                                                />
                                            }</span>
                                        </p>

                                    </div>
                                }
                            />
                        </List.Item>
                    )}
                />
            </Drawer>)
        );
    }
}

const mapStateToProps = (state) => ({
    loadingNotifications: state.notification.loading,
    notifications: state.notification.notifications,
    countNotifications: state.notification.count,
    isMobile: state.responsive.isMobile
});

const mapDispatchToProps = (dispatch) => {
    return {
        updateNotificationStatus: (_id) => dispatch(updateNotificationStatus(_id)),
        clearNotifications: () => dispatch(clearNotifications()),
        markNotificationsAsRead: () => dispatch(markNotificationsAsRead())
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(NotificationDrawer);
