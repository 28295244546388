import {
    GET_BILLING_REPORT_STARTED,
    GET_BILLING_REPORT_SUCCESS,
    GET_BILLING_REPORT_FAILURE,

    GET_EXACT_REPORT_STARTED,
    GET_EXACT_REPORT_SUCCESS,
    GET_EXACT_REPORT_FAILURE,

    REMOVE_ERROR_MESSAGE
} from "../../constants/report/billingReport";

const initialState = {
    billingReports: [],
    selectedReport: null,
    loading: false,
    error: null,
    message: null,
    component: ""
}

export default function billingReport(state = initialState, action) {
    switch (action.type) {
        case GET_BILLING_REPORT_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null
            }
        case GET_BILLING_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                billingReports: action.payload.data,
                selectedReport: null
            }
        case GET_BILLING_REPORT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                selectedReport: null
            }
        case GET_EXACT_REPORT_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null
            }
        case GET_EXACT_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                selectedReport: action.payload.data
            }
        case GET_EXACT_REPORT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                selectedReport: null,
                message: null
            }
        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
                message: null
            };

        default:
            return {
                ...state,
            };
    }
}