import {
    GET_NOTIFICATIONS_COUNT_STARTED,
    GET_NOTIFICATIONS_COUNT_SUCCESS,
    GET_NOTIFICATIONS_COUNT_FAILURE,
    GET_NOTIFICATIONS_STARTED,
    GET_NOTIFICATIONS_SUCCESS,
    GET_NOTIFICATIONS_FAILURE,
    LOAD_MORE_NOTIFICATIONS_STARTED,
    LOAD_MORE_NOTIFICATIONS_SUCCESS,
    LOAD_MORE_NOTIFICATIONS_FAILURE,
    // UPDATE_NOTIFICATION_STATUS_STARTED,
    UPDATE_NOTIFICATION_STATUS_SUCCESS,
    // UPDATE_NOTIFICATION_STATUS_FAILURE,
    CLEAR_NOTIFICATIONS_STARTED,
    CLEAR_NOTIFICATIONS_SUCCESS,
    CLEAR_NOTIFICATIONS_FAILURE,
    MARK_NOTIFICATIONS_AS_READ_STARTED,
    MARK_NOTIFICATIONS_AS_READ_SUCCESS,
    MARK_NOTIFICATIONS_AS_READ_FAILURE,
    REMOVE_ERROR_MESSAGE,
} from "../../constants/notification/notification";

import { LOGOUT } from "../../constants/salon-account/authenticate";
import API from "../../../utils/API/API";

export const getNotificationsCount = () => {
    return (dispatch) => {
        dispatch(getNotificationsCountStarted())
        API.get(`/api/notifications/count`)
            .then((res) => {
                dispatch(getNotificationsCountSuccess(res.data));
            }).catch((err) => {
                catchError(err, dispatch, getNotificationsCountFailure)
            }).finally(() => {
                setTimeout(function () {
                    dispatch(removeErrorMessage());
                }, 1000);
            });;
    }
};

const getNotificationsCountStarted = () => ({
    type: GET_NOTIFICATIONS_COUNT_STARTED,
});

const getNotificationsCountSuccess = (data) => ({
    type: GET_NOTIFICATIONS_COUNT_SUCCESS,
    payload: { data },
});

const getNotificationsCountFailure = (error) => ({
    type: GET_NOTIFICATIONS_COUNT_FAILURE,
    payload: { error },
});

export const getNotifications = () => {
    return (dispatch) => {
        dispatch(getNotificationsStarted())
        API.get(`/api/notifications`)
            .then((res) => {
                dispatch(getNotificationsSuccess(res.data));
            }).catch((err) => {
                catchError(err, dispatch, getNotificationsFailure)
            }).finally(() => {
                setTimeout(function () {
                    dispatch(removeErrorMessage());
                }, 1000);
            });;
    }
};

const getNotificationsStarted = () => ({
    type: GET_NOTIFICATIONS_STARTED,
});

const getNotificationsSuccess = (data) => ({
    type: GET_NOTIFICATIONS_SUCCESS,
    payload: { notifications: data },
});

const getNotificationsFailure = (error) => ({
    type: GET_NOTIFICATIONS_FAILURE,
    payload: { error },
});

export const loadMoreNotifications = (page = 2) => {
    return (dispatch) => {
        dispatch(loadMoreNotificationsStarted())
        API.get(`/api/notifications?page=${page}`)
            .then((res) => {
                dispatch(loadMoreNotificationsSuccess(res.data, page));
            }).catch((err) => {
                catchError(err, dispatch, loadMoreNotificationsFailure)
            }).finally(() => {
                setTimeout(function () {
                    dispatch(removeErrorMessage());
                }, 1000);
            });;
    }
};

const loadMoreNotificationsStarted = () => ({
    type: LOAD_MORE_NOTIFICATIONS_STARTED,
});

const loadMoreNotificationsSuccess = (data, page) => ({
    type: LOAD_MORE_NOTIFICATIONS_SUCCESS,
    payload: { notifications: data, page },
});

const loadMoreNotificationsFailure = (error) => ({
    type: LOAD_MORE_NOTIFICATIONS_FAILURE,
    payload: { error },
});

export const updateNotificationStatus = (_id) => {
    return (dispatch) => {
        dispatch(updateNotificationStatusSuccess(_id))
    }
};

// const updateNotificationStatusStarted = () => ({
//     type: UPDATE_NOTIFICATION_STATUS_STARTED,
// });

const updateNotificationStatusSuccess = (_id) => ({
    type: UPDATE_NOTIFICATION_STATUS_SUCCESS,
    payload: { data: _id },
});

// const updateNotificationStatusFailure = (error) => ({
//     type: UPDATE_NOTIFICATION_STATUS_FAILURE,
//     payload: { error },
// });

export const clearNotifications = () => {
    return (dispatch) => {
        dispatch(clearNotificationsStarted())
        API.delete(`/api/notifications/clear-all`)
            .then((res) => {
                dispatch(clearNotificationsSuccess());
            }).catch((err) => {
                catchError(err, dispatch, clearNotificationsFailure)
            }).finally(() => {
                setTimeout(function () {
                    dispatch(removeErrorMessage());
                }, 1000);
            });;
    }
};

const clearNotificationsStarted = () => ({
    type: CLEAR_NOTIFICATIONS_STARTED,
});

const clearNotificationsSuccess = () => ({
    type: CLEAR_NOTIFICATIONS_SUCCESS,
});

const clearNotificationsFailure = (error) => ({
    type: CLEAR_NOTIFICATIONS_FAILURE,
    payload: { error },
});

export const markNotificationsAsRead = () => {
    return (dispatch) => {
        dispatch(markNotificationsAsReadStarted())
        API.patch(`/api/notifications/mark-all-as-read`)
            .then((res) => {
                dispatch(markNotificationsAsReadSuccess());
            }).catch((err) => {
                catchError(err, dispatch, markNotificationsAsReadFailure)
            }).finally(() => {
                setTimeout(function () {
                    dispatch(removeErrorMessage());
                }, 1000);
            });;
    }
};

const markNotificationsAsReadStarted = () => ({
    type: MARK_NOTIFICATIONS_AS_READ_STARTED,
});

const markNotificationsAsReadSuccess = () => ({
    type: MARK_NOTIFICATIONS_AS_READ_SUCCESS,
});

const markNotificationsAsReadFailure = (error) => ({
    type: MARK_NOTIFICATIONS_AS_READ_FAILURE,
    payload: { error },
});



const removeErrorMessage = () => ({
    type: REMOVE_ERROR_MESSAGE,
});

const logoutUser = () => ({
    type: LOGOUT
});

//Handle catch error
//logout use when unauthorized
//display error message and remove message after 3 seconds
const catchError = (error, dispatch, nextFunction) => {
    var message = error.message
    if (error.response) {
        message = error.response.data.message
        if (error.response.status === 401) {
            dispatch(logoutUser())
        }
    }

    dispatch(nextFunction(message));
};