import React from "react";
// import { Modal } from "antd";
import AccountActivation from "./AccountActivation";
import { PageHeader } from '@ant-design/pro-layout';
// import { DashBookingLogo, } from "../../layouts/Navigation/DashBookingLogo";
import { Layout, Row, Col } from "antd";
// import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
// import "../../layouts/Navigation/MenuContainer.css";

const { Header, Content } = Layout;
export const MobileActivationModal = (props) => {
    return (
        <Layout className="site-layout" style={{ height: "100%" }}>
            <Header
                className="site-layout-background"
                style={{ padding: 0 }}
            >
                <Row justify="center">
                    <Col span={23}>
                        <PageHeader
                            title={"Account Activation"}
                            className="site-page-header-container"
                            style={{ padding: "10px" }}
                        />
                    </Col>
                </Row>
            </Header>
            <Content
                className="site-layout-background"
                style={{
                    margin: "24px 0px 5px 5px",
                    padding: "5px 5px 5px 10px",
                    overflow: "scroll",
                }}
            >

                <AccountActivation history={props.history} />

            </Content>
        </Layout>
    );
};
