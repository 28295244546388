import {
    GET_BLOCK_TIME_STARTED,
    GET_BLOCK_TIME_SUCCESS,
    GET_BLOCK_TIME_FAILURE,
    REMOVE_ERROR_MESSAGE,
} from "../../../constants/appointment/availability/availability";

const initialState = {
    blockTimes: [],
    loading: false,
    error: null,
    message: null,
    component: "",
};

export default function availability(state = initialState, action) {
    switch (action.type) {
        case GET_BLOCK_TIME_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "CreateAppointmentContainer",
            };
        case GET_BLOCK_TIME_SUCCESS: 
            // let blockTimes = {...state.blockTimes}
            // blockTimes = {...blockTimes, [action.payload.staff]: action.payload.data}
            let blockTimes = action.payload.data
            return {
                ...state,
                loading: false,
                blockTimes
            }
        case GET_BLOCK_TIME_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        case REMOVE_ERROR_MESSAGE: {
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
            };
        }

        default:
            return {
                ...state,
            };
    }
}
