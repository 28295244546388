import React from "react";
// import { Modal } from "antd";
import AccountActivation from "./AccountActivation";
import { DashBookingLogo, } from "../../layouts/Navigation/DashBookingLogo";
import { PageHeader } from '@ant-design/pro-layout';
import { Layout, Row, Col } from "antd";
// import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { Menu } from "antd";

import {
    UserOutlined,
    CalendarOutlined,
    SettingOutlined,
    // AreaChartOutlined,
    CheckCircleOutlined,
    BarChartOutlined,
    LineChartOutlined,
    HistoryOutlined,
    PushpinOutlined,
    CreditCardOutlined,
    HourglassOutlined,
} from "@ant-design/icons";
import "../../layouts/Navigation/MenuContainer.css";

const { Header, Sider, Content } = Layout;
export const ActivationModal = (props) => {

    const items = [
        {
            label: "Checked-in",
            key: "/checked-in",
            disabled: true,
            icon: <CheckCircleOutlined />
        },
        {
            label: "Appointments",
            key: "/appointments",
            disabled: true,
            icon: <PushpinOutlined />
        },
        {
            label: "Calendar",
            key: "/calendar",
            disabled: true,
            icon: <CalendarOutlined />
        },
        {
            label: "Clients",
            key: "/clients",
            disabled: true,
            icon: <UserOutlined />
        },
        {
            label: "Time Clock",
            key: "/time-clock",
            disabled: true,
            icon: <HourglassOutlined />
        },
        {
            label: "History",
            key: "/history",
            disabled: true,
            icon: <HistoryOutlined />
        },
        {
            label: "Reports",
            key: "sub2",
            icon: <BarChartOutlined />,
            disabled: true,
            children: [
                {
                    label: "Statistics",
                    key: "/reports/statistics",
                    disabled: true,
                    icon: <LineChartOutlined />
                },
                {
                    label: "Billing",
                    key: "/reports/billing",
                    disabled: true,
                    icon: <CreditCardOutlined />
                }
            ]
        },
        {
            label: "Settings",
            key: "/settings",
            disabled: true,
            icon: <SettingOutlined />
        }
    ]

    return (
        <Layout style={{ height: "100%" }}>
            <Sider trigger={null} collapsible collapsed={false}>
                <div className="logo">
                    <DashBookingLogo />
                </div>
                <Menu theme="dark" mode="inline" items={items} />
            </Sider>
            <Layout className="site-layout">
                <Header
                    className="site-layout-background"
                    style={{ padding: 0 }}
                >
                    <Row justify="start">
                        <Col span={2}></Col>
                        <Col span={22}>
                            {/* Page title defined by current location
                      Contain notification and account setting
                  */}
                            <PageHeader
                                title={"Enter a credit card to activate your account"}
                                className="site-page-header-container"
                                style={{ padding: "10px" }}
                            />
                        </Col>
                    </Row>
                </Header>
                <Content
                    className="site-layout-background"
                    style={{
                        margin: "24px 0px 5px 5px",
                        padding: "5px 5px 5px 10px",
                        overflow: "scroll",
                    }}
                >

                    <AccountActivation history={props.history} />

                </Content>
            </Layout>
        </Layout >
    );
};
