export const GET_TAXES_STARTED = "GET_TAXES_STARTED"
export const GET_TAXES_SUCCESS = "GET_TAXES_SUCCESS"
export const GET_TAXES_FAILURE = "GET_TAXES_FAILURE"

export const CREATE_TAX_STARTED = "CREATE_TAX_STARTED"
export const CREATE_TAX_SUCCESS = "CREATE_TAX_SUCCESS"
export const CREATE_TAX_FAILURE = "CREATE_TAX_FAILURE"

export const UPDATE_TAX_STARTED = "UPDATE_TAX_STARTED"
export const UPDATE_TAX_SUCCESS = "UPDATE_TAX_SUCCESS"
export const UPDATE_TAX_FAILURE = "UPDATE_TAX_FAILURE"

export const DELETE_TAX_STARTED = "DELETE_TAX_STARTED"
export const DELETE_TAX_SUCCESS = "DELETE_TAX_SUCCESS"
export const DELETE_TAX_FAILURE = "DELETE_TAX_FAILURE"

export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE"
