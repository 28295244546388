export const GET_BUSINESS_STATISTIC_LAST_TWELVE_MONTHS_START = "GET_BUSINESS_STATISTIC_LAST_TWELVE_MONTHS_START"
export const GET_BUSINESS_STATISTIC_LAST_TWELVE_MONTHS_SUCCESS = "GET_BUSINESS_STATISTIC_LAST_TWELVE_MONTHS_SUCCESS"
export const GET_BUSINESS_STATISTIC_LAST_TWELVE_MONTHS_FAILURE = "GET_BUSINESS_STATISTIC_LAST_TWELVE_MONTHS_FAILURE"

export const GET_BUSINESS_STATISTIC_MONTHS_LIST_START = "GET_BUSINESS_STATISTIC_MONTHS_LIST_START"
export const GET_BUSINESS_STATISTIC_MONTHS_LIST_SUCCESS = "GET_BUSINESS_STATISTIC_MONTHS_LIST_SUCCESS"
export const GET_BUSINESS_STATISTIC_MONTHS_LIST_FAILURE = "GET_BUSINESS_STATISTIC_MONTHS_LIST_FAILURE"


export const GET_BUSINESS_STATISTIC_DETAILS_START= "GET_BUSINESS_STATISTIC_DETAILS_START"
export const GET_BUSINESS_STATISTIC_DETAILS_SUCCESS= "GET_BUSINESS_STATISTIC_DETAILS_SUCCESS"
export const GET_BUSINESS_STATISTIC_DETAILS_FAILURE= "GET_BUSINESS_STATISTIC_DETAILS_FAILURE"

export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE"