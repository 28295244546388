export const GET_LOYALTY_SETTING_STARTED = "GET_LOYALTY_SETTING_STARTED"
export const GET_LOYALTY_SETTING_SUCCESS = "GET_LOYALTY_SETTING_SUCCESS"
export const GET_LOYALTY_SETTING_FAILURE = "GET_LOYALTY_SETTING_FAILURE"

export const CREATE_LOYALTY_SETTING_STARTED = "CREATE_LOYALTY_SETTING_STARTED"
export const CREATE_LOYALTY_SETTING_SUCCESS = "CREATE_LOYALTY_SETTING_SUCCESS"
export const CREATE_LOYALTY_SETTING_FAILURE = "CREATE_LOYALTY_SETTING_FAILURE"

export const UPDATE_LOYALTY_SETTING_STARTED = "UPDATE_LOYALTY_SETTING_STARTED"
export const UPDATE_LOYALTY_SETTING_SUCCESS = "UPDATE_LOYALTY_SETTING_SUCCESS"
export const UPDATE_LOYALTY_SETTING_FAILURE = "UPDATE_LOYALTY_SETTING_FAILURE"

export const UPDATE_LOYALTY_SETTING_NEW_CLIENT_STARTED = "UPDATE_LOYALTY_SETTING_NEW_CLIENT_STARTED"
export const UPDATE_LOYALTY_SETTING_NEW_CLIENT_SUCCESS = "UPDATE_LOYALTY_SETTING_NEW_CLIENT_SUCCESS"
export const UPDATE_LOYALTY_SETTING_NEW_CLIENTG_FAILURE = "UPDATE_LOYALTY_SETTING_NEW_CLIENTG_FAILURE"

export const GET_LOYALTY_CONFIG_STARTED = "GET_LOYALTY_CONFIG_STARTED"
export const GET_LOYALTY_CONFIG_SUCCESS = "GET_LOYALTY_CONFIG_SUCCESS"
export const GET_LOYALTY_CONFIG_FAILURE = "GET_LOYALTY_CONFIG_FAILURE"

export const UPDATE_LOYALTY_CONFIG_STARTED = "UPDATE_LOYALTY_CONFIG_STARTED"
export const UPDATE_LOYALTY_CONFIG_SUCCESS = "UPDATE_LOYALTY_CONFIG_SUCCESS"
export const UPDATE_LOYALTY_CONFIG_FAILURE = "UPDATE_LOYALTY_CONFIG_FAILURE"

export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE"