export const GET_BUSINESS_BILLING_HISTORY_STARTED = "GET_BUSINESS_BILLING_HISTORY_STARTED"
export const GET_BUSINESS_BILLING_HISTORY_SUCCESS = "GET_BUSINESS_BILLING_HISTORY_SUCCESS"
export const GET_BUSINESS_BILLING_HISTORY_FAILURE = "GET_BUSINESS_BILLING_HISTORY_FAILURE"

export const GET_BUSINESS_BILLING_DETAILS_STARTED = "GET_BUSINESS_BILLING_DETAILS_STARTED"
export const GET_BUSINESS_BILLING_DETAILS_SUCCESS = "GET_BUSINESS_BILLING_DETAILS_SUCCESS"
export const GET_BUSINESS_BILLING_DETAILS_FAILURE = "GET_BUSINESS_BILLING_DETAILS_FAILURE"


export const UPDATE_PAYMENT_METHOD_STARTED = "UPDATE_PAYMENT_METHOD_STARTED"
export const UPDATE_PAYMENT_METHOD_SUCCESS = "UPDATE_PAYMENT_METHOD_SUCCESS"
export const UPDATE_PAYMENT_METHOD_FAILURE = "UPDATE_PAYMENT_METHOD_FAILURE"

export const CREATE_PAYMENT_METHOD_STARTED = "CREATE_PAYMENT_METHOD_STARTED"
export const CREATE_PAYMENT_METHOD_SUCCESS = "CREATE_PAYMENT_METHOD_SUCCESS"
export const CREATE_PAYMENT_METHOD_FAILURE = "CREATE_PAYMENT_METHOD_FAILURE"

export const UPDATE_VOID_CHEQUE_STARTED = "UPDATE_VOID_CHEQUE_STARTED"
export const UPDATE_VOID_CHEQUE_SUCCESS = "UPDATE_VOID_CHEQUE_SUCCESS"
export const UPDATE_VOID_CHEQUE_FAILURE = "UPDATE_VOID_CHEQUE_FAILURE"

export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE"