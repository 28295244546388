export const AUTHENTICATE_USER = "AUTHENTICATE_USER";

export const LOGOUT = "LOGOUT"

export const LOGOUT_ADMIN = "LOGOUT_ADMIN"

export const LOGOUT_ALL_USER = "LOGOUT_ALL_USER"

export const AUTHENTICATE_USER_STARTED = "AUTHENTICATE_USER_STARTED"
export const AUTHENTICATE_USER_SUCCESS = "AUTHENTICATE_USER_SUCCESS"
export const AUTHENTICATE_USER_FAILURE = "AUTHENTICATE_USER_FAILURE"


export const FORGOT_PASSWORD_STARTED = "FORGOT_PASSWORD_STARTED"
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS"
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE"

export const RESET_PASSWORD_STARTED = "RESET_PASSWORD_STARTED"
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS"
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE"

export const ACTIVATE_ACCOUNT_STARTED = "ACTIVATE_ACCOUNT_STARTED"
export const ACTIVATE_ACCOUNT_SUCCESS = "ACTIVATE_ACCOUNT_SUCCESS"
export const ACTIVATE_ACCOUNT_FAILURE = "ACTIVATE_ACCOUNT_FAILURE"

export const CANCEL_ACTIVATE_ACCOUNT = "CANCEL_ACTIVATE_ACCOUNT"

export const UPDATE_PASSWORD_STARTED = "UPDATE_PASSWORD_STARTED"
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS"
export const UPDATE_PASSWORD_FAILURE = "UPDATE_PASSWORD_FAILURE"

export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE"