import {
    GET_MENU_STARTED,
    GET_MENU_SUCCESS,
    GET_MENU_FAILURE,
    CREATE_CATEGORY_STARTED,
    CREATE_CATEGORY_SUCCESS,
    CREATE_CATEGORY_FAILURE,
    UPDATE_CATEGORY_STARTED,
    UPDATE_CATEGORY_SUCCESS,
    UPDATE_CATEGORY_FAILURE,
    DELETE_CATEGORY_FAILURE,
    DELETE_CATEGORY_STARTED,
    DELETE_CATEGORY_SUCCESS,
    UPDATE_CATEGORY_ORDER_STARTED,
    UPDATE_CATEGORY_ORDER_SUCCESS,
    UPDATE_CATEGORY_ORDER_FAILURE,
    UPDATE_ALL_SERVICES,
    GET_SERVICE_DETAIL_STARTED,
    GET_SERVICE_DETAIL_SUCCESS,
    GET_SERVICE_DETAIL_FAILURE,
    UPDATE_SERVICE_DETAIL_STARTED,
    UPDATE_SERVICE_DETAIL_SUCCESS,
    UPDATE_SERVICE_DETAIL_FAILURE,
    CREATE_SERVICE_STARTED,
    CREATE_SERVICE_SUCCESS,
    CREATE_SERVICE_FAILURE,
    DELETE_SERVICE_STARTED,
    DELETE_SERVICE_SUCCESS,
    DELETE_SERVICE_FAILURE,
    UPDATE_SERVICE_ORDER_STARTED,
    UPDATE_SERVICE_ORDER_SUCCESS,
    UPDATE_SERVICE_ORDER_FAILURE,
    CREATE_COMBO_STARTED,
    CREATE_COMBO_SUCCESS,
    CREATE_COMBO_FAILURE,
    UPDATE_COMBO_STARTED,
    UPDATE_COMBO_SUCCESS,
    UPDATE_COMBO_FAILURE,
    DELETE_COMBO_STARTED,
    DELETE_COMBO_SUCCESS,
    DELETE_COMBO_FAILURE,
    GET_COMBO_DETAIL_STARTED,
    GET_COMBO_DETAIL_SUCCESS,
    GET_COMBO_DETAIL_FAILURE,
    CLEAR_SERVICE_STARTED,
    REMOVE_ERROR_MESSAGE
} from "../../constants/menu/menu";
import { sortArray } from "../../../utils/SortArray/SortArray"


const getCategoriesFromLocalStorage = () => {
    const localCategoryData = localStorage.getItem("categories")
    if (localCategoryData) {
        const categories = JSON.parse(localCategoryData)
        return categories
    }
    return []
}

const getServicesFromLocalStorage = () => {
    const localServiceData = localStorage.getItem("services")
    if (localServiceData) {
        const services = JSON.parse(localServiceData)
        return services
    }
    return []
}

const initialState = {
    categories: getCategoriesFromLocalStorage(),
    services: getServicesFromLocalStorage(),
    service: null,
    loading: false,
    error: null,
    message: null,
    component: "",
};



export default function menu(state = initialState, action) {
    let newServiceArray = []
    let newCategoryArray = []
    let serviceOrder = []
    let updatedCategory = {}
    let updatedService = {}
    switch (action.type) {
        case UPDATE_COMBO_STARTED:
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
            };
        case UPDATE_COMBO_SUCCESS:
            updatedService = action.payload.data
            //update service array
            newServiceArray = state.services.map(service => {
                if (service._id === updatedService._id) {
                    return updatedService
                }
                return service
            })
            //update category array
            newCategoryArray = state.categories.map(category => {
                //add service _id to category that contain the service
                if (category._id === updatedService.serviceCategory && !category.services.includes(updatedService._id)) {
                    category.services = [...category.services, updatedService._id]
                }
                //remove service _id in cateogry that does not contain the service
                if (category._id !== updatedService.serviceCategory && category.services.includes(updatedService._id)) {
                    category.services = category.services.filter(service => service !== updatedService._id)
                }
                return category
            })
            localStorage.setItem("categories", JSON.stringify(newCategoryArray));
            localStorage.setItem("services", JSON.stringify(newServiceArray));
            return {
                ...state,
                loading: false,
                categories: newCategoryArray,
                services: newServiceArray,
                error: null,
                message: "Combo was updated successfully!",
            };
        case UPDATE_COMBO_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
            };

        case DELETE_COMBO_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case DELETE_COMBO_SUCCESS:
            updatedService = action.payload.combo
            updatedCategory = action.payload.category
            newServiceArray = state.services.filter(service => service._id !== updatedService._id)
            newCategoryArray = state.categories.map(category =>
                category._id === updatedCategory._id ? updatedCategory : category
            )
            localStorage.setItem("categories", JSON.stringify(newCategoryArray));
            localStorage.setItem("services", JSON.stringify(newServiceArray));
            return {
                ...state,
                loading: false,
                categories: newCategoryArray,
                services: newServiceArray,
                error: null,
                message: "Combo was deleted successfully!",
            };
        case DELETE_COMBO_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
            };
        case GET_COMBO_DETAIL_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case GET_COMBO_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                service: action.payload.data,
                error: null,
                message: null,
            };
        case GET_COMBO_DETAIL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
            };
        case CREATE_COMBO_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case CREATE_COMBO_SUCCESS:
            updatedService = action.payload.combo
            updatedCategory = action.payload.category
            newCategoryArray = state.categories.map(category =>
                category._id === updatedCategory._id ? updatedCategory : category
            )
            newServiceArray = [...state.services, updatedService]

            localStorage.setItem("categories", JSON.stringify(newCategoryArray));
            localStorage.setItem("services", JSON.stringify(newServiceArray));
            return {
                ...state,
                loading: false,
                categories: newCategoryArray,
                services: newServiceArray,
                error: null,
                message: "Combo was created successfully!",
            };
        case CREATE_COMBO_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
            };
        case GET_MENU_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "MenuContainer",
            };
        case GET_MENU_SUCCESS:
            newCategoryArray = action.payload.data.categories
            newServiceArray = action.payload.data.services
            //reorder the service
            serviceOrder = newCategoryArray.map(category => category.services).flat()
            newServiceArray = sortArray(newServiceArray, serviceOrder, "_id")

            localStorage.setItem("services", JSON.stringify(newServiceArray));
            localStorage.setItem("categories", JSON.stringify(newCategoryArray));
            return {
                ...state,
                loading: false,
                categories: action.payload.data.categories,
                services: action.payload.data.services,
                error: null,
                message: null,
            };

        case GET_MENU_FAILURE:
            localStorage.removeItem("services")
            localStorage.removeItem("categories")
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
            }
        case UPDATE_ALL_SERVICES:
            newServiceArray = action.payload.services
            newCategoryArray = state.categories
            serviceOrder = newCategoryArray.map(category => category.services).flat()
            newServiceArray = sortArray(newServiceArray, serviceOrder, "_id")
            localStorage.setItem("services", JSON.stringify(newServiceArray))
            return {
                ...state,
                loading: false,
                services: newServiceArray,
            }
        case CREATE_CATEGORY_STARTED:
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
            }
        case CREATE_CATEGORY_SUCCESS:
            newCategoryArray = [...state.categories, action.payload.data]
            localStorage.setItem("categories", JSON.stringify(newCategoryArray))
            return {
                ...state,
                loading: false,
                categories: newCategoryArray,
                message: "New category was created successfully!"
            }
        case CREATE_CATEGORY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case UPDATE_CATEGORY_STARTED:
            return {
                ...state,
                loading: false,
                error: null,
                message: null
            }
        case UPDATE_CATEGORY_SUCCESS:
            updatedCategory = action.payload.data
            newCategoryArray = state.categories.map(category =>
                category._id === updatedCategory._id ? updatedCategory : category
            )
            newServiceArray = state.services.map(service => {
                if (service.serviceCategory === updatedCategory._id) {
                    service.color = updatedCategory.color
                }
                return service
            })
            localStorage.setItem("services", JSON.stringify(newServiceArray))
            localStorage.setItem("categories", JSON.stringify(newCategoryArray))
            return {
                ...state,
                loading: false,
                services: newServiceArray,
                categories: newCategoryArray,
                message: "Category was saved successfully"
            }
        case UPDATE_CATEGORY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
                message: null
            };


        case DELETE_CATEGORY_STARTED:
            return {
                ...state,
                loading: false,
                error: null,
                message: null
            }
        case DELETE_CATEGORY_SUCCESS:
            newCategoryArray = state.categories.filter(cat => cat._id !== action.payload.data._id)
            newServiceArray = state.services.filter(service => service.serviceCategory !== action.payload.data._id)
            localStorage.setItem("categories", JSON.stringify(newCategoryArray))
            localStorage.setItem("services", JSON.stringify(newServiceArray))
            return {
                ...state,
                loading: false,
                message: "Category was removed!",
                error: null,
                categories: newCategoryArray
            }
        case DELETE_CATEGORY_FAILURE:
            return {
                ...state,
                loading: false,
                message: null,
                error: action.payload.error
            }
        case UPDATE_CATEGORY_ORDER_STARTED:
            return {
                ...state,
                loading: false,
                error: null,
                message: null
            }
        case UPDATE_CATEGORY_ORDER_SUCCESS:
            newCategoryArray = sortArray(state.categories, action.payload.data, "_id")
            serviceOrder = newCategoryArray.map(category => category.services).flat()
            newServiceArray = sortArray(state.services, serviceOrder, "_id")
            localStorage.setItem("categories", JSON.stringify(newCategoryArray));
            localStorage.setItem("services", JSON.stringify(newServiceArray));
            return {
                ...state,
                loading: false,
                categories: newCategoryArray,
                services: newServiceArray
            }
        case UPDATE_CATEGORY_ORDER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null
            }

        case GET_SERVICE_DETAIL_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null
            }
        case GET_SERVICE_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                service: action.payload.data,
                error: null,
                message: null,
            }
        case GET_SERVICE_DETAIL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null
            }
        case UPDATE_SERVICE_DETAIL_STARTED:
            return {
                ...state,
                loading: false,
                error: null,
                message: null
            }
        case UPDATE_SERVICE_DETAIL_SUCCESS:
            updatedService = action.payload.data
            //update service array
            newServiceArray = state.services.map(service => {
                if (service._id === updatedService._id) {
                    return updatedService
                }
                return service
            })
            //update category array
            newCategoryArray = state.categories.map(category => {
                //add service _id to category that contain the service
                if (category._id === updatedService.serviceCategory && !category.services.includes(updatedService._id)) {
                    category.services = [...category.services, updatedService._id]
                }
                //remove service _id in cateogry that does not contain the service
                if (category._id !== updatedService.serviceCategory && category.services.includes(updatedService._id)) {
                    category.services = category.services.filter(service => service !== updatedService._id)
                }
                return category
            })
            localStorage.setItem("categories", JSON.stringify(newCategoryArray));
            localStorage.setItem("services", JSON.stringify(newServiceArray));

            return {
                ...state,
                loading: false,
                categories: newCategoryArray,
                services: newServiceArray,
                error: null,
                message: "Service has been updated successfully!"
            }
        case UPDATE_SERVICE_DETAIL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null
            }
        case CREATE_SERVICE_STARTED:
            return {
                ...state,
                loading: true,
            }
        case CREATE_SERVICE_SUCCESS:
            updatedService = action.payload.data.service
            updatedCategory = action.payload.data.category
            newCategoryArray = state.categories.map(category =>
                category._id === updatedCategory._id ? updatedCategory : category
            )
            newServiceArray = [...state.services, updatedService]

            localStorage.setItem("categories", JSON.stringify(newCategoryArray));
            localStorage.setItem("services", JSON.stringify(newServiceArray));
            return {
                ...state,
                loading: false,
                categories: newCategoryArray,
                services: newServiceArray,
                error: null,
                message: "Service has been created successfully!"
            }
        case CREATE_SERVICE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null
            }
        case DELETE_SERVICE_STARTED:
            return {
                ...state,
                loading: true,
                message: null,
                error: null
            }
        case DELETE_SERVICE_SUCCESS:
            updatedService = action.payload.data.service
            updatedCategory = action.payload.data.category
            newServiceArray = state.services.filter(service => service._id !== updatedService._id)
            newCategoryArray = state.categories.map(category =>
                category._id === updatedCategory._id ? updatedCategory : category
            )
            localStorage.setItem("categories", JSON.stringify(newCategoryArray));
            localStorage.setItem("services", JSON.stringify(newServiceArray));
            return {
                ...state,
                loading: false,
                categories: newCategoryArray,
                services: newServiceArray,
                message: "Service has been deleted successfully!",
                error: null,
            }
        case DELETE_SERVICE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null
            }

        case UPDATE_SERVICE_ORDER_STARTED:
            return {
                ...state,
                loading: false,
                error: null,
                message: null
            }

        case UPDATE_SERVICE_ORDER_SUCCESS:
            updatedCategory = action.payload.data

            newCategoryArray = state.categories.map(category => {
                if (category._id === action.payload.data._id) {
                    category.services = action.payload.data.services
                }

                return category
            })

            serviceOrder = newCategoryArray.map(category => category.services).flat()
            newServiceArray = sortArray(state.services, serviceOrder, "_id")

            localStorage.setItem("services", JSON.stringify(newServiceArray));
            localStorage.setItem("categories", JSON.stringify(newCategoryArray));
            return {
                ...state,
                loading: false,
                categories: newCategoryArray,
                services: newServiceArray
            }
        case UPDATE_SERVICE_ORDER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case CLEAR_SERVICE_STARTED:
            return {
                ...state,
                service: null
            }

        default:
            return {
                ...state,
            };
    }
}
