export const GET_GIFT_CARDS_STARTED = "GET_GIFT_CARDS_STARTED"
export const GET_GIFT_CARDS_SUCCESS = "GET_GIFT_CARDS_SUCCESS"
export const GET_GIFT_CARDS_FAILURE = "GET_GIFT_CARDS_FAILURE"

export const GET_GIFT_CARD_HISTORY_STARTED = "GET_GIFT_CARD_HISTORY_STARTED"
export const GET_GIFT_CARD_HISTORY_SUCCESS = "GET_GIFT_CARD_HISTORY_SUCCESS"
export const GET_GIFT_CARD_HISTORY_FAILURE = "GET_GIFT_CARD_HISTORY_FAILURE"

export const CREATE_GIFT_CARD_STARTED = "CREATE_GIFT_CARD_STARTED"
export const CREATE_GIFT_CARD_SUCCESS = "CREATE_GIFT_CARD_SUCCESS"
export const CREATE_GIFT_CARD_FAILURE = "CREATE_GIFT_CARD_FAILURE"

export const UPDATE_GIFT_CARD_STARTED = "UPDATE_GIFT_CARD_STARTED"
export const UPDATE_GIFT_CARD_SUCCESS = "UPDATE_GIFT_CARD_SUCCESS"
export const UPDATE_GIFT_CARD_FAILURE = "UPDATE_GIFT_CARD_FAILURE"


export const DELETE_GIFT_CARD_STARTED = "DELETE_GIFT_CARD_STARTED"
export const DELETE_GIFT_CARD_SUCCESS = "DELETE_GIFT_CARD_SUCCESS"
export const DELETE_GIFT_CARD_FAILURE = "DELETE_GIFT_CARD_FAILURE"

export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE"