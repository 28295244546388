import {
    GET_GIFT_CARDS_STARTED,
    GET_GIFT_CARDS_SUCCESS,
    GET_GIFT_CARDS_FAILURE,
    CREATE_GIFT_CARD_STARTED,
    CREATE_GIFT_CARD_SUCCESS,
    CREATE_GIFT_CARD_FAILURE,
    UPDATE_GIFT_CARD_STARTED,
    UPDATE_GIFT_CARD_SUCCESS,
    UPDATE_GIFT_CARD_FAILURE,
    // DELETE_GIFT_CARD_STARTED,
    DELETE_GIFT_CARD_SUCCESS,
    DELETE_GIFT_CARD_FAILURE,
    REMOVE_ERROR_MESSAGE

} from '../../constants/gift-card-history/giftCardHistory'

const initialState = {
    countCards: 0,
    cards: [],
    loading: false,
    error: null,
    message: null,
};

export default function giftCardHistory(state = initialState, action) {
    switch (action.type) {

        case GET_GIFT_CARDS_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case GET_GIFT_CARDS_SUCCESS:
            return {
                ...state,
                loading: false,
                cards: action.payload.cards,
                countCards: action.payload.countCards,
            };
        case GET_GIFT_CARDS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };

        case CREATE_GIFT_CARD_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case CREATE_GIFT_CARD_SUCCESS:
            let { cards } = state
            let newCard = action.payload.card
            cards = [newCard, ...cards]
            return {
                ...state,
                loading: false,
                cards: cards,
                currentCard: newCard.cardNumber,
                message: 'Gift card has been created successfully!'
            };
        case CREATE_GIFT_CARD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };

        case UPDATE_GIFT_CARD_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case UPDATE_GIFT_CARD_SUCCESS:
            let updatedCards = state.cards
            for (let card of updatedCards) {
                if (card.cardNumber === action.payload.card.cardNumber) {
                    card = action.payload.card
                }
            }
            return {
                ...state,
                loading: false,
                cards: updatedCards,
                message: 'Gift card has been updated successfully!'
            };
        case UPDATE_GIFT_CARD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };

        case DELETE_GIFT_CARD_SUCCESS:
            let removedCards = state.cards
            let countCards = state.countCards - 1
            removedCards = removedCards.filter(card => card.cardNumber !== action.payload.cardNumber)
            return {
                ...state,
                loading: false,
                cards: removedCards,
                countCards,
                currentCard: null,
                message: 'Gift card has been removed successfully!'

            };
        case DELETE_GIFT_CARD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        //remove error
        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                loadingHistory: false,
                error: null,
                message: null
            };
        default:
            return {
                ...state,
            };
    }
}