import {
    COLLAPSE_MENU,
    COLLAPSE_MENU_DONE
} from "../../constants/responsive/responsive";

export const collapseMenu = (isMenuCollapsed) => {
    return (dispatch) => {
        dispatch(collapseMenuStarted(isMenuCollapsed))
        setTimeout(function() {return dispatch(collapseMenuDone())}, 400)
    }
}

const collapseMenuStarted = (isMenuCollapsed) => ({
    type: COLLAPSE_MENU,
    payload: isMenuCollapsed
});

const collapseMenuDone = () => {
    return {
        type: COLLAPSE_MENU_DONE,
    }
}