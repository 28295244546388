import {
    GET_SMS_SETTINGS_STARTED,
    GET_SMS_SETTINGS_SUCCESS,
    GET_SMS_SETTINGS_FAILURE,
    UPDATE_SMS_SETTINGS_STARTED,
    UPDATE_SMS_SETTINGS_SUCCESS,
    UPDATE_SMS_SETTINGS_FAILURE,
    REMOVE_ERROR_MESSAGE,
} from "../../constants/sms-settings/smsSettings";

const initialState = {
    settings: null,
    loading: false,
    error: null,
    message: null,
};

export default function smsSettings(state = initialState, action) {
    switch (action.type) {
        case GET_SMS_SETTINGS_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            }
        case GET_SMS_SETTINGS_SUCCESS:
            return {
                ...state,
                settings: action.payload.data,
                loading: false,
            }
        case GET_SMS_SETTINGS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        case UPDATE_SMS_SETTINGS_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            }
        case UPDATE_SMS_SETTINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                settings: action.payload.data,
                message: "Your sms settings has been saved!",
            }
        case UPDATE_SMS_SETTINGS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }


        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                error: null,
                message: null
            };
        default:
            return {
                ...state,
            };
    }
}
