import {
    GET_CLOVER_STATUS,
    GET_CLOVER_MESSAGE,

    GET_CLOVER_SETTING_STARTED,
    GET_CLOVER_SETTING_SUCCESS,
    GET_CLOVER_SETTING_FAILURE,

    UPDATE_CLOVER_SETTING_STARTED,
    UPDATE_CLOVER_SETTING_SUCCESS,
    UPDATE_CLOVER_SETTING_FAILURE,
    UPDATE_CLOVER_AUTH_TOKEN_STARTED,
    UPDATE_CLOVER_AUTH_TOKEN_SUCCESS,
    UPDATE_CLOVER_AUTH_TOKEN_FAILURE,
    REMOVE_ERROR_MESSAGE
} from "../../constants/clover/cloverStatus"
const getCloverAuthToken = () => {
    const localData = localStorage.getItem("CloverAuth")
    if (localData) {
        return localData
    } else
        return null
}
const initialState = {
    status: "disconnected",
    isDeviceReady: false,
    cloverAuth: getCloverAuthToken(),
    tipAmount: 0,
    loading: false,
    cloverSettings: null,
    error: null,
    message: null,
    component: ""
};
export default function clover(state = initialState, action) {
    switch (action.type) {
        case UPDATE_CLOVER_AUTH_TOKEN_STARTED:
            return {
                ...state,
            }
        case UPDATE_CLOVER_AUTH_TOKEN_SUCCESS:
            return {
                ...state,
                cloverAuth: action.payload,
            }
        case UPDATE_CLOVER_AUTH_TOKEN_FAILURE:
            return {
                ...state,
                error: action.payload.error
            }
        case GET_CLOVER_STATUS:
            let isDeviceReady = state.isDeviceReady
            if (action.payload.status === "deviceReady") {
                isDeviceReady = true
            } else {
                isDeviceReady = false
            }

            return {
                ...state,
                isDeviceReady,
                status: action.payload.status,
                tipAmount: action.payload.tipAmount
            }
        case GET_CLOVER_MESSAGE:
            return {
                ...state,
                message: action.payload.message
            }
        case GET_CLOVER_SETTING_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null
            }
        case GET_CLOVER_SETTING_SUCCESS:
            const cloverSettings = action.payload.cloverSettings
            if (cloverSettings.cloverAuth) {
                localStorage.setItem("CloverAuth", cloverSettings.cloverAuth)
                localStorage.setItem("CloverConnected", true);
            }
            return {
                ...state,
                loading: false,
                cloverSettings: action.payload.cloverSettings,
            }
        case GET_CLOVER_SETTING_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null
            }
        case UPDATE_CLOVER_SETTING_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null
            }
        case UPDATE_CLOVER_SETTING_SUCCESS:
            if (!action.payload.cloverSettings?.uriText) {
                localStorage.removeItem("CloverConnected")
            }
            return {
                ...state,
                loading: false,
                cloverSettings: action.payload.cloverSettings,
                message: "Updated Clover Settings Successfully"
            }
        case UPDATE_CLOVER_SETTING_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null
            }
        //remove error
        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
                message: null
            };
        default:
            return state;
    }
}