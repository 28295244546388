import {
    GET_NOTIFICATIONS_COUNT_STARTED,
    GET_NOTIFICATIONS_COUNT_SUCCESS,
    GET_NOTIFICATIONS_COUNT_FAILURE,
    GET_NOTIFICATIONS_STARTED,
    GET_NOTIFICATIONS_SUCCESS,
    GET_NOTIFICATIONS_FAILURE,
    LOAD_MORE_NOTIFICATIONS_STARTED,
    LOAD_MORE_NOTIFICATIONS_SUCCESS,
    LOAD_MORE_NOTIFICATIONS_FAILURE,
    UPDATE_NOTIFICATION_STATUS_STARTED,
    UPDATE_NOTIFICATION_STATUS_SUCCESS,
    UPDATE_NOTIFICATION_STATUS_FAILURE,
    CLEAR_NOTIFICATIONS_STARTED,
    CLEAR_NOTIFICATIONS_SUCCESS,
    CLEAR_NOTIFICATIONS_FAILURE,
    MARK_NOTIFICATIONS_AS_READ_STARTED,
    MARK_NOTIFICATIONS_AS_READ_SUCCESS,
    MARK_NOTIFICATIONS_AS_READ_FAILURE,
    REMOVE_ERROR_MESSAGE,
} from "../../constants/notification/notification";

const initialState = {
    notifications: [],
    count: {},
    loading: false,
    error: null,
    message: null,
    component: ""
};

export default function notification(state = initialState, action) {
    switch (action.type) {
        case GET_NOTIFICATIONS_COUNT_STARTED:
            return {
                ...state,
                component: "NotificationContainer"
            };
        case GET_NOTIFICATIONS_COUNT_SUCCESS:
            return {
                ...state,
                count: action.payload.data
            };
        case GET_NOTIFICATIONS_COUNT_FAILURE:
            return {
                ...state,
            }
        case GET_NOTIFICATIONS_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "NotificationContainer"
            };
        case GET_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                notifications: action.payload.notifications,
            };
        case GET_NOTIFICATIONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        case LOAD_MORE_NOTIFICATIONS_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "NotificationContainer"
            };
        case LOAD_MORE_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                notifications: [...state.notifications, ...action.payload.notifications],
            };
        case LOAD_MORE_NOTIFICATIONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        case UPDATE_NOTIFICATION_STATUS_STARTED:
            return {
                ...state,
                loading: false,
                error: null,
                component: "NotificationContainer"
            };
        case UPDATE_NOTIFICATION_STATUS_SUCCESS:

            let updatedNotificationId = action.payload.data;
            let newCount = state.count;
            newCount.unreadNotifications = newCount.unreadNotifications - 1;
            let newNotificationArray = state.notifications.map(notif => {
                if (notif._id === updatedNotificationId) {
                    notif.read = true
                }
                return notif
            });
            return {
                ...state,
                loading: false,
                count: newCount,
                notifications: newNotificationArray,
            };
        case UPDATE_NOTIFICATION_STATUS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };

        case CLEAR_NOTIFICATIONS_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case CLEAR_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                notifications: [],
                count: { unreadNotifications: 0, totalNotifications: 0 },
                message: "Notifications have been cleared!",
                loading: false,

            };
        case CLEAR_NOTIFICATIONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            }

        case MARK_NOTIFICATIONS_AS_READ_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case MARK_NOTIFICATIONS_AS_READ_SUCCESS:
            let { count, notifications } = state
            count.unreadNotifications = 0;
            notifications.map(notif => {
                if (notif.read === false) {
                    notif.read = true
                }
                return notif
            });
            return {
                ...state,
                notifications: notifications,
                count: count,
                message: "Notifications have been marked as read!",
                loading: false,

            };
        case MARK_NOTIFICATIONS_AS_READ_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            }
        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
                message: null
            };
        default:
            return {
                ...state,
            };
    }
}