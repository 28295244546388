import React from "react";
import "./Loading.css"
import {Spin} from "antd"
const Loading = () => {
    return (
        <div className="loading-component">
            <Spin/>
        </div>
    );
};

export default Loading