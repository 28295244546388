import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Input, Button, Typography, Alert, Row, Col } from "antd";
import { LoginOutlined } from "@ant-design/icons";
// import "./login.css";
import {
    authenticateUser,
    forgotPassword,
} from "../../redux/actions/salon-account/authenticate";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
// import { DashBookingHorizontalLogo } from "../../layouts/Navigation/DashBookingLogo";
const layout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};
let currentYear = new Date();

const { Link } = Typography;
class MobileLogin extends Component {
    state = {
        forgotPassword: false,
    };

    userChooseForgotPassword = () => {
        this.setState({
            forgotPassword: !this.state.forgotPassword,
        });
    };

    render() {
        const { forgotPassword } = this.state;
        const { loading, error, component, message } = this.props;

        const onFinish = (values) => {
            this.props.authenticateUser(values);
        };

        const onForgotPassword = (values) => {
            this.props.forgotPassword(values);
        };

        const onFinishFailed = (errorInfo) => {
            // console.log("Failed:", errorInfo);
        };

        return (
            <Row justify="center" align="middle" style={{ height: "100%", overflowY: "auto" }}>
                <Col span={20}>
                    <div>
                        {!forgotPassword ? (
                            <Form
                                {...layout}
                                name="basic"
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                size="large"
                                className="mobile-new-login-form"
                            >
                                <div className="form-title">
                                    <div
                                        style={{
                                            textAlign: "center",
                                            width: "100%",
                                        }}
                                    >
                                        <img src="/assets/images/dash-logo.png" alt="Dash Booking Logo" style={{ borderRadius: "5px" }} height="40" />
                                        <h1 >Partner Portal</h1>
                                    </div>
                                </div>

                                <Form.Item
                                    label="Username"
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            type: "email",
                                            message:
                                                "Please input your valid username!",
                                        },
                                    ]}
                                    style={{ marginBottom: "0px" }}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="Password"
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Please input your password!",
                                        },
                                    ]}
                                    style={{ marginBottom: "0px" }}
                                >
                                    <Input.Password />
                                </Form.Item>

                                <Form.Item
                                    style={{
                                        width: "100%",
                                        textAlign: "center",
                                        margin: "10px 0"
                                    }}
                                >
                                    <Link
                                        underline
                                        onClick={this.userChooseForgotPassword}
                                        style={{ color: "#0097B2" }}
                                    >
                                        Forgot your password?
                                    </Link>
                                </Form.Item>

                                <Form.Item
                                    style={{
                                        width: "100%",
                                        textAlign: "center",
                                    }}
                                >
                                    <Button
                                        type="primary"
                                        icon={<LoginOutlined />}
                                        block
                                        htmlType="submit"
                                        loading={loading}
                                        style={{ borderRadius: "5px", backgroundColor: "#0097B2" }}
                                    >
                                        Login
                                    </Button>
                                </Form.Item>
                                {error && (
                                    <Alert
                                        message="Error"
                                        description={error}
                                        type="error"
                                        showIcon
                                    />
                                )}
                            </Form>
                        ) : (
                            <ForgotPassword
                                loading={loading}
                                component={component}
                                message={message}
                                userChooseForgotPassword={
                                    this.userChooseForgotPassword
                                }
                                onForgotPassword={onForgotPassword}
                            />
                        )}
                    </div>
                    <div
                        // className="copy-right-footer"
                        style={{
                            width: "100%",
                            textAlign: "center",
                            marginTop: "20px",
                        }}
                    >
                        <p style={{ margin: "10px" }}>
                            {currentYear.getFullYear()} &copy; Dash Booking Inc.
                        </p>
                        <a
                            href="https://www.dashbooking.com/business/partners-terms-and-conditions"
                            rel="noreferrer"
                            target="_blank"
                            style={{ color: "#0097B2", }}
                        >
                            Terms and Policy
                        </a>
                        <a style={{ marginLeft: "15px", color: "#0097B2", }} href="https://www.dashbooking.com/privacy-policy" rel="noreferrer"
                            target="_blank">Privacy Policy</a>
                    </div>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = (state) => ({
    loading: state.authenticate.loading,
    error: state.authenticate.error,
    message: state.authenticate.message,
    component: state.authenticate.component,
});

function mapDispatchToProps(dispatch) {
    return {
        authenticateUser: (user) => dispatch(authenticateUser(user)),
        forgotPassword: (email) => dispatch(forgotPassword(email)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileLogin);
