export const GET_STAFF_STARTED = "GET_STAFF_STARTED"
export const GET_STAFF_SUCCESS = "GET_STAFF_SUCCESS"
export const GET_STAFF_FAILURE = "GET_STAFF_FAILURE"


// export const GET_STAFF_DATA_SETTINGS_STARTED = "GET_STAFF_DATA_SETTINGS_STARTED"
// export const GET_STAFF_DATA_SETTINGS_SUCCESS = "GET_STAFF_DATA_SETTINGS_SUCCESS"
// export const GET_STAFF_DATA_SETTINGS_FAILURE = "GET_STAFF_DATA_SETTINGS_FAILURE"

export const CREATE_STAFF_STARTED = "CREATE_STAFF_STARTED"
export const CREATE_STAFF_SUCCESS = "CREATE_STAFF_SUCCESS"
export const CREATE_STAFF_FAILURE = "CREATE_STAFF_FAILURE"

export const UPDATE_STAFF_DETAILS_STARTED = "UPDATE_STAFF_DETAILS_STARTED"
export const UPDATE_STAFF_DETAILS_SUCCESS = "UPDATE_STAFF_DETAILS_SUCCESS"
export const UPDATE_STAFF_DETAILS_FAILURE = "UPDATE_STAFF_DETAILS_FAILURE"


export const UPDATE_STAFF_SERVICES_STARTED = "UPDATE_STAFF_SERVICES_STARTED"
export const UPDATE_STAFF_SERVICES_SUCCESS = "UPDATE_STAFF_SERVICES_SUCCESS"
export const UPDATE_STAFF_SERVICES_FAILURE = "UPDATE_STAFF_SERVICES_FAILURE"


export const UPDATE_STAFF_ORDER_STARTED = "UPDATE_STAFF_ORDER_STARTED"
export const UPDATE_STAFF_ORDER_SUCCESS = "UPDATE_STAFF_ORDER_SUCCESS"
export const UPDATE_STAFF_ORDER_FAILURE = "UPDATE_STAFF_ORDER_FAILURE"


export const DELETE_STAFF_STARTED = "DELETE_STAFF_STARTED"
export const DELETE_STAFF_SUCCESS = "DELETE_STAFF_SUCCESS"
export const DELETE_STAFF_FAILURE = "DELETE_STAFF_FAILURE"

export const GET_STAFF_ASSIGN_SETTINGS_STARTED = "GET_STAFF_ASSIGN_SETTINGS_STARTED"
export const GET_STAFF_ASSIGN_SETTINGS_SUCCESS = "GET_STAFF_ASSIGN_SETTINGS_SUCCESS"
export const GET_STAFF_ASSIGN_SETTINGS_FAILURE = "GET_STAFF_ASSIGN_SETTINGS_FAILURE"

export const UPDATE_STAFF_ASSIGN_SETTINGS_STARTED = "UPDATE_STAFF_ASSIGN_SETTINGS_STARTED"
export const UPDATE_STAFF_ASSIGN_SETTINGS_SUCCESS = "UPDATE_STAFF_ASSIGN_SETTINGS_SUCCESS"
export const UPDATE_STAFF_ASSIGN_SETTINGS_FAILURE = "UPDATE_STAFF_ASSIGN_SETTINGS_FAILURE"

export const GET_STAFF_PRIORITY_STARTED = "GET_STAFF_PRIORITY_STARTED"
export const GET_STAFF_PRIORITY_SUCCESS = "GET_STAFF_PRIORITY_SUCCESS"
export const GET_STAFF_PRIORITY_FAILURE = "GET_STAFF_PRIORITY_FAILURE"

export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE"