import {
    CREATE_GIFT_CARD_STARTED,
    CREATE_GIFT_CARD_SUCCESS,
    CREATE_GIFT_CARD_FAILURE,
    CHECK_DUPLICATED_GIFT_CARD_NUMBER_STARTED,
    CHECK_DUPLICATED_GIFT_CARD_NUMBER_SUCCESS,
    CHECK_DUPLICATED_GIFT_CARD_NUMBER_FAILURE,
    GET_GIFT_CARDS_STARTED,
    GET_GIFT_CARDS_SUCCESS,
    GET_GIFT_CARDS_FAILURE,
    LOAD_MORE_GIFT_CARDS_STARTED,
    LOAD_MORE_GIFT_CARDS_SUCCESS,
    LOAD_MORE_GIFT_CARDS_FAILURE,
    UPDATE_GIFT_CARD_STARTED,
    UPDATE_GIFT_CARD_SUCCESS,
    UPDATE_GIFT_CARD_FAILURE,
    REMOVE_GIFT_CARD_STARTED,
    REMOVE_GIFT_CARD_SUCCESS,
    REMOVE_GIFT_CARD_FAILURE,
    SET_CARDS_FILTER,
    SET_CURRENT_CARD,
    SAVE_GIFT_CARD_FOR_CHECKOUT,
    UPDATE_GIFT_CARD_FOR_CHECKOUT,
    REMOVE_GIFT_CARD_FOR_CHECKOUT,
    GET_GIFT_CARDS_STARTED_FOR_PAYMENT,
    GET_GIFT_CARDS_SUCCESS_FOR_PAYMENT,
    GET_GIFT_CARDS_FAILURE_FOR_PAYMENT,
    UPDATE_GIFT_CARD_FOR_PAYMENT_FRONTEND,
    SET_CURRENT_CARD_FOR_PAYMENT,
    REMOVE_ERROR_MESSAGE,
    SEND_EMAIL_STARTED,
    SEND_EMAIL_SUCCESS,
    SEND_EMAIL_FAILURE
} from '../../constants/gift-card/giftCard'

const initialState = {
    filter: {
        searchField: "",
        status: ['activated', 'inactivated']
    },
    countCards: 0,
    cards: [],
    loading: false,
    error: null,
    message: null,
    currentCard: null,

    paymentCardsList: [],
    cardsForPayment: [],
    currentCardForPayment: null
};

export default function giftCard(state = initialState, action) {
    switch (action.type) {
        case CHECK_DUPLICATED_GIFT_CARD_NUMBER_STARTED:
            return {
                ...state,
                loading: true,
            }
        case CHECK_DUPLICATED_GIFT_CARD_NUMBER_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null
            }
        case CHECK_DUPLICATED_GIFT_CARD_NUMBER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        //create
        case CREATE_GIFT_CARD_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case CREATE_GIFT_CARD_SUCCESS:
            let { cards } = state
            let newCard = action.payload.card
            cards = [newCard, ...cards]
            return {
                ...state,
                loading: false,
                cards: cards,
                currentCard: newCard.cardNumber,
                message: 'Gift card has been created successfully!'
            };
        case CREATE_GIFT_CARD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };


        //get
        case GET_GIFT_CARDS_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case GET_GIFT_CARDS_SUCCESS:
            return {
                ...state,
                loading: false,
                cards: action.payload.cards,
                countCards: action.payload.countCards,
            };
        case GET_GIFT_CARDS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        //loadmore
        case LOAD_MORE_GIFT_CARDS_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case LOAD_MORE_GIFT_CARDS_SUCCESS:
            return {
                ...state,
                loading: false,
                cards: action.payload.cards,
                countCards: action.payload.countCards,
            };
        case LOAD_MORE_GIFT_CARDS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };

        //update
        case UPDATE_GIFT_CARD_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case UPDATE_GIFT_CARD_SUCCESS:
            let updatedCards = state.cards
            for (let i = 0; i < updatedCards.length; i++) {
                if (updatedCards[i].cardNumber === action.payload.card.cardNumber) {
                    updatedCards[i] = action.payload.card
                }
            }
            return {
                ...state,
                loading: false,
                cards: updatedCards,
                message: 'Gift card has been updated successfully!'
            };
        case UPDATE_GIFT_CARD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        //remove
        case REMOVE_GIFT_CARD_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case REMOVE_GIFT_CARD_SUCCESS:
            let removedCards = state.cards
            removedCards = removedCards.filter(card => card.cardNumber !== action.payload.card.cardNumber)
            return {
                ...state,
                loading: false,
                cards: removedCards,
                currentCard: null,
                message: 'Gift card has been removed successfully!'

            };
        case REMOVE_GIFT_CARD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        //set filter
        case SET_CARDS_FILTER:
            return {
                ...state,
                filter: action.payload.filter
            };
        //set current card
        case SET_CURRENT_CARD:
            return {
                ...state,
                currentCard: action.payload.cardNumber
            }
        //save for checkout
        case SAVE_GIFT_CARD_FOR_CHECKOUT:
            return {
                ...state,
                cards: [...state.cards, action.payload.card]
            }
        //update for checkout
        case UPDATE_GIFT_CARD_FOR_CHECKOUT:
            let updatedCardsForCheckout = state.cards
            for (let i = 0; i < updatedCardsForCheckout.length; i++) {
                if (updatedCardsForCheckout[i].cardNumber === action.payload.card.cardNumber) {
                    updatedCardsForCheckout[i] = action.payload.card
                }
            }
            return {
                ...state,
                cards: updatedCardsForCheckout,
            };
        //remove for checkout
        case REMOVE_GIFT_CARD_FOR_CHECKOUT:
            let removedCardsForCheckout = state.cards
            removedCardsForCheckout = removedCardsForCheckout.filter(card => card.cardNumber !== action.payload.card.cardNumber)
            return {
                ...state,
                cards: removedCardsForCheckout,
            };
        //get for payment
        case GET_GIFT_CARDS_STARTED_FOR_PAYMENT:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case GET_GIFT_CARDS_SUCCESS_FOR_PAYMENT:
            return {
                ...state,
                loading: false,
                paymentCardsList: action.payload.cards,
            };
        case GET_GIFT_CARDS_FAILURE_FOR_PAYMENT:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };

        //update for payment frontend
        case UPDATE_GIFT_CARD_FOR_PAYMENT_FRONTEND:
            return {
                ...state,
                loading: false,
                cardsForPayment: action.payload.cardsList,
            };
        //set current card for payment
        case SET_CURRENT_CARD_FOR_PAYMENT:
            return {
                ...state,
                currentCardForPayment: action.payload.cardNumber,
                paymentCardsList: action.payload.cardNumber === null ? [] : state.paymentCardsList
            }

        //send email
        case SEND_EMAIL_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };

        case SEND_EMAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                message: "Gift card has been sent!",
            }

        case SEND_EMAIL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        //remove error
        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
                message: null
            };
        default:
            return {
                ...state,
            };
    }
}