import {
    GET_BUSINESS_INFO_STARTED,
    GET_BUSINESS_INFO_SUCCESS,
    GET_BUSINESS_INFO_FAILURE,
    GET_BUSINESS_APPOINTMENT_SETTINGS_STARTED,
    GET_BUSINESS_APPOINTMENT_SETTINGS_SUCCESS,
    GET_BUSINESS_APPOINTMENT_SETTINGS_FAILURE,
    UPDATE_BUSINESS_APPOINTMENT_SETTINGS_STARTED,
    UPDATE_BUSINESS_APPOINTMENT_SETTINGS_SUCCESS,
    UPDATE_BUSINESS_APPOINTMENT_SETTINGS_FAILURE,
    REMOVE_ERROR_MESSAGE,
} from "../../constants/salon-account/businessInfo";

const initialState = {
    business: null,
    appointmentSettings: {
        name: "",
        urlName: "",
        address:"",
        isAutoConfirmed: true,
        notificationEmails:[]
    },
    loading: false,
    updateLoading: false,
    error: null,
    message: null,
    component: "",
};

export default function business(state = initialState, action) {
    switch (action.type) {
        case UPDATE_BUSINESS_APPOINTMENT_SETTINGS_STARTED:
            return  {
                ...state,
                updateLoading: true,
                error: null,
                message: null,
            };
        case UPDATE_BUSINESS_APPOINTMENT_SETTINGS_SUCCESS:
            return {
                ...state,
                updateLoading: false,
                appointmentSettings: action.payload.data,
                message:"Appointment Settings has been updated successfully."
            }
        case UPDATE_BUSINESS_APPOINTMENT_SETTINGS_FAILURE:
            return  {
                ...state,
                updateLoading: false,
                error: action.payload.error,
                message: null,
            };
        case GET_BUSINESS_INFO_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case GET_BUSINESS_INFO_SUCCESS:
            return {
                ...state,
                loading: false,
                business: action.payload.data,
            };
        case GET_BUSINESS_INFO_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
            };
        case GET_BUSINESS_APPOINTMENT_SETTINGS_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };

        case GET_BUSINESS_APPOINTMENT_SETTINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                appointmentSettings: action.payload.data,
            };

        case GET_BUSINESS_APPOINTMENT_SETTINGS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
            };

        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                error: null,
                message: null,
            };

        default:
            return {
                ...state,
            };
    }
}
