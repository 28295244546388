import React, { useEffect } from "react";
import { PageHeader } from '@ant-design/pro-layout';
import { Row, Col } from "antd";
import Notifications from "./Notifications";
import AccountSetting from "./AccountSetting";
import Locations from "./Locations"
import { withRouter } from "react-router";
import { useSelector } from "react-redux";
import API from "../../../utils/API/API";
const PageHeaderContainer = (props) => {
    const currentLocation = props.history.location.pathname;

    useEffect(() => {
        API.get("/api/business/get-country").then(res => {
            if (res.data && res.data.business) {
                const { business } = res.data || {}
                localStorage.setItem("business", JSON.stringify(business))
            }
        }).catch(error => {
            console.error("Failed to fetch country:", error);
        })
        API.get("/api/holiday/get-active").then(res => {
            if (res.data) {
                localStorage.setItem("holidaysAndSHours", JSON.stringify(res.data));
            }
        }).catch(error => {
            console.error("Failed to fetch holidays and special hours:", error);
        })

    }, [])

    const titles = [
        "Checked in",
        "Account",
        "Checked in",
        "Appointments",
        "Calendar",
        "Time Clock",
        "Settings",
        "Clients",
        "History",
        "Hours",
        "Menu",
        "Staffs",
        "Taxes",
        "Reports",
        "Gift cards",
        "Holidays",
        "Clover",
        "Referral",
        "Gift Card"

    ];
    const subtitles = [
        "",
        "Business Information",
        "",
        "",
        "",
        "Staff Clock in/out",
        "",
        "",
        "Appointments/Revenues/Reviews",
        "Setting business hours",
        "Manage services",
        "Staff management",
        "Taxes settings",
        "",
        "Gift cards management",
        "Manage days off",
        "Payment integration",
        "Refer other salons",
        ""
    ];
    let currentIndex = 3;
    titles.forEach((title, index) => {
        if (currentLocation.replace(/-/g, ' ').includes(title.toLowerCase())) {
            currentIndex = index;
        }
    });

    const { isMobile } = useSelector(state => state.responsive)
    return (
        <PageHeader
            title={titles[currentIndex]}
            subTitle={!isMobile && subtitles[currentIndex]}
            className="site-page-header-container"
            style={{ padding: "10px", }}
            // tags={<Row style={{ width: "200px" }}>
            //     <Locations key="Locations" history={props.history} />
            // </Row>}
            extra={
                <div>
                    <Row justify="end" align="middle" style={{ flexWrap: "nowrap" }}>
                        <Col>
                            <Locations key="Locations" history={props.history} />
                        </Col>
                        <Col>
                            <Notifications key="Notification" history={props.history} />
                        </Col>
                        <Col>
                            <AccountSetting key="AccountSettings" history={props.history} />
                        </Col>
                    </Row>
                </div>
            }
        />
    );
};

export default withRouter(PageHeaderContainer);
