/**
 * Turn a encoded string to html
 * @param {*} stringValue
 */
//  function(stringValue) {
//   const strToDecode = stringValue;
//   const parser = new DOMParser();
//   const decodedValue = parser.parseFromString(
//     `<!doctype html><body>${strToDecode}`,
//     "text/html"
//   ).body.textContent;
//   return decodedValue;
// }

export default function decodeHtmlEntities(str) {
  const htmlEntities = {
    '&amp;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&#x27;': "'",
    '&#x2F;': '/',
  };

  return str.replace(/&amp;|&lt;|&gt;|&quot;|&#x27;|&#x2F;/g, (match) => htmlEntities[match]);
}
