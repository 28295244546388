import {
    GET_UPCOMMING_APPOITMENTS_START,
    GET_UPCOMMING_APPOITMENTS_SUCCESS,
    GET_UPCOMMING_APPOITMENTS_FAILURE,

    REMOVE_ERROR_MESSAGE
} from "../../constants/upcomming-appointments/upcommingAppointments";

const initialState = {
    upcommingAppointments: [],
    loading: false,
    error: null,
    message: null,
};

export default function upcommingAppointments(state = initialState, action) {

    switch (action.type) {
        case GET_UPCOMMING_APPOITMENTS_START:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };

        case GET_UPCOMMING_APPOITMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                upcommingAppointments: action.payload.data,
                error: null,
                message: null,
            };

        case GET_UPCOMMING_APPOITMENTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
            };

        case REMOVE_ERROR_MESSAGE: {
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
            };
        }
        default:
            return {
                ...state,
            };
    }
}
