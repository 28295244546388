export const GET_MENU_STARTED = "GET_MENU_STARTED"
export const GET_MENU_SUCCESS = "GET_MENU_SUCCESS"
export const GET_MENU_FAILURE = "GET_MENU_FAILURE"

export const CREATE_CATEGORY_STARTED = "CREATE_CATEGORY_STARTED"
export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS"
export const CREATE_CATEGORY_FAILURE = "CREATE_CATEGORY_FAILURE"

export const UPDATE_CATEGORY_STARTED = "UPDATE_CATEGORY_STARTED"
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS"
export const UPDATE_CATEGORY_FAILURE = "UPDATE_CATEGORY_FAILURE"

export const DELETE_CATEGORY_STARTED = "DELETE_CATEGORY_STARTED"
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS"
export const DELETE_CATEGORY_FAILURE = "DELETE_CATEGORY_FAILURE"

export const UPDATE_CATEGORY_ORDER_STARTED = "UPDATE_CATEGORY_ORDER_STARTED"
export const UPDATE_CATEGORY_ORDER_SUCCESS = "UPDATE_CATEGORY_ORDER_SUCCESS"
export const UPDATE_CATEGORY_ORDER_FAILURE = "UPDATE_CATEGORY_ORDER_FAILURE"

export const UPDATE_SERVICE_ORDER_STARTED = "UPDATE_SERVICE_ORDER_STARTED"
export const UPDATE_SERVICE_ORDER_SUCCESS = "UPDATE_SERVICE_ORDER_SUCCESS"
export const UPDATE_SERVICE_ORDER_FAILURE = "UPDATE_SERVICE_ORDER_FAILURE"


export const GET_SERVICE_DETAIL_STARTED = "GET_SERVICE_DETAIL_STARTED"
export const GET_SERVICE_DETAIL_SUCCESS = "GET_SERVICE_DETAIL_SUCCESS"
export const GET_SERVICE_DETAIL_FAILURE = "GET_SERVICE_DETAIL_FAILURE"

export const UPDATE_SERVICE_DETAIL_STARTED = "UPDATE_SERVICE_DETAIL_STARTED"
export const UPDATE_SERVICE_DETAIL_SUCCESS = "UPDATE_SERVICE_DETAIL_SUCCESS"
export const UPDATE_SERVICE_DETAIL_FAILURE = "UPDATE_SERVICE_DETAIL_FAILURE"

export const CREATE_SERVICE_STARTED = "CREATE_SERVICE_STARTED"
export const CREATE_SERVICE_SUCCESS = "CREATE_SERVICE_SUCCESS"
export const CREATE_SERVICE_FAILURE = "CREATE_SERVICE_FAILURE"

export const DELETE_SERVICE_STARTED = "DELETE_SERVICE_STARTED"
export const DELETE_SERVICE_SUCCESS = "DELETE_SERVICE_SUCCESS"
export const DELETE_SERVICE_FAILURE = "DELETE_SERVICE_FAILURE"

export const CREATE_COMBO_STARTED = "CREATE_COMBO_STARTED"
export const CREATE_COMBO_SUCCESS = "CREATE_COMBO_SUCCESS"
export const CREATE_COMBO_FAILURE = "CREATE_COMBO_FAILURE"

export const UPDATE_COMBO_STARTED = "UPDATE_COMBO_STARTED"
export const UPDATE_COMBO_SUCCESS = "UPDATE_COMBO_SUCCESS"
export const UPDATE_COMBO_FAILURE = "UPDATE_COMBO_FAILURE"

export const DELETE_COMBO_STARTED = "DELETE_COMBO_STARTED"
export const DELETE_COMBO_SUCCESS = "DELETE_COMBO_SUCCESS"
export const DELETE_COMBO_FAILURE = "DELETE_COMBO_FAILURE"

export const GET_COMBO_DETAIL_STARTED = "GET_COMBO_DETAIL_STARTED"
export const GET_COMBO_DETAIL_SUCCESS = "GET_COMBO_DETAIL_SUCCESS"
export const GET_COMBO_DETAIL_FAILURE = "GET_COMBO_DETAIL_FAILURE"

export const CLEAR_SERVICE_STARTED = "CLEAR_SERVICE_STARTED"

export const UPDATE_ALL_SERVICES = "UPDATE_ALL_SERVICES"
export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE"