export const GET_NOTIFICATIONS_COUNT_STARTED = "GET_NOTIFICATIONS_COUNT_STARTED"
export const GET_NOTIFICATIONS_COUNT_SUCCESS = "GET_NOTIFICATIONS_COUNT_SUCCESS"
export const GET_NOTIFICATIONS_COUNT_FAILURE = "GET_NOTIFICATIONS_COUNT_FAILURE"

export const GET_NOTIFICATIONS_STARTED = "GET_NOTIFICATIONS_STARTED"
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS"
export const GET_NOTIFICATIONS_FAILURE = "GET_NOTIFICATIONS_FAILURE"

export const LOAD_MORE_NOTIFICATIONS_STARTED = "LOAD_MORE_NOTIFICATIONS_STARTED"
export const LOAD_MORE_NOTIFICATIONS_SUCCESS = "LOAD_MORE_NOTIFICATIONS_SUCCESS"
export const LOAD_MORE_NOTIFICATIONS_FAILURE = "LOAD_MORE_NOTIFICATIONS_FAILURE"

export const UPDATE_NOTIFICATION_STATUS_STARTED = "UPDATE_NOTIFICATION_STATUS_STARTED"
export const UPDATE_NOTIFICATION_STATUS_SUCCESS = "UPDATE_NOTIFICATION_STATUS_SUCCESS"
export const UPDATE_NOTIFICATION_STATUS_FAILURE = "UPDATE_NOTIFICATION_STATUS_FAILURE"

export const CLEAR_NOTIFICATIONS_STARTED = "CLEAR_NOTIFICATIONS_STARTED"
export const CLEAR_NOTIFICATIONS_SUCCESS = "CLEAR_NOTIFICATIONS_SUCCESS"
export const CLEAR_NOTIFICATIONS_FAILURE = "CLEAR_NOTIFICATIONS_FAILURE"

export const MARK_NOTIFICATIONS_AS_READ_STARTED = "MARK_NOTIFICATIONS_AS_READ_STARTED"
export const MARK_NOTIFICATIONS_AS_READ_SUCCESS = "MARK_NOTIFICATIONS_AS_READ_SUCCESS"
export const MARK_NOTIFICATIONS_AS_READ_FAILURE = "MARK_NOTIFICATIONS_AS_READ_FAILURE"

export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE"