import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import { Result, Button } from "antd";
import { Switch, Route } from "react-router-dom";
import Loading from "../../component/common/Loading/Loading";


class ContentContainer extends Component {

    state = {
        notAllowedAccess: []
    }

    componentDidMount() {
        const accountData = JSON.parse(localStorage.getItem('accountData'));
        if (accountData?.notAllowedAccess?.length > 0) {
            const notAllowedAccess = accountData.notAllowedAccess;
            this.setState({ notAllowedAccess });
        }
    }

    getComponent = (componentName) => {
        const { isMobile } = this.props;
        return React.lazy(() => import(`../../${isMobile ? "pageMobile" : "page"}/${componentName}`));
    };
    render() {
        // const { isMobile } = this.props
        // const Profile = React.lazy(() => import(`../../${isMobile ? "pageMobile" : "page"}/Account/Profile`));
        // const CheckedIn = React.lazy(() => import(`../../${isMobile ? "pageMobile" : "page"}/CheckedIn/CheckedIn`));
        // const ClientRoutes = React.lazy(() => import(`../../${isMobile ? "pageMobile" : "page"}/Client/ClientRoutes`));
        // const AppointmentRoutes = React.lazy(() => import(`../../${isMobile ? "pageMobile" : "page"}/Appointment/AppointmentRoutes`));
        // const UpcomingAppointments = React.lazy(() => import(`../../${isMobile ? "pageMobile" : "page"}/UpcomingAppointments/UpcomingAppointments`));
        // const PinAuth = React.lazy(() => import(`../../${isMobile ? "pageMobile" : "page"}/PinAuth/PinAuth`));
        // const TimeClock = React.lazy(() => import(`../../${isMobile ? "pageMobile" : "page"}/TimeClock/TimeClock`));
        // const History = React.lazy(() => import(`../../${isMobile ? "pageMobile" : "page"}/History/History`));
        // const Sales = React.lazy(() => import(`../../${isMobile ? "pageMobile" : "page"}/Report/Sales`));
        // const Billing = React.lazy(() => import(`../../${isMobile ? "pageMobile" : "page"}/Report/Billing`));
        // // const Referral = React.lazy(() => import(`../../${isMobile ? "pageMobile" : "page"}/Referral/Referral`));
        // const Invoice = React.lazy(() => import(`../../${isMobile ? "pageMobile" : "page"}/Invoice/Invoice`));
        // const Checkout = React.lazy(() => import(`../../${isMobile ? "pageMobile" : "page"}/Checkout/Checkout`));
        // const SettingRoutes = React.lazy(() => import(`../../${isMobile ? "pageMobile" : "page"}/Settings/SettingRoutes`));
        // const GiftCardHistory = React.lazy(() => import(`../../${isMobile ? "pageMobile" : "page"}/GiftCardHistory/GiftCardHistory`));
        // const EditInvoice = React.lazy(() => import(`../../${isMobile ? "pageMobile" : "page"}/Invoice/EditInvoice`));
        // const ClientsStats = React.lazy(() => import(`../../${isMobile ? "pageMobile" : "page"}/Report/ClientsStats`))


        const routes = [
            { path: '/', exact: true, component: this.getComponent('Appointment/AppointmentRoutes'), key: 'appointments' },
            { path: '/checked-in', exact: true, component: this.getComponent('CheckedIn/CheckedIn'), key: 'checked-in' },
            { path: '/check-out', exact: true, component: this.getComponent('Checkout/Checkout'), key: 'check-out' },
            { path: '/appointments', component: this.getComponent('Appointment/AppointmentRoutes'), key: 'appointments' },
            { path: '/new-appointments', component: this.getComponent('Appointment/AppointmentRoutes'), key: 'appointments' },
            { path: '/calendar', component: this.getComponent('UpcomingAppointments/UpcomingAppointments'), key: 'calendar' },
            { path: '/time-clock', component: this.getComponent('TimeClock/TimeClock'), key: 'time-clock' },
            { path: '/clients', component: this.getComponent('Client/ClientRoutes'), key: 'clients' },
            { path: '/history', exact: true, component: this.getComponent('History/History'), key: 'history' },
            { path: '/gift-card', exact: true, component: this.getComponent('GiftCardHistory/GiftCardHistory'), key: 'gift-card' },
            { path: '/settings', component: this.getComponent('Settings/SettingRoutes'), key: 'settings' },
            { path: '/reports/billings', component: this.getComponent('Report/Billing'), key: 'reports' },
            { path: '/reports/statistics', component: this.getComponent('Report/Sales'), key: 'reports' },
            { path: '/reports/billing', component: this.getComponent('Report/Billing'), key: 'reports' },
            { path: '/reports/client-stats', component: this.getComponent('Report/ClientsStats'), key: 'reports' },
            { path: '/invoices', component: this.getComponent('Invoice/Invoice'), key: 'invoices' },
            { path: '/edit-invoice', component: this.getComponent('Invoice/EditInvoice'), key: "invoices" },
            { path: '/account', exact: true, component: this.getComponent('Account/Profile'), key: 'account' },
            { path: '/pin-auth', exact: true, component: this.getComponent('PinAuth/PinAuth'), key: 'pin-auth' },
            {
                path: '/*',
                component: (props) => (
                    <Result
                        status='404'
                        title='404'
                        subTitle='Sorry, the page you visited does not exist.'
                        extra={
                            <Button
                                type='primary'
                                className='animated bounceInLeft'
                                onClick={() => props.history.goBack()}
                                shape='round'
                            >
                                Back Home
                            </Button>
                        }
                    />
                )
            }
        ];
        return (

            <Suspense fallback={<Loading />}>
                <Switch>
                    {routes.filter(route => !this.state.notAllowedAccess.includes(route.key)).map((route, index) => (
                        <Route
                            key={index}
                            path={route.path}
                            exact={route.exact}
                            render={props => <route.component {...props} />}
                        />
                    ))}
                </Switch>
            </Suspense>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        isMobile: state.responsive.isMobile,
    };
};

function mapDispatchToProps(dispatch) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentContainer);