import React, { Component } from "react";
//import "./App.css";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import MenuContainer from "./layouts/Navigation/MenuContainer";
// import "antd/dist/antd.css";
import { createBrowserHistory } from "history";
import Login from "./page/Login/Login";
import MobileLogin from "./pageMobile/Login/Login"
import MobileResetPassword from "./pageMobile/ForgotPassword/ResetPassword";
import { connect } from "react-redux";
import { logout } from "./redux/actions/salon-account/authenticate";
import ResetPassword from "./page/ForgotPassword/ResetPassword";
// import AccountActivation from "./page/AccountActivation/AccountActivation";
import { ActivationModal } from "./page/AccountActivation/ActivationModal";
import { MobileActivationModal } from "./pageMobile/AccountActivation/ActivationModal";
import './index.css';
const mapStateToProps = (state) => {
    return {
        isMobile: state.responsive.isMobile,
        user: state.authenticate.user,
        isRequiredActivation: state.authenticate.isRequiredActivation,
    };
};

function mapDispatchToProps(dispatch) {
    return {
        logout: () => dispatch(logout()),
    };
}
const history = createBrowserHistory();

class App extends Component {

    componentDidMount() {
        const { isMobile } = this.props
        if (isMobile) {
            import('./Mobile.css')
        } else {
            import('./Desktop.css')
        }

    }

    render() {
        const { isMobile } = this.props


        // const Login = React.lazy(() => import(`../../${isMobile ? "pageMobile" : "page"}/Login/Login`));
        const loggedIn = this.props.user.authenticated;
        const isRequiredActivation = this.props.isRequiredActivation;
        if (isRequiredActivation) {
            return (
                <Router>
                    <Switch>
                        <Route path="/*" exact component={isMobile ? MobileActivationModal : ActivationModal}>
                            {/* <ActivationModal history={history} /> */}
                            {/* <AccountActivation history={history} /> */}
                        </Route>
                    </Switch>
                </Router>
            );
        }

        //unauthenticated routes
        if (!loggedIn) {
            return (
                <Router>
                    <Switch>
                        <Route path="/reset-password" exact component={isMobile ? MobileResetPassword : ResetPassword} />

                        <Route path="/*" component={isMobile ? MobileLogin : Login} />
                    </Switch>
                </Router>
            );
        }

        return (
            <div className="body" style={{ height: "100%" }}>
                <MenuContainer history={history} />
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
