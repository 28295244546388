export const GET_HISTORY_APPOINTMENTS_STARTED = "GET_HISTORY_APPOINTMENTS_STARTED"
export const GET_HISTORY_APPOINTMENTS_SUCCESS = "GET_HISTORY_APPOINTMENTS_SUCCESS"
export const GET_HISTORY_APPOINTMENTS_FAILURE = "GET_HISTORY_APPOINTMENTS_FAILURE"

export const GET_HISTORY_APPOINTMENTS_LIST_STARTED = "GET_HISTORY_APPOINTMENTS_LIST_STARTED"
export const GET_HISTORY_APPOINTMENTS_LIST_SUCCESS = "GET_HISTORY_APPOINTMENTS_LIST_SUCCESS"
export const GET_HISTORY_APPOINTMENTS_LIST_FAILURE = "GET_HISTORY_APPOINTMENTS_LIST_FAILURE"

export const GET_HISTORY_REVENUES_STARTED = "GET_HISTORY_REVENUES_STARTED"
export const GET_HISTORY_REVENUES_SUCCESS = "GET_HISTORY_REVENUES_SUCCESS"
export const GET_HISTORY_REVENUES_FAILURE = "GET_HISTORY_REVENUES_FAILURE"

export const GET_HISTORY_GIFTCARDS_STARTED = "GET_HISTORY_GIFTCARDS_STARTED"
export const GET_HISTORY_GIFTCARDS_SUCCESS = "GET_HISTORY_GIFTCARDS_SUCCESS"
export const GET_HISTORY_GIFTCARDS_FAILURE = "GET_HISTORY_GIFTCARDS_FAILURE"


export const GET_HISTORY_SALES_STARTED = "GET_HISTORY_SALES_STARTED"
export const GET_HISTORY_SALES_SUCCESS = "GET_HISTORY_SALES_SUCCESS"
export const GET_HISTORY_SALES_FAILURE = "GET_HISTORY_SALES_FAILURE"

export const GET_HISTORY_PAYMENTS_STARTED = "GET_HISTORY_PAYMENTS_STARTED"
export const GET_HISTORY_PAYMENTS_SUCCESS = "GET_HISTORY_PAYMENTS_SUCCESS"
export const GET_HISTORY_PAYMENTS_FAILURE = "GET_HISTORY_PAYMENTS_FAILURE"

export const GET_HISTORY_REVIEWS_STARTED = "GET_HISTORY_REVIEWS_STARTED"
export const GET_HISTORY_REVIEWS_SUCCESS = "GET_HISTORY_REVIEWS_SUCCESS"
export const GET_HISTORY_REVIEWS_FAILURE = "GET_HISTORY_REVIEWS_FAILURE"

export const REPLY_REVIEW_HISTORY_STARTED = "REPLY_REVIEW_HISTORY_STARTED"
export const REPLY_REVIEW_HISTORY_SUCCESS = "REPLY_REVIEW_HISTORY_SUCCESS"
export const REPLY_REVIEW_HISTORY_FAILURE = "REPLY_REVIEW_HISTORY_FAILURE"

export const VOID_INVOICE_STARTED = "VOID_INVOICE_STARTED"
export const VOID_INVOICE_SUCCESS = "VOID_INVOICE_SUCCESS"
export const VOID_INVOICE_FAILURE = "VOID_INVOICE_FAILURE"

export const GET_HISTORY_SALES_BY_STAFF_STARTED = "GET_HISTORY_SALES_BY_STAFF_STARTED"
export const GET_HISTORY_SALES_BY_STAFF_SUCCESS = "GET_HISTORY_SALES_BY_STAFF_SUCCESS"
export const GET_HISTORY_SALES_BY_STAFF_FAILURE = "GET_HISTORY_SALES_BY_STAFF_FAILURE"

export const GET_HISTORY_SALES_BY_MENU_STARTED = "GET_HISTORY_SALES_BY_MENU_STARTED"
export const GET_HISTORY_SALES_BY_MENU_SUCCESS = "GET_HISTORY_SALES_BY_MENU_SUCCESS"
export const GET_HISTORY_SALES_BY_MENU_FAILURE = "GET_HISTORY_SALES_BY_MENU_FAILURE"

export const GET_HISTORY_SALES_BY_DATE_STARTED = "GET_HISTORY_SALES_BY_DATE_STARTED"
export const GET_HISTORY_SALES_BY_DATE_SUCCESS = "GET_HISTORY_SALES_BY_DATE_SUCCESS"
export const GET_HISTORY_SALES_BY_DATE_FAILURE = "GET_HISTORY_SALES_BY_DATE_FAILURE"

export const GET_HISTORY_SALES_BY_PAYMENT_STARTED = "GET_HISTORY_SALES_BY_PAYMENT_STARTED"
export const GET_HISTORY_SALES_BY_PAYMENT_SUCCESS = "GET_HISTORY_SALES_BY_PAYMENT_SUCCESS"
export const GET_HISTORY_SALES_BY_PAYMENT_FAILURE = "GET_HISTORY_SALES_BY_PAYMENT_FAILURE"

export const GET_HISTORY_DISCOUNTS_STARTED = "GET_HISTORY_DISCOUNTS_STARTED"
export const GET_HISTORY_DISCOUNTS_SUCCESS = "GET_HISTORY_DISCOUNTS_SUCCESS"
export const GET_HISTORY_DISCOUNTS_FAILURE = "GET_HISTORY_DISCOUNTS_FAILURE"

export const GET_HISTORY_TIPS_STARTED = "GET_HISTORY_TIPS_STARTED"
export const GET_HISTORY_TIPS_SUCCESS = "GET_HISTORY_TIPS_SUCCESS"
export const GET_HISTORY_TIPS_FAILURE = "GET_HISTORY_TIPS_FAILURE"

export const GET_HISTORY_TAXES_STARTED = "GET_HISTORY_TAXES_STARTED"
export const GET_HISTORY_TAXES_SUCCESS = "GET_HISTORY_TAXES_SUCCESS"
export const GET_HISTORY_TAXES_FAILURE = "GET_HISTORY_TAXES_FAILURE"

export const GET_HISTORY_INVOICES_STARTED = "GET_HISTORY_INVOICES_STARTED"
export const GET_HISTORY_INVOICES_SUCCESS = "GET_HISTORY_INVOICES_SUCCESS"
export const GET_HISTORY_INVOICES_FAILURE = "GET_HISTORY_INVOICES_FAILURE"

export const GET_HISTORY_APPOINTMENTS_BY_TYPE_STARTED = "GET_HISTORY_APPOINTMENTS_BY_TYPE_STARTED"
export const GET_HISTORY_APPOINTMENTS_BY_TYPE_SUCCESS = "GET_HISTORY_APPOINTMENTS_BY_TYPE_SUCCESS"
export const GET_HISTORY_APPOINTMENTS_BY_TYPE_FAILURE = "GET_HISTORY_APPOINTMENTS_BY_TYPE_FAILURE"

export const GET_HISTORY_APPOINTMENTS_BY_STATUS_STARTED = "GET_HISTORY_APPOINTMENTS_BY_STATUS_STARTED"
export const GET_HISTORY_APPOINTMENTS_BY_STATUS_SUCCESS = "GET_HISTORY_APPOINTMENTS_BY_STATUS_SUCCESS"
export const GET_HISTORY_APPOINTMENTS_BY_STATUS_FAILURE = "GET_HISTORY_APPOINTMENTS_BY_STATUS_FAILURE"

export const GET_HISTORY_APPOINTMENTS_BY_DATE_STARTED = "GET_HISTORY_APPOINTMENTS_BY_DATE_STARTED"
export const GET_HISTORY_APPOINTMENTS_BY_DATE_SUCCESS = "GET_HISTORY_APPOINTMENTS_BY_DATE_SUCCESS"
export const GET_HISTORY_APPOINTMENTS_BY_DATE_FAILURE = "GET_HISTORY_APPOINTMENTS_BY_DATE_FAILURE"

export const DISCARD_INVOICE_STARTED = "DISCARD_INVOICE_STARTED"
export const DISCARD_INVOICE_SUCCESS = "DISCARD_INVOICE_SUCCESS"
export const DISCARD_INVOICE_FAILURE = "DISCARD_INVOICE_FAILURE"

export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE"