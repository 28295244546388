import React, { Component } from 'react'
import { connect } from 'react-redux'
import { UserOutlined } from "@ant-design/icons"
import { Row, Col, Button, Input, Form, Checkbox } from "antd";
import dayjs from "dayjs";
import { createPaymentMethod } from "../../redux/actions/billing/billing";
import { cancelActivation } from "../../redux/actions/salon-account/authenticate"
import { NewMessageError } from '../../component/common/MessageError/NewMessageError';
import Loading from "../../component/common/Loading/Loading"
// import {DashBookingHorizontalLogo} from "../../layouts/Navigation/DashBookingLogo"
// const { Title } = Typography;
// const { Option } = Select;
class AccountActivation extends Component {

    state = {
        disableSubmitButton: true
    }

    formRef = React.createRef();
    autofucustRef = React.createRef();

    handleSubmit = (value) => {
        this.props.createPaymentMethod(value);
    };

    componentDidUpdate(prevProps) {
        if (prevProps.error !== this.props.error || prevProps.message !== this.props.message) {
            NewMessageError(this.props.message, this.props.error)
        }
        if (prevProps.message !== this.props.message) {
            window.location.reload(false)
        }
    }


    cardNumberFormat(value) {
        const regex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g;
        const onlyNumbers = value.replace(/[^\d]/g, "");

        return onlyNumbers.replace(regex, (regex, $1, $2, $3, $4) =>
            [$1, $2, $3, $4].filter((group) => !!group).join(" ")
        );
    }
    handleChange = (value, fieldname) => {
        if (fieldname === "number") {
            value = this.cardNumberFormat(value);
        }

        this.formRef.current.setFieldsValue({
            [fieldname]: value,
        });
    };

    disableSubmit = () => {
        let formValues = this.formRef.current.getFieldsValue()
        if (formValues.name && formValues.number && formValues.expMonth && formValues.expYear && formValues.cvc && formValues.agreeToTerm) {
            return false
        }
        return true
    }

    cancelActivation = () => {
        this.props.cancelActivation()
    }

    render() {
        const checkExpCard = (month, year) => {
            const yearReg = /^(202[1-9]|20[3-5][0-9])$/;
            const monthReg = /^(0?[1-9]|1[012])$/;
            if (month && month.match(monthReg) && year && year.match(yearReg)) {
                const cardExp = dayjs(month + " " + year, "MM YYYY");
                if (dayjs().isAfter(cardExp, "month")) {
                    return true;
                }
            }

            return false;
        };

        const { loading } = this.props

        if (loading) {
            return <Loading />
        }
        return (
            <div
                style={{
                    position: "relative",
                    width: "100% "
                }}
            >
                <Row
                    justify='center'
                >
                    <Col span={23}>
                        <h3 style={{ fontWeight: "600" }}>Enter a credit card to activate your account</h3>
                    </Col>
                    <Col span={23}>
                        <Form
                            onFieldsChange={() => {
                                this.setState({
                                    disableSubmitButton: this.disableSubmit()
                                })
                            }}
                            id="credit-card-form"
                            layout="vertical"
                            onFinish={this.handleSubmit}
                            size="large"
                            requiredMark={true}
                            ref={this.formRef}
                        >
                            <Form.Item
                                label="Name on card"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                style={{ marginBottom: "0px" }}
                            >
                                <Input ref={this.autofucustRef} autoFocus prefix={<UserOutlined />} />
                            </Form.Item>

                            <Form.Item
                                label="Card Number"
                                name="number"
                                required
                                style={{ marginBottom: "0px" }}
                            >
                                <Input
                                    prefix={<img width="30px" src="/assets/images/credit-card-svgrepo-com.svg" alt="Visa Master Card" />}
                                    placeholder="0000 0000 0000 0000"
                                    onChange={(e) =>
                                        this.handleChange(e.target.value, "number")
                                    }
                                />
                            </Form.Item>
                            <Form.Item
                                label="Expiry Month"
                                required
                                name="expMonth"
                                style={{ marginBottom: "0px" }}
                                rules={[
                                    () => ({
                                        validator(_, value) {
                                            if (value) {
                                                const monthRegex =
                                                    /^(0?[1-9]|1[012])$/;
                                                if (
                                                    value.match(monthRegex)
                                                ) {
                                                    return Promise.resolve();
                                                }
                                            }

                                            return Promise.reject(
                                                new Error(
                                                    "Please enter a valid month!"
                                                )
                                            );
                                        },
                                    }),
                                ]}
                            >
                                <Input placeholder="MM" />
                            </Form.Item>
                            <Form.Item
                                label="Expiry Year"
                                name="expYear"
                                dependencies={["expMonth"]}
                                required
                                style={{ marginBottom: "0px" }}
                                rules={[
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (value) {
                                                const yearReg =
                                                    /^(202[1-9]|20[3-5][0-9])$/;

                                                const month =
                                                    getFieldValue(
                                                        "expMonth"
                                                    );
                                                if (
                                                    checkExpCard(
                                                        month,
                                                        value
                                                    )
                                                ) {
                                                    return Promise.reject(
                                                        new Error(
                                                            "Please enter a valid year!"
                                                        )
                                                    );
                                                }
                                                if (value.match(yearReg)) {
                                                    return Promise.resolve();
                                                }
                                            }

                                            return Promise.reject(
                                                new Error(
                                                    "Please enter a valid year!"
                                                )
                                            );
                                        },
                                    }),
                                ]}
                            >
                                {/* <Select size="large" placeholder="Select year" listHeight="100%">
                                               {yearOptions.map(year => {
                                                   return    <Option key={year} value={year}  style={{lineHeight:"30px", fontSize:"20px"}}>{year}</Option>
                                               })}
                                                
                                              
                                            </Select> */}

                                <Input placeholder="YYYY" />
                            </Form.Item>
                            <Form.Item
                                label="CVC code"
                                name="cvc"
                                required
                                style={{ marginBottom: "0px" }}
                                rules={[
                                    () => ({
                                        validator(_, value) {
                                            if (value) {
                                                const cvcRex =
                                                    /^[0-9]{3,4}$/;
                                                if (value.match(cvcRex)) {
                                                    return Promise.resolve();
                                                }
                                            }

                                            return Promise.reject(
                                                new Error(
                                                    "Please enter a valid cvc!"
                                                )
                                            );
                                        },
                                    }),
                                ]}
                            >
                                <Input placeholder="123" prefix={<img width="30px" src="/assets/images/card-svgrepo-com.svg" alt="CVC Code" />} />
                            </Form.Item>
                            {/* <Row justify="space-between"   style={{ marginBottom: "10px" }}>
                                    <Col>
                                        <Form.Item
                                            label="Expiry Month"
                                            required
                                            name="expMonth"
                                            style={{ marginBottom: "0px" }}
                                            rules={[
                                                () => ({
                                                    validator(_, value) {
                                                        if (value) {
                                                            const monthRegex =
                                                                /^(0?[1-9]|1[012])$/;
                                                            if (
                                                                value.match(monthRegex)
                                                            ) {
                                                                return Promise.resolve();
                                                            }
                                                        }

                                                        return Promise.reject(
                                                            new Error(
                                                                "Please enter a valid month!"
                                                            )
                                                        );
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input placeholder="MM" />
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item
                                            label="Expiry Year"
                                            name="expYear"
                                            dependencies={["expMonth"]}
                                            required
                                            style={{ marginBottom: "0px" }}
                                            rules={[
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (value) {
                                                            const yearReg =
                                                                /^(202[1-9]|20[3-5][0-9])$/;

                                                            const month =
                                                                getFieldValue(
                                                                    "expMonth"
                                                                );
                                                            if (
                                                                checkExpCard(
                                                                    month,
                                                                    value
                                                                )
                                                            ) {
                                                                return Promise.reject(
                                                                    new Error(
                                                                        "Please enter a valid year!"
                                                                    )
                                                                );
                                                            }
                                                            if (value.match(yearReg)) {
                                                                return Promise.resolve();
                                                            }
                                                        }

                                                        return Promise.reject(
                                                            new Error(
                                                                "Please enter a valid year!"
                                                            )
                                                        );
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input placeholder="YYYY" />
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item
                                            label="CVC code"
                                            name="cvc"
                                            required
                                            style={{ marginBottom: "0px" }}
                                            rules={[
                                                () => ({
                                                    validator(_, value) {
                                                        if (value) {
                                                            const cvcRex =
                                                                /^[0-9]{3,4}$/;
                                                            if (value.match(cvcRex)) {
                                                                return Promise.resolve();
                                                            }
                                                        }

                                                        return Promise.reject(
                                                            new Error(
                                                                "Please enter a valid cvc!"
                                                            )
                                                        );
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input placeholder="123" />
                                        </Form.Item>
                                    </Col>
                                </Row> */}

                            <Form.Item
                                style={{ marginTop: "20px" }}
                                name="agreeToTerm"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please check the Terms and the Policy checkbox!"
                                    },
                                ]}
                                valuePropName="checked"
                            >
                                <Checkbox>
                                    <div>
                                        I agree to the
                                        <a href="https://www.dashbooking.com/business/partners-terms-and-conditions" rel="noreferrer" target="_blank"> Terms of services and Privacy policy</a>
                                    </div>
                                </Checkbox>
                            </Form.Item>
                            <Form.Item >
                                <Button disabled={this.state.disableSubmitButton} loading={this.props.loading} htmlType="submit" type="primary" shape="round" block >Submit</Button>
                                <Button block type="link" style={{ marginTop: "20px" }} onClick={() => this.cancelActivation()} >Cancel</Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </div>
            // </div >
        )
    }
}

const mapStateToProps = (state) => ({
    loading: state.billing.loading,
    error: state.billing.error,
    message: state.billing.message
});

const mapDispatchToProps = (dispatch) => {
    return {
        createPaymentMethod: (card) => dispatch(createPaymentMethod(card)),
        cancelActivation: () => dispatch(cancelActivation())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountActivation)
