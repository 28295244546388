export const GET_CLOVER_STATUS = "GET_CLOVER_STATUS"
export const GET_CLOVER_MESSAGE = "GET_CLOVER_MESSAGE"

export const GET_CLOVER_SETTING_STARTED = "GET_CLOVER_SETTING_STARTED"
export const GET_CLOVER_SETTING_SUCCESS = "GET_CLOVER_SETTING_SUCCESS"
export const GET_CLOVER_SETTING_FAILURE = "GET_CLOVER_SETTING_FAILURE"

export const UPDATE_CLOVER_SETTING_STARTED = "UPDATE_CLOVER_SETTING_STARTED"
export const UPDATE_CLOVER_SETTING_SUCCESS = "UPDATE_CLOVER_SETTING_SUCCESS"
export const UPDATE_CLOVER_SETTING_FAILURE = "UPDATE_CLOVER_SETTING_FAILURE"


export const UPDATE_CLOVER_AUTH_TOKEN_STARTED = "UPDATE_CLOVER_AUTH_TOKEN_STARTED"
export const UPDATE_CLOVER_AUTH_TOKEN_SUCCESS = "UPDATE_CLOVER_AUTH_TOKEN_SUCCESS"
export const UPDATE_CLOVER_AUTH_TOKEN_FAILURE = "UPDATE_CLOVER_AUTH_TOKEN_FAILURE"

export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE"
