import {
    GET_BUSINESS_HOUR_STARTED,
    GET_BUSINESS_HOUR_SUCCESS,
    GET_BUSINESS_HOUR_FAILURE,
    UPDATE_BUSINESS_HOUR_STARTED,
    UPDATE_BUSINESS_HOUR_SUCCESS,
    UPDATE_BUSINESS_HOUR_FAILURE,
    REMOVE_ERROR_MESSAGE,
} from "../../constants/business-hour/business-hour";


const getBusinessHourFromLocalStorage = () => {
    const localData = localStorage.getItem("businessHour");
    let businessHour = { 0: { isOpen: false, shifts: [{ open: "", close: "" }] },
                        1: { isOpen: false, shifts: [{ open: "", close: "" }] },
                        2: { isOpen: false, shifts: [{ open: "", close: "" }] },
                        3: { isOpen: false, shifts: [{ open: "", close: "" }] },
                        4: { isOpen: false, shifts: [{ open: "", close: "" }] },
                        5: { isOpen: false, shifts: [{ open: "", close: "" }] },
                        6: { isOpen: false, shifts: [{ open: "", close: "" }] }}
    if(localData) {
        businessHour = JSON.parse(localData)
    }
    return businessHour
}

const initialState = {
    businessHour: getBusinessHourFromLocalStorage(),
    loading: false,
    error: null,
    message: null,
    component: "",
};

export default function businessHour(state = initialState, action) {
    let newBusinessHour = {};
    switch (action.type) {
        case GET_BUSINESS_HOUR_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "StaffContainer",
            };
        case GET_BUSINESS_HOUR_SUCCESS:
            return {
                ...state,
                loading: false,
                businessHour: action.payload.businessHour,
                error: null,
                message: null,
            };
        case GET_BUSINESS_HOUR_FAILURE:
            localStorage.removeItem("businessHour");
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
            };
        case UPDATE_BUSINESS_HOUR_STARTED:
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
            };
        case UPDATE_BUSINESS_HOUR_SUCCESS:
            newBusinessHour = action.payload.data;
            localStorage.setItem("businessHour", JSON.stringify(newBusinessHour));
            return {
                ...state,
                loading: false,
                businessHour: newBusinessHour,
                message: "Business hours was updated successfully!"
            }
        case UPDATE_BUSINESS_HOUR_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
            };
        case REMOVE_ERROR_MESSAGE: {
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
            };
        }
        default:
            return {
                ...state,
            };
    }
}
