export const GET_BUSINESS_HOUR_STARTED = "GET_BUSINESS_HOUR_STARTED"
export const GET_BUSINESS_HOUR_SUCCESS = "GET_BUSINESS_HOUR_SUCCESS"
export const GET_BUSINESS_HOUR_FAILURE = "GET_BUSINESS_HOUR_FAILURE"

export const UPDATE_BUSINESS_HOUR_STARTED =  "UPDATE_BUSINESS_HOUR_STARTED"
export const UPDATE_BUSINESS_HOUR_SUCCESS =  "UPDATE_BUSINESS_HOUR_SUCCESS"
export const UPDATE_BUSINESS_HOUR_FAILURE =  "UPDATE_BUSINESS_HOUR_FAILURE"

export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE"


