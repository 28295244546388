import {
    GOOGLE_LOG_OUT_STARTED,
    GOOGLE_LOG_OUT_SUCCESS,
    GOOGLE_LOG_OUT_FAILURE,
    GET_LOCATION_LIST_STARTED,
    GET_LOCATION_LIST_SUCCESS,
    GET_LOCATION_LIST_FAILURE,
    GET_MORE_GOOGLE_REVIEW_STARTED,
    GET_MORE_GOOGLE_REVIEW_SUCCESS,
    GET_MORE_GOOGLE_REVIEW_FAILURE,
    REPLY_GOOGLE_REVIEW_STARTED,
    REPLY_GOOGLE_REVIEW_SUCCESS,
    REPLY_GOOGLE_REVIEW_FAILURE,
    DELETE_REPLY_GOOGLE_REVIEW_STARTED,
    DELETE_REPLY_GOOGLE_REVIEW_SUCCESS,
    DELETE_REPLY_GOOGLE_REVIEW_FAILURE,
    CREATE_ADMIN_STARTED,
    CREATE_ADMIN_SUCCESS,
    CREATE_ADMIN_FAILURE,
    DELETE_ADMIN_STARTED,
    DELETE_ADMIN_SUCCESS,
    DELETE_ADMIN_FAILURE,
    UPDATE_HOURS_STARTED,
    UPDATE_HOURS_SUCCESS,
    UPDATE_HOURS_FAILURE,
    UPDATE_GENERAL_INFO_STARTED,
    UPDATE_GENERAL_INFO_SUCCESS,
    UPDATE_GENERAL_INFO_FAILURE,
    GET_GENERAL_INFO_STARTED,
    GET_GENERAL_INFO_SUCCESS,
    GET_GENERAL_INFO_FAILURE,
    GET_HOURS_STARTED,
    GET_HOURS_SUCCESS,
    GET_HOURS_FAILURE,
    GET_ADMINS_STARTED,
    GET_ADMINS_SUCCESS,
    GET_ADMINS_FAILURE,
    GET_GOOGLE_REVIEW_STARTED,
    GET_GOOGLE_REVIEW_SUCCESS,
    GET_GOOGLE_REVIEW_FAILURE,
    REMOVE_ERROR_MESSAGE,

} from "../../constants/googleProfile/googleProfile";


const initialState = {
    isAuth: false,
    locationList: [],
    accountName: null,
    general: null,
    googleHours: null,
    reviews: null,
    admins: [],
    loading: false,
    loadingReview: false,
    loadingGeneral: false,
    loadingHours: false,
    loadingAdmins: false,
    error: null,
    message: null,
};

export default function googleProfile(state = initialState, action) {
    switch (action.type) {
        case GET_GOOGLE_REVIEW_STARTED:
            return {
                ...state,
                loadingReview: true,
                error: null,
                message: null,
            };

        case GET_GOOGLE_REVIEW_SUCCESS:
            return {
                ...state,
                reviews: action.payload.reviews,
                loadingReview: false,
                error: null,
                message: null,
            };

        case GET_GOOGLE_REVIEW_FAILURE:
            return {
                ...state,
                loadingReview: false,
                error: action.payload.error,
                message: null,
            };

        case GET_ADMINS_STARTED:
            return {
                ...state,
                loadingAdmins: true,
                error: null,
                message: null,
            };

        case GET_ADMINS_SUCCESS:
            return {
                ...state,
                admins: action.payload.admins,
                loadingAdmins: false,
                error: null,
                message: null,
            };

        case GET_ADMINS_FAILURE:
            return {
                ...state,
                loadingAdmins: false,
                error: action.payload.error,
                message: null,
            };

        case GET_HOURS_STARTED:
            return {
                ...state,
                loadingHours: true,
                error: null,
                message: null,
            };

        case GET_HOURS_SUCCESS:
            return {
                ...state,
                googleHours: action.payload.hours,
                loadingHours: false,
                error: null,
                message: null,
            };

        case GET_HOURS_FAILURE:
            return {
                ...state,
                loadingHours: false,
                error: action.payload.error,
                message: null,
            };

        case GET_GENERAL_INFO_STARTED:
            return {
                ...state,
                loadingGeneral: true,
                error: null,
                message: null,
            };

        case GET_GENERAL_INFO_SUCCESS:
            return {
                ...state,
                general: action.payload.general,
                loadingGeneral: false,
                error: null,
                message: null,
            };

        case GET_GENERAL_INFO_FAILURE:
            return {
                ...state,
                loadingGeneral: false,
                error: action.payload.error,
                message: null,
            };

        case UPDATE_GENERAL_INFO_STARTED:
            return {
                ...state,
                loadingGeneral: true,
                error: null,
                message: null,
            };

        case UPDATE_GENERAL_INFO_SUCCESS:
            return {
                ...state,
                loadingGeneral: false,
                general: action.payload.general,
                error: null,
                message: 'General info updated successfully!',
            };

        case UPDATE_GENERAL_INFO_FAILURE:
            return {
                ...state,
                loadingGeneral: false,
                error: action.payload.error,
                message: null,
            };

        case UPDATE_HOURS_STARTED:
            return {
                ...state,
                loadingHours: true,
                error: null,
                message: null,
            };

        case UPDATE_HOURS_SUCCESS:
            return {
                ...state,
                loadingHours: false,
                general: action.payload.general,
                error: null,
                message: 'Hours updated successfully!',
            };

        case UPDATE_HOURS_FAILURE:
            return {
                ...state,
                loadingHours: false,
                error: action.payload.error,
                message: null,
            };

        case DELETE_ADMIN_STARTED:
            return {
                ...state,
                loadingAdmins: true,
                error: null,
                message: null,
            };

        case DELETE_ADMIN_SUCCESS:
            return {
                ...state,
                loadingAdmins: false,
                admins: state.admins.filter(admin => admin.name !== action.payload.adminName),
                error: null,
                message: 'Admin deleted successfully!',
            };

        case DELETE_ADMIN_FAILURE:
            return {
                ...state,
                loadingAdmins: false,
                error: action.payload.error,
                message: null,
            };

        case CREATE_ADMIN_STARTED:
            return {
                ...state,
                loadingAdmins: true,
                error: null,
                message: null,
            };

        case CREATE_ADMIN_SUCCESS:
            return {
                ...state,
                loadingAdmins: false,
                admins: state.admins.concat(action.payload.admin),
                error: null,
                message: 'Admin created successfully!',
            };

        case CREATE_ADMIN_FAILURE:
            return {
                ...state,
                loadingAdmins: false,
                error: action.payload.error,
                message: null,
            };

        case DELETE_REPLY_GOOGLE_REVIEW_STARTED:
            return {
                ...state,
                loadingReview: true,
                error: null,
                message: null,
            };

        case DELETE_REPLY_GOOGLE_REVIEW_SUCCESS:
            state.reviews.reviews[action.payload.index].reviewReply = null
            return {
                ...state,
                loadingReview: false,
                reviews: state.reviews,
                error: null,
                message: 'Reply deleted successfully!',
            };

        case DELETE_REPLY_GOOGLE_REVIEW_FAILURE:
            return {
                ...state,
                loadingReview: false,
                error: action.payload.error,
                message: null,
            };

        case REPLY_GOOGLE_REVIEW_STARTED:
            return {
                ...state,
                loadingReview: true,
                error: null,
                message: null,
            };

        case REPLY_GOOGLE_REVIEW_SUCCESS:
            state.reviews.reviews[action.payload.index].reviewReply = action.payload.reply
            return {
                ...state,
                loadingReview: false,
                reviews: state.reviews,
                error: null,
                message: 'Reply successfully!',
            };

        case REPLY_GOOGLE_REVIEW_FAILURE:
            return {
                ...state,
                loadingReview: false,
                error: action.payload.error,
                message: null,
            };

        case GET_MORE_GOOGLE_REVIEW_STARTED:
            return {
                ...state,
                loadingReview: true,
                error: null,
                message: null,
            };

        case GET_MORE_GOOGLE_REVIEW_SUCCESS:
            const newReviews = action.payload.reviews
            return {
                ...state,
                reviews: {
                    ...state.reviews,
                    reviews: state.reviews.reviews.concat(newReviews.reviews),
                    nextPageToken: newReviews.nextPageToken
                },
                loadingReview: false,
                error: null,
                message: null,
            };

        case GET_MORE_GOOGLE_REVIEW_FAILURE:
            return {
                ...state,
                loadingReview: false,
                error: action.payload.error,
                message: null,
            };

        case GET_LOCATION_LIST_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };

        case GET_LOCATION_LIST_SUCCESS:
            return {
                ...state,
                locationList: action.payload.locationList,
                accountName: action.payload.accountName,
                isAuth: true,
                loading: false,
                error: null,
                message: null,
            };

        case GET_LOCATION_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
            };

        case GOOGLE_LOG_OUT_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };

        case GOOGLE_LOG_OUT_SUCCESS:
            return {
                ...state,
                isAuth: false,
                loading: false,
                error: null,
                message: null,
            };

        case GOOGLE_LOG_OUT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
            };

        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
            };

        default:
            return {
                ...state,
            };
    }
}
